import React, { useEffect, useRef, useState } from 'react';
import css from './SearchCollections.module.scss';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/connectors.redux';
import { useHistory } from 'react-router-dom';

const SearchCollections = () => {
    const { list } = useSelector((store: RootState) => store.Collections);
    const history = useHistory();

    const [searchResult, setSearchResult] = useState([]);
    const searchInputRef = useRef(null);

    const searchOnChangeHandler = debounce((e) => {
        if (e.target.value === '') return setSearchResult([]);

        const filterList = list
            .filter((item) => item.contract_view_name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1)
            .sort(
                (a, b) =>
                    a.contract_view_name.toLowerCase().indexOf(e.target.value.toLowerCase()) -
                    b.contract_view_name.toLowerCase().indexOf(e.target.value.toLowerCase()),
            )
            .slice(0, 5);

        setSearchResult(filterList);
    }, 200);

    const searchSubmitHandler = (collection) => {
        if (searchInputRef?.current !== null) searchInputRef.current.value = '';
        history.push(`/collections/${collection.contract_name}/${collection.brand_name}`);
    };

    const clearInputHandler = () => {
        if (searchInputRef.current !== null) searchInputRef.current.value = '';
        history.goBack();
    };

    useEffect(() => {
        if (searchInputRef?.current !== null) {
            searchInputRef?.current.focus();
        }
    }, []);

    return (
        <section className={css.searchSection}>
            <div className={css.searchInputContainer}>
                <input
                    type="text"
                    placeholder="Search Collections"
                    ref={searchInputRef}
                    onChange={searchOnChangeHandler}
                />
                <svg
                    className={css.exitImg}
                    viewBox="0 0 13 12"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={clearInputHandler}
                >
                    <path d="M1.5 11L11.5 0.999999" stroke="#6F6F6F" stroke-width="2" stroke-linecap="round" />
                    <path d="M1.5 1L11.5 11" stroke="#6F6F6F" stroke-width="2" stroke-linecap="round" />
                </svg>
            </div>
            <div className={css.searchResultContainer}>
                {searchResult.length ? (
                    <div className={css.searchCollectionHeader}>
                        <span>collection</span>
                    </div>
                ) : null}

                {searchResult.map((item) => {
                    return (
                        <div className={css.searchCollectionResult} onMouseDown={() => searchSubmitHandler(item)}>
                            <div className={css.searchCollectionItem}>
                                <img src={window.envBackImageHost + item.brand_img_src} alt="" />
                                <span>{item.contract_view_name}</span>
                            </div>
                            <div className={css.searchCollectionItemNumber}>
                                <p>{item.sttc_crnt_nft}</p>
                                <p>items</p>
                            </div>
                        </div>
                    );
                })}
                <div className={css.searchCollectionBottom}>
                    <span>{searchResult.length ? 'Press Enter to search all items' : 'No collections found'}</span>
                </div>
            </div>
        </section>
    );
};

export default SearchCollections;
