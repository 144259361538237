import React from 'react';
import _ from 'lodash';

import { useLanguages } from '@/hooks/useLanguages.hook';

import Section from './components/Section';
import Box from './components/Box';
import { QnAItem } from './components/QnAList';

import css from './helpCenter.module.scss';
import PerPlus from '@/helpers/PerPlus';
import { stakerAddress } from '@/includes/envVariables';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux/connectors.redux';

const index = () => {
    const dispatch = useDispatch();
    const Lang = useLanguages();
    const wallet = useSelector((store: RootState) => store.Wallet);
    const { contractList } = useSelector((store: RootState) => store.PerPlus);

    const ApproveKip17Request = async () => {
        if (wallet.info.address === '') return window?.toast('error', Lang.err_msg_fail_connect_wallet);

        const perPlus = new PerPlus(
            { contractList: contractList },
            { contractAddress: '0x0Ed55aEe0399064Cfe51dD3cC10D99734bb796c7' },
        );

        await perPlus.setApprovedAll(
            { wallet, spender: stakerAddress },
            {
                sucCallback(receipt?) {
                    window?.toast('success', Lang.enforce_perPlus_modal_approve_suc);
                },
                failCallback() {
                    window?.toast('error', Lang.enforce_perPlus_modal_approve_fail);
                },
            },
            { dispatch: dispatch, Lang: Lang },
        );
    };

    return (
        <main className="pt-5">
            {/* ************* Q&A ************* */}
            <Section>
                <Box className="p-3" title={Lang.help_cent_block_1_title}>
                    <QnAItem uuid={'qna11'} question={Lang.help_cent_question_1_1}>
                        {_.map(Lang.help_cent_answer_1_1.split('\n'), (line, i) => (
                            <span key={i}>
                                {line}
                                <br />
                            </span>
                        ))}
                    </QnAItem>
                    <QnAItem uuid={'qna12'} question={Lang.help_cent_question_1_2}>
                        {_.map(Lang.help_cent_answer_1_2.split('\n'), (line, i) => (
                            <span key={i}>
                                {line}
                                <br />
                            </span>
                        ))}
                    </QnAItem>
                    <QnAItem uuid={'qna13'} question={Lang.help_cent_question_1_3}>
                        {_.map(Lang.help_cent_answer_1_3.split('\n'), (line, i) => (
                            <span key={i}>
                                <br />
                                {line}
                            </span>
                        ))}
                        <a href={Lang.help_cent_answer_1_3_1} target="_blank">
                            https://chrome.google.com/webstore/detail/kaikas
                        </a>
                        {_.map(Lang.help_cent_answer_1_3_2.split('\n'), (line, i) => (
                            <span key={i}>
                                <br />
                                {line}
                            </span>
                        ))}
                        <a href={Lang.help_cent_answer_1_3_3} target="_blank">
                            {Lang.help_cent_answer_1_3_3}
                        </a>
                        {_.map(Lang.help_cent_answer_1_3_4.split('\n'), (line, i) => (
                            <span key={i}>
                                {line}
                                <br />
                            </span>
                        ))}
                    </QnAItem>
                    <QnAItem uuid={'qna14'} question={Lang.help_cent_question_1_4}>
                        {_.map(Lang.help_cent_answer_1_4.split('\n'), (line, i) => (
                            <span key={i}>
                                {line}
                                <br />
                            </span>
                        ))}
                    </QnAItem>

                    <QnAItem uuid={'qna15'} question={Lang.help_cent_question_1_5}>
                        {_.map(Lang.help_cent_answer_1_5.split('\n'), (line, i) => (
                            <span key={i}>
                                {line}
                                <br />
                            </span>
                        ))}
                    </QnAItem>

                    <QnAItem uuid={'qna16'} question={Lang.help_cent_question_1_6}>
                        {_.map(Lang.help_cent_answer_1_6.split('\n'), (line, i) => (
                            <span key={i}>
                                {line}
                                <br />
                            </span>
                        ))}
                        {/* <a href={Lang.help_cent_answer_1_6_1} target="_blank">
                            Link
                        </a> */}
                    </QnAItem>

                    <QnAItem uuid={'qna17'} question={Lang.help_cent_question_1_7}>
                        {_.map(Lang.help_cent_answer_1_7.split('\n'), (line, i) => (
                            <span key={i}>
                                {line}
                                <br />
                            </span>
                        ))}
                    </QnAItem>

                    <QnAItem uuid={'qna18'} question={Lang.help_cent_question_1_8}>
                        {_.map(Lang.help_cent_answer_1_8, (line, i: number) => {
                            if (i === 1) {
                                return (
                                    <button
                                        className={css.stakingHelpButton}
                                        type="button"
                                        onClick={ApproveKip17Request}
                                        key={i}
                                    >
                                        {line}
                                    </button>
                                );
                            } else return <span key={i}>{line}</span>;
                        })}
                    </QnAItem>
                </Box>
            </Section>
        </main>
    );
};

export default index;
