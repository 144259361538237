export default {
    alert_please_excuting_mobile: '모바일 환경에서 실행해주세요.',

    word_expiration_time: '만료시간',

    /**
     * 시스템 점검 관련 메세지
     */
    maintenance_system_check: '시스템 점검중입니다.',
    maintenance_upgrade_warning: 'Warning!',
    maintenance_upgrade_check: '새로운 기능을 추가하려 에너지를 모으고 있습니다.',
    maintenance_upgrade_full_charge_is_1hour: '에너지는 약 1시간 동안 모을 예정입니다.',

    /****************************************************************************,
     * 화면 우측 하단의 Toast alert (메세지 알림 창) KLIP 연결 및 상태 업데이트 알람,
     */
    approved_klip_title: '승인 트랜잭션',
    sell_klip_title: '판매등록 트랜잭션',
    sell_cancel_klip_title: '판매취소 트랜잭션',
    buy_klip_title: '구매 트랜잭션',
    mint_klip_title: '민팅 트랜잭션',

    // Toast Success message,
    suc_msg_sucs_sell_approved: '판매등록이 승인되었습니다. \n다음 단계를 진행해주세요.',
    suc_msg_sucs_sell: '판매등록이 완료되었습니다.',
    suc_msg_sucs_sellCancel: '판매취소가 완료되었습니다.',
    suc_msg_sucs_buy: '구매가 완료되었습니다.',
    suc_msg_sucs_mint: '민팅이 완료되었습니다.',

    // Toast Error message,
    err_msg_sucs_sell_approved: '판매등록이 승인되지 않았습니다. \n다시 시도해주세요.',
    err_msg_sucs_sell: '판매등록이 완료되지 않았습니다. \n다시 시도해주세요.',
    err_msg_sucs_sellCancel: '판매취소가 완료되지 않았습니다. \n다시 시도해주세요.',
    err_msg_sucs_buy: '구매가 완료되지 않았습니다. \n다시 시도해주세요.',
    err_msg_sucs_mint: '민팅이 완료되지 않았습니다. \n다시 시도해주세요.',

    /**
     * 지갑 연결 시도 후 성공 메세지,
     * Toast Success message,
     */
    suc_msg_sucs_connect_kaikas: '카이카스에 연결되었습니다.',
    suc_msg_sucs_connect_klip: '클립에 연결되었습니다.',

    err_msg_address_check_kaikas: '카이카스에 연결된 지갑주소를 확인해주세요.',

    /**
     * 마이페이지의 Listed nft 카드 선택후 가격 입력시 메세지,
     */
    err_msg_fail_price_too_big: '너무 큰 숫자입니다.',
    err_msg_fail_price_not_integers: '소수점 두번째 자리까지 입력할 수 있습니다.',
    err_msg_fail_price_empty: '클레이를 입력해주세요.',
    err_msg_fail_price_not_valid: '유효하지 않은 숫자입니다.',

    err_msg_fail_not_token_owner: '토큰 소유자가 아닙니다.',
    err_msg_fail_not_token_owner_factory: '판매중인 토큰이 아닙니다.',
    err_msg_fail_connect_wallet: '지갑을 연결해주세요.',
    err_msg_fail_connect_kaikas: '카이카스가 연결되지 않았습니다.\n지갑을 다시 연결해주세요.',
    err_msg_fail_connect_klip: '클립이 연결되지 않았습니다.\n지갑을 다시 연결해주세요.',
    err_msg_need_kaikas: '카이카스 확장프로그램 설치 후 이용해주세요.',
    err_msg_access_denaid: '접속이 거부되었습니다.\n지갑연결 상태를 확인해주세요.',
    err_msg_fail_request: '정보를 불러올 수 없습니다.\n잠시 후 다시 시도해주세요.',
    err_msg_fail_check_extension: '정보를 불러올 수 없습니다.\n카이카스 확장 프로그램을 확인해주세요.',
    err_msg_fail_do_not_test_network: '테스트 네트워크에서는 이용할 수 없습니다.',

    /**
     * Klip 연결 만료 Toast 메세지,
     */
    inf_msg_expires_in_one_minute: '1분 후 클립 연결이 종료됩니다.',
    inf_msg_expires_in_disconnect: '클립 연결시간이 만료되었습니다.',

    /**
     * 메인 메뉴 우측 상단 지갑주소 클릭시 나오는 메뉴,
     */
    inf_msg_copy_to_clipboard: '복사되었습니다.',

    /**
     * 메인 상단 중앙의 메뉴 이름
     */
    header_menu0: '처음으로',
    header_menu1: '콜렉션',
    header_menu2: '상황판',
    header_menu3: '도움말',
    header_menu4: '프로필',
    header_menu5: '퍼 플러스',
    header_logout: '로그아웃',
    /**
     * 메인 우측 상단 내 메뉴 클릭시 나오는 박스 메뉴,
     */
    header_my_menu: '내 메뉴',

    header_collections: '콜렉션',
    header_my_page: '마이페이지',
    header_conn_wallet: '지갑연결',
    header_disconn_wallet_Questions: '연결을 해제하시겠어요?',
    header_disconn_wallet: '연결해제',

    /**
     * 메인 우측 상단 연결하기 클릭시 나오는 모달 윈도우 내용,
     */
    header_modal_conn_title: '클레이스왑 시작하기',
    header_modal_conn_kaikas: 'Kaikas 지갑 연결하기',
    header_modal_separator: '또는',
    header_modal_conn_desciption: '카카오톡으로 쉽고 안전하게 시작하기',
    header_modal_conn_klip: '카카오톡으로 Klip 지갑 연결하기',
    header_modal_conn_klip_notice: '카카오 디지털 지갑 Klip 안내',
    header_modal_auto_conn_wallet: '자동 연결',

    header_modal_loading_title: 'Kaikas 지갑 연결 대기',
    header_modal_loading_description: 'Kaikas 지갑 연결 대기',

    header_modal_klip_title: '카카오톡으로 Klip QR 연결',
    header_modal_klip_description: '카카오톡 앱을 이용하여 QR 코드를 스캔해주세요.',
    header_modal_klip_footer_desc1: '카카오톡 실행',
    header_modal_klip_footer_desc2: '상단 검색 터치',
    header_modal_klip_footer_desc3: '코드 스캔',

    /**
     * 이용 약관,
     */
    footer_term_of_use: '이용약관',
    footer_supports: '문의',

    /**
     * 404 Not Found Page
     */
    not_found_404_title: '404 Not Found :(',
    not_found_404_subscript: '요청하신 페이지를 찾을 수 없습니다.',
    not_found_404_description1: '페이지가 존재하지 않거나, 사용할 수 없는 페이지 입니다.',
    not_found_404_description2: '입력한 주소가 정확한지 다시 한번 확인해주세요.',
    not_found_404_button_text: '돌아가기',

    /**
     * 메인 페이지 내용 중 단위별 타이틀,
     */
    main_box_title_minting_now: 'Minting',
    main_box_title_coming_soon: '곧 공개됩니다',
    main_box_title_next: '곧 공개될 NFT 시리즈',
    main_box_title_market: '거래소',
    main_box_title_qna: 'Q & A',
    main_box_title_game_items: '게임 아이템',
    main_box_title_minting_create: '민팅 제작소',
    main_box_title_minigame: '미니게임',

    /**
     * 콜렉션 리스트
     */
    list_no_collections: '콜렉션이 없습니다',

    /**
     * 콜렉션 페이지 리스트 안내 문구,
     */
    list_no_my_items: '아이템이 없습니다.',
    //검색시 가져올 리스트가 없을때.
    list_no_sales: '판매중인 아이템이 없습니다.',

    list_more_item: '더보기',
    //더이상 가져올 리스트가 없을 때.
    list_end_response_lock: '더 이상 판매 아이템이 없습니다.',

    /**
     * 도움말 페이지의 질문 & 답변,
     */
    help_cent_block_1_title: 'KlayMint ?',
    help_cent_question_1_1: '1. 클레이민트란?',
    help_cent_answer_1_1:
        'KlayMint는 NFT를 통해 시작되는 새로운 가치와 시장에 누구나 간편하게 접근하여 NFT를 통한 흥미롭고 가치있는 생태계에 쉽게 참여할 수 있도록 접근성을 제공해주는 플랫폼입니다.',
    help_cent_question_1_2: '2. 클레이민트에서는 무엇을 할 수 있나요?',
    help_cent_answer_1_2:
        "KlayMint는 프로젝트/작가/개인 등 NFT를 발행하고자 하는 모두가 이용할 수 있습니다. (Beta와 Phase 1 단계에서는 신청 양식을 통해 런칭할 수 있습니다.)\n\n민팅 참여, 거래 지원, NFT 합성·스테이킹 서비스('퍼 플러스')를 제공하며\n\n카이카스와 클립 지갑을 연결한 후, 모든 서비스를 풍부하게 이용하실 수 있습니다.",

    help_cent_question_1_3: '3. 클레이민트를 시작하려면 어떻게 해야하나요?',
    help_cent_answer_1_3: '먼저 카이카스 또는 클립 지갑을 만들어 주세요.\n\n1) 카이카스 : \n\n1) 카이카스 : ',
    help_cent_answer_1_3_1: 'https://chrome.google.com/webstore/detail/kaikas/jblndlipeogpafnldhgmapagcccfchpi',
    help_cent_answer_1_3_2: '\n2) 클립 : ',
    help_cent_answer_1_3_3: 'https://klipwallet.com/',
    help_cent_answer_1_3_4: '\n\n지갑의 계정을 KlayMint에 연결하여 서비스를 이용해 보세요.',

    help_cent_question_1_4: '4. 클레이민트(상장)에 NFT를 판매하려면 어떻게 해야하나요?',
    help_cent_answer_1_4:
        "1) 우측상단에 '연결하기' 버튼은 클릭합니다.\n\n2) 연결할 수 있는 지갑을 선택합니다. (카이카스 또는 클립)\n\n3) 우측 상단에 '내 메뉴' 에서 '마이페이지'로 이동합니다.\n\n4) 'UNLISTED'에 보유하고 있는 'NFT 카드의 SELL'를 선택합니다.\n\n5) 판매를 원하는 가격을 적고 'SELL'을 선택합니다.",

    help_cent_question_1_5: '5. 클레이민트에서 판매를 취소하려면 어떻게 해야하나요?',
    help_cent_answer_1_5:
        "1) 우측상단 '내 메뉴' 에서 '마이페이지'로 이동합니다.\n\n2) 'ON SALE'에 입장합니다.\n\n3) 판매를 올렸던 NFT 카드를 선택하여 'SELL CANCEL'을 클릭합니다.",

    help_cent_question_1_6: '6. 클레이민트에서 내 컬렉션을 어떻게 발행하나요?',
    help_cent_answer_1_6:
        "KlayMint을 통해 NFT를 발행하고 싶은 프로젝트/예술가/작가/누구나 홈페이지 하단의 'Listing Contact'를 통해 리스팅 문의를 진행할 수 있습니다.\n\n 자세한 문의는 klaytn@klaymint.io 주소로 문의해주세요.",
    help_cent_answer_1_6_1:
        'https://docs.google.com/forms/d/e/1FAIpQLSckNvWTn_cOFtMpOQfE_BSrM9C1otZCUDIuOww_DEpSsveBsA/viewform',

    help_cent_question_1_7: '7. KlayMint에서 NFT 발행 시 수익은 어떻게 발생하나요?',
    help_cent_answer_1_7:
        'KlayMint의 민팅 판매 금액은 일정 수수료를 제외하고 모두 프로젝트에게 돌아갑니다.\n\n 민팅된 NFT의 2차 거래를 통해 KlayMint 1%와 프로젝트 0~10%(설정가능)의 수수료가 지급됩니다.',

    help_cent_question_1_8: '8.스테이킹 서비스를 정상적으로 이용할 수 없나요?',
    help_cent_answer_1_8: ['', '여기', '를 눌러 트랜잭션을 보낸 후, 스테이킹을 이용해주세요.'],

    help_cancel_center_check: 'ON SALE에서 NFT 토큰을 확인 할 수 없나요 ?',
    help_cancel_center: ' - KlayMint가 보유하고 있는 판매 목록에서 판매를 강제 취소 진행',

    /**
     * 이용 약관,
     */
    term_of_use: '서비스 이용약관',
    term_of_use_title: '클레이민트',

    validate_msg_required: '필수 항목입니다.',
    validate_msg_num_char_or_less: '자 이하만 입력해주세요.',
    validate_msg_more_then_num_char: '자 이상만 입력해주세요.',

    modal_check_mint_title: '트랜잭션 요청',
    modal_check_mint_text_hold: '보유',
    modal_check_mint_mint_now: '민팅하기',
    modal_check_mint_pending_transactions: '이미 진행중인 트랜잭션이 있습니다',
    modal_check_mint_lack_to_klay: 'Klay가 부족합니다.',

    modal_check_text_approved: '승인',
    modal_check_need_data_check_a_approved: '승인상태 조회 필요',
    modal_check_sell_item_need_after_approved: '승인상태 조회 후 판매할 수 있습니다.',
    modal_check_little_fee: '소량의 수수료',

    /**
     * 프로젝트 등록 (collection 리스트 위)
     */
    collections_register_btn_title: 'KLAYTN 체인으로 발행된 NFT의 거래를 지원합니다.',
    collections_register_btn_subtitle: '지금 클릭하고 프로젝트를 등록해 보세요!',

    register_kip17_pfp_sections_title: 'KIP-17 표준의 PFP - NFT 프로젝트',
    register_kip17_pfp_sections_description_title: '조건',
    register_kip17_pfp_sections_description1:
        '1. 거래지원 등록자는 Minter 중 하나여야 하며, NFT를 발행한 컨트랙트를 입력해야 합니다.',
    register_kip17_pfp_sections_description2: '2. 등록하신 컨트랙트는 검증 후, 빠른 시일 내에 거래지원됩니다.',
    register_kip17_pfp_sections_input_placeholder: 'Contract Address 입력',
    register_kip17_pfp_sections_btn_name: '등록신청',

    suc_cfm_register_question: '등록하시겠어요?',

    suc_msg_register_contract_address: '컨트랙트가 등록되었습니다.',
    err_msg_invalid_contract_address: '컨트랙트가 유효하지 않습니다.',
    err_msg_duplicate_contract_address: '컨트랙트 등록이 실패했습니다.',

    /**
     * my page
     */
    suc_msg_sucs_changed_nickname: '닉네임이 변경되었습니다.',
    err_msg_faild_changed_nickname: '닉네임이 변경되지 않았습니다. 다시 시도해주세요.',
    noti_msg_duplication_nickname: '이미 등록된 닉네임입니다.',

    /**
     * swap 관련 msg,
     */
    suc_msg_per_swap: '스왑성공',
    err_msg_per_swap: '스왑실패',
    err_msg_kilp_swap_denied: '클립 사용자는 이용할 수 없습니다.',
    err_msg_check_per_balance: '보유하고 있는 PER의 수량을 확인하시고 다시 입력해주세요.',
    err_msg_check_klay_balance: '보유하고 있는 KLAY의 수량을 확인하시고 다시 입력해주세요.',

    /**
     * MegaPhone에서 출력되는 문자
     */
    mega_msg_reinforce_success:
        '우와! 지금 {nickname}님이 <a className="{rateCSS}">{rating}</a> 클래스 합성에 성공했습니다!',

    /**
     * 합성페이지 관련
     */

    enforce: '합성 기능 이전 & 업데이트 진행중입니다.',
    enforce_main_content_title: '2장의 NFT를 합성해보세요!',
    enforce_main_content_desc: '더 높은 클래스의 NFT를 얻을 수 있습니다.',
    enforce_main_content_choice_main_card: '합성할 카드를 선택하세요',
    enforce_main_content_choice_sub_card: '재료카드를 선택하세요.',
    enforce_main_content_main_card: '합성 대상 카드',
    enforce_main_content_sub_card: '재료 카드',
    enforce_main_content_main: '합성 대상',
    enforce_main_content_item_amount: '아이템 개수',
    enforce_main_content_sub: '재료',
    enforce_main_content_enforce_percentage: '성공률',
    enforce_main_content_enforce_percentage_m: '합성 성공률',
    enforce_main_content_enforce_item_Betting: '아이템 사용',
    enforce_main_content_enforce_item_Betting_txt: '아이템을 사용해 합성 성공률을 \n 높여보세요 !',
    enforce_main_content_enforce_item_etc_txt: '위 아이템을 눌러 선택해제 하세요.',
    enforce_main_content_enforce_info: '합성 정보',
    enforce_main_content_enforce_class_scenario: '클래스 시나리오',
    enforce_main_content_enforce_choice_card: '카드 2장을 선택해 주세요.',

    enforce_main_content_enforce_percentage_desc: [
        '재료카드는 합성 대상의 클래스를 포함한 이하의 등급으로만 시도가능합니다.',
        '각 등급별 합성확률은 다음과 같습니다.',
    ],
    enforce_main_content_start_enforce_title: '합성을 시작하였습니다 !',
    enforce_main_content_start_enforce_title_fail: '합성시도가 실패했습니다 ! 잠시 후 다시 시도해 주세요.!',
    enforce_main_content_start_enforce_desc: [
        '결과는 대시보드에서 확인하실 수 있습니다.',
        '합성이 완료되면 대시보드을 통해 알려드립니다.',
    ],

    enforce_main_content_return: '처음으로 돌아가기',
    enforce_main_content_result_fail_title: '합성 실패 😓',
    enforce_main_content_result_fail_desc: ['아쉬워하긴 일러요!', '다음 합성 때 성공률을 올려주는 아이템을 드릴게요.'],
    enforce_main_content_result_fail_item_desc: ['💎 아이템 ', '개 획득!'],
    enforce_main_content_result_suc_title: '합성 성공 !',
    enforce_main_content_result_suc_desc: [
        '합성대상카드와 재료카드를 이용하여 합성에 성공하였습니다.',
        '이용된 카드들은 합성과정에서 소각되었습니다.',
    ],

    enforce_perPlus_modal_transaction: '트랜잭션 요청',
    enforce_perPlus_modal_transaction_confirmation: '내용을 모두 확인하였으며, 진행하시겠습니까?',
    enforce_perPlus_modal_kip7_approve_desc:
        '위 토큰에 대해 컨트랙트의 접근을 허용할 수 있도록 최초 1회에 한해 Approve 트랜잭션을 실행합니다.',
    enforce_perPlus_modal_kip17_approve_desc:
        '합성에 필요한 KIP17 토큰전송의 권한위임에 대한 최초 1회에 한해 Approve 트랜잭션을 실행합니다.',
    enforce_perPlus_modal_kip37_approve_desc:
        '합성 시 합성성공률 증가에 필요한 KIP37 토큰전송의 권한위임에 대한 최초 1회에 한해 Approve 트랜잭션을 실행합니다.',
    enforce_perPlus_modal_approve_next_desc: '트랜잭션 성공 후 계속해서 다음 트랜잭션을 요청할 수 있습니다.',

    enforce_perPlus_klip_modal_title: '합성 트랜잭션',
    enforce_perPlus_modal_approve_suc: '다음 트랜잭션으로 이어집니다.',
    enforce_perPlus_modal_approve_fail: '승인되지 않았습니다.',

    enforce_perPlus_modal_expected_result: '예상결과',
    enforce_perPlus_modal_have_per: '보유',

    enforce_inventory_choice_button: '선택',
    enforce_inventory_cancel_button: '취소',
    enforce_inventory_sort_list_1: '클래스 낮은 순',
    enforce_inventory_sort_list_2: '클래스 높은 순',
    enforce_inventory_sort_list: ['클래스 낮은 순', '클래스 높은 순'],
    enforce_inventory_searchBox_placeholder: 'NFT ID를 검색해 보세요.',
    enforce_inventory_myItems: '내 아이템',

    enforce_dashboard_estimated_time: '약 1분 30초',
    enforce_dashboard_estimated_time_desc: '예정 소요 시간 ',

    enforce_network_delay: '클레이튼 네트워크 블록 동기화가 지연되고 있습니다.\n 잠시 후 다시 이용해주세요.',
    enforce_request_fail: '합성요청이 실패했습니다.\n 다시 시도해주세요.',

    enforce_Inventory: '인벤토리',
    enforce_Dashboard: '대시보드',

    mining_Power_grade: '등급별 채굴력을 확인할 수 있습니다.',

    mining_power_th1: '등급',
    mining_power_th2: '채굴력',
    mining_power_th3: '스테이킹 가능 수량 ',
    mining_power_th4: '일자',
    mining_power_th5: '상태',

    mining_power_th2_m: '채굴력',
    mining_power_th3_m: '수량',

    suc_klip_msg: '트랜잭션 실행이 완료되었습니다.',
    fail_klip_msg: '트랜잭션이 완료되지 않았습니다. 잠시 후 다시 이용해주세요.',

    mobile_swap_alert: '모바일 이용자는 사용할 수 없는 기능입니다.',

    enforce_cannot_be_used: '사용할 수 없는 카드입니다.',
    enforce_expected_mining_power: '예상 채굴량',
    /**
     * 스왑 페이지 관련
     */

    swap: '스왑',
    cancel_msg_per_swap: '취소 하였습니다.',
    swap_header_h1: '원하는 자산으로 바로 교환(스왑)하세요.',
    swap_header_h3: ['본 기능은', '사이트에서 제공하는 기능과 동일한 기능입니다. 이용에 참고 바랍니다.'],
    swap_hold: '보유',
    swap_input_placeholder: '수량을 입력하세요',
    swap_exchange_rate: '교환 비율',
    swap_expected_tarnce_value: '예상 거래 수량',
    swap_fee: '수수료',
    swap_transaction_history: '트랜잭션 내역',

    /* 소각 페이지 관련 */
    Status: '소각',
    Status_h1: '소각된 토큰 현황을 보여드립니다.',
    Status_h3: 'PER 토큰의 최신 상태를 확인 하세요.',
    Status_burn_per: '현재 소각된 PER',
    Status_circulation_per: '현재 PER 유통량',

    /* 스테이킹 페이지 관련 */
    Staking: '스테이킹',
    Staking_h1: '스테이킹 적립 보상',
    Staking_h3: '퍼프렌즈를 예치하고 보상을 받아볼까요?!',
    Staking_Klay_total_quantity: 'KLAY 총 수량',
    Staking_PER_total_quantity: 'PER 총 수량',

    imposableStaking: '스테이킹 불가능한 NFT 입니다.',
    // Staking_over_count: '스테이킹은 5장까지 할 수 있습니다.',
    Staking_over_count: ['스테이킹은 ', '장까지 할 수 있습니다.'],
    // Staking_over_count: ['스테이킹은 ', '장 까지 가능합니다.'],
    Staking_multi_count_over: ['멀티 스테이킹/언스테이킹은 ', '장 까지 가능합니다.'],

    staking_tx: '스테이킹 트랜잭션',
    staking_tx_suc: '스테이킹이 완료되었습니다.',
    staking_tx_fail: '스테이킹 실패했습니다.',

    unStaking_tx: '언스테이킹 트랜잭션',
    unStaking_tx_suc: '보상 수령이 완료되었습니다.',
    unStaking_tx_fail: '언스테이킹이 실패했습니다.',

    //================== staking common word ================================
    staking_common_staking: '스테이킹',
    staking_common_unstaking: '언스테이킹',
    staking_common_gohome: '스테이킹 홈으로',
    //=======================================================================
    // staking_enter_button: '스테이킹 하러 가기',
    staking_enter_button: '언스테이킹 하기',
    //================== staking Index page =================================
    staking_index_h1: '스테이킹으로 보상을 획득하세요',

    staking_tooltip_h6_1: '■ 보상수령',
    staking_tooltip_p_1: 'NFT를 스테이킹하여 채굴되는 자산을 수령할 수 있습니다.',
    staking_tooltip_h6_2: '■ 스테이킹',
    staking_tooltip_p_2:
        '보유하고 있는 NFT를 스테이킹 하여, 예치되어있는 자산을 마이닝 파워에 따라 채굴할 수 있습니다. 복수 스테이킹 시 마이닝 파워는 합산됩니다.',
    staking_tooltip_h6_3: '■ 언스테이킹',
    staking_tooltip_p_3: '스테이킹 한 NFT 카드를 언스테이킹하여, 채굴된 자산을 수령합니다.',

    staking_pool_text_klay: '총 예치된 수량',

    staking_historybox_amount: '출금 가능',

    staking_allWithdraw_button: '보상 수령',
    staking_dostaking_button: '스테이킹',
    staking_dounstaking_button: '언스테이킹',

    staking_separateItem_miningpower: '채굴력',
    staking_separateItem_shareratio: '지분율',
    staking_separateItem_cumulative: '누적 출금액',

    staking_expected_24h: '예상 리워드(24h)',
    staking_plz_login: '로그인을 해주세요',

    staking_list_carousel: '스테이킹 리스트',

    //=======================================================================

    //================== staking doStaking page =============================
    staking_scenterContent_selectcard: ['스테이킹 할', 'NFT를 선택해주세요.'],
    staking_confirm_header: ['스테이킹 정보 | ', '총', '개'],
    unStaking_confirm_header: ['언스테이킹 정보 | ', '총', '개'],
    //=======================================================================

    //================== staking successStaking =============================
    staking_success_h1: '스테이킹이 완료되었습니다!',
    staking_success_small: '결과는 트랜잭션 내역을 확인해주세요',
    staking_transactionTable_caption: '트랜잭션 내역',
    staking_transactionTable_thead1: '유형',
    staking_transactionTable_thead2: '해시',
    staking_transactionTable_thead3: '상태',
    staking_transactionTable_thead4: '블록',
    //=======================================================================

    //================== staking successUnStaking ===========================
    staking_unstakingsuccess_cardunstaking: '에 대한 언스테이킹을 완료하였습니다.',
    staking_unstakingsuccess_unstaking: '언스테이킹을 완료하였습니다.',
    staking_unstakingsuccess_small: '결과는 트랜잭션 내역을 확인해주세요',
    //=======================================================================

    /* 기타 사항 */
    LogOut: '로그아웃',
    Exit: '나가기',
    NickName: '닉네임 변경',
    Tooltip_Possible: '합성 기능이 정상 작동합니다.',
    Tooltip_InPossible: '합성 기능이 제대로 작동하지 않습니다. 잠시 후 이용해주세요.',
    Possible_value: '상태',
    Possible_Instability: '불안정',
    Possible_Stability: '안정',
    Possible_Delay: '지연',
    CheckCard_Modal_close_btn: '닫기',

    /* 닉네임 변경 모달창 */
    Input_NickName: '닉네임',
    Input_Please: '입력하세요',
    Input_NickName_Edit: '수정',
    Input_Etc_comment: '2~8자 한글·영문, 특수기호 입력 불가',

    /**
     * Tutorial
     **/
    tutorial_line: [
        "응애! 반가워! 나는 아기 카멜레온 '멜'이라고 해!\n나와 같이 PER Plus 행성을 탐험하러 가볼래? " /* 120px */,
        "먼저 '합성'을 해볼까? 합성을 통해서 카드의 등급을 올릴 수 있어!\n합성을 위해선 ‘합성 대상 카드’와 ‘재료 카드’, 총 2장의 카드가 필요해 ",
        '여기 인벤토리에 멋쟁이 포포 카드가 보일 거야\n이 포포 카드를 더욱 힘세고 멋진 모습으로 만들어볼까? ' /* 120px */,
        '카드를 클릭하면 합성 대상 카드에 등록할 수 있어\n한번 포포 카드를 합성 대상 카드에 등록해 보자구! ' /* 120px */,
        '이 멋쟁이 포포 카드의 등급은 ‘Rare’야!\n‘재료 카드’의 등급은 ‘합성 대상 카드’의 등급과 같거나 낮아야 해! ',
        '카드를 클릭하면 재료 카드에 등록할 수 있어\n합성 재료로 쓰인 카드는 소멸되니까 신.중.히 선택해줘!\n그럼 포포 카드를 합성하기 위해 카온 카드를 재료 카드로 선택해 볼까? ' /* 120px */,
        '선택이 완료되었으니, 이제 ‘합성’을 할 수 있어!\n아 참, 다음부터 합성에는 PER 코인이 필요하단 거 잊지 말고! ' /* 120px */,
        '이번은 너를 위해 특별히 PER 코인 없이 체험할 수 있게 했어!\n히힛! 그럼 ‘합성’ 버튼을 눌러 포포 카드를 합성해 볼까? ',
        '우와 해냈구나! 합성가 진행되고 있어!\n포포는 멋쟁이라 옷을 갈아입는데 시간이 조금 걸릴 수 있어! ' /* 120px */,
        '합성 결과는 대시보드에서 확인할 수 있어!\n대시보드 메뉴의 합성 결과에서 직접 확인해 보자구! ' /* 120px */,
        '성공이야! Rare 등급이었던 포포 카드가 Unique 등급으로 합성되었어!\n잘 따라와 준 덕분에 나도 정말 재밌는 시간이었어! 진짜 고마워! ',
        '응애! 벌써 시간이 이렇게 됐네!\n퍼플러스 행성에서 이뤄질 다양한 이벤트를 준비하러 갈 시간이야! ' /* 120px */,
        '스왑과 소각에 대해서도 확인할 수 있으니 천천히 둘러봐!\n앞으로 새로운 이벤트와 추가되는 기능도 있으니까 기대해달라구! ',
        '클레이민트 채널과 퍼프렌즈 디스코드에 가입하면\n다양한 이벤트 소식을 가장 빠르게 전달해 줄게!\n두근두근! 기대해달라구! ',
    ],
    tutorial_end: '열심히 제작한 튜토리얼을 정말 끝내시겠습니까?',

    tutorial_Tooltop: '튜토리얼을 다시 볼 수 있어요',

    /**
     * 급작스런 모바일...
     */
    mobile_header_profile_dropdown_nickname_change: '닉네임 변경',
    /**
     * 네비게이션 메뉴
     * */
    navi_menu_text_arr: ['합성', '스왑', '스테이킹', /*'소각',*/ '플러스 민팅'],

    add_per_token: '토큰 등록',

    add_per_token_suc: 'PER 토큰 등록이 완료되었습니다.',
    add_per_token_fail: 'PER 토큰 등록이 실패했습니다. 잠시 후 다시 시도해 주세요.',

    swap_require_login: '스왑기능은 로그인이 필요합니다.',
    not_not_enough_per: 'PER 가 충분하지 않습니다.',

    /**
     * myPage 전송기능 관련
     */
    sell_button: '판매',
    transfer_button: '전송',

    err_msg_transfer: '전송되지 않았습니다. \n다시 시도해주세요.',
    suc_msg_transfer: '전송되었습니다.',

    placeholder_msg_transfer: '받는 주소를 입력해주세요.',
    err_msg_transfer_invalid_address: '주소가 유효하지 않습니다.',
    warning_msg_transfer: '* 일부 프로젝트에서는 전송기능이 작동하지 않을 수 있습니다.',
    title_transfer: 'NFT 전송',
    from_transfer: '보내는 사람',
    to_transfer: '받는 사람',

    /**
     * evt modal text
     */
    evt_modal_session: '오늘은 그만보기',
    evt_modal_close: '닫기',

    /**
     * 플러스 민팅 (auto minting) 페이지
     */
    plusMinting_pubilc_mint: '퍼블릭',
    plusMinting_pubilc_mint_price: '판매가',
    plusMinting_owner_mint: 'NFT',
    plusMinting_owner_mint_price: '개 홀더',
    plusMinting_remaining_numbers: [' 개', '남음'],
    plusMinting_check_number_of_nft_owned: '보유하고 계신 PerFriends NFT 개수를 확인해 주세요!',
    plusMinting_modal_enter_quantity: 'NFT 수량을 입력해 주세요',
    plusMinting_modal_sale_price: '민팅가',
    plusMinting_modal_header: ['', '지불 자산을 선택해 주세요.', '트렌잭션을 요청합니다.'],
};
