import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { postUpdateThumbnail } from '@/helpers/klaymint.api';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux/connectors.redux';

const imageCropper: React.FC<any> = (props): JSX.Element => {
    const [upImg, setUpImg] = useState<any>(null);
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [crop, setCrop] = useState<Crop>({
        aspect: 1 / 1,
        unit: '%',
        x: 0,
        y: 0,
        width: 0,
        height: 0,
    });
    const [completedCrop, setCompletedCrop] = useState(null);

    const wallet = useSelector((store: RootState) => store.Wallet);
    const dispatch = useDispatch();

    const generateDownload = (canvas, crop) => {
        if (!crop || !canvas) {
            return;
        }

        canvas.toBlob(
            async (blob) => {
                const formData = new FormData();
                const file = new File([blob], `thumb_${wallet.info.address}.png`, {
                    type: 'image/png',
                });
                formData.append('thumb', file);
                formData.append('usr_wallet_address', wallet.info.address);

                postUpdateThumbnail(formData)
                    .then(async (res) => {
                        console.log('postUpdateThumbnail', res.data);
                    })
                    .catch((err) => {
                        console.log('err', err);
                    });

                //const previewUrl = window.URL.createObjectURL(blob);

                // const anchor = document.createElement('a');
                // anchor.download = 'cropPreview.png';
                // anchor.href = URL.createObjectURL(blob);
                // anchor.click();
                // window.URL.revokeObjectURL(previewUrl);
            },
            'image/png',
            1,
        );
    };

    const onSelectFile = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();

            reader.addEventListener('load', async () => setUpImg(reader.result));
            reader.readAsDataURL(e.target.files[0]);

            const aspect = 1 / 1;
            const width = 50;
            const height = 50;
            const y = (100 - height) / 2;
            const x = (100 - width) / 2;

            await setCrop({ ...crop, unit: 'px', width, height, aspect });
        }
    };

    const onLoad = useCallback((img) => {
        imgRef.current = img;

        const aspect = 1 / 1;
        const width = 100;
        const height = 100;

        setCrop({
            ...crop,
            unit: 'px',
            width,
            height,
            aspect,
        });

        return false; // Return false if you set crop state in here.
    }, []);

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY,
        );
    }, [completedCrop]);

    return (
        <div className="p-2">
            <div className="d-flex justify-content-center p-2">
                <canvas
                    ref={previewCanvasRef}
                    className="rounded-circle"
                    // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                    style={{
                        // width: Math.round(completedCrop?.width ?? 0),
                        // height: Math.round(completedCrop?.height ?? 0),
                        width: 80,
                        height: 80,
                        // display: 'none',
                    }}
                />
            </div>
            <ReactCrop
                src={upImg}
                onImageLoaded={onLoad}
                crop={crop}
                onChange={(c) => setCrop(c)}
                onComplete={(c) => setCompletedCrop(c)}
            />

            <div className="d-flex justify-content-center">
                <input
                    id="selectThumbnail"
                    type="file"
                    accept="image/*"
                    onChange={onSelectFile}
                    style={{ display: 'none' }}
                />
                <label
                    htmlFor="selectThumbnail"
                    className="d-flex rounded-circle bg-white align-items-center justify-content-center"
                    style={{ width: 40, height: 40 }}
                >
                    <i className="fas fa-camera" />
                </label>
            </div>
            <button
                type="button"
                disabled={!completedCrop?.width || !completedCrop?.height}
                onClick={() => generateDownload(previewCanvasRef.current, completedCrop)}
            >
                sdsdsd
            </button>
        </div>
    );
};

export default imageCropper;
