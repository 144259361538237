import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Routers } from '@/routers';
import { useDispatch } from 'react-redux';
import { detectedBreakpoint, isMobile } from '@/redux/reducers/GlobalStatus.reducer';
import { loadCollections } from '@/redux/reducers/Collections.reducer';

import Layout from '@/pages/_layouts';
import GLoader from '@/_components/commons/loaders/global_loader';
import Toast from '@/_components/commons/toasts/Toast_bs5';

import Maintenance from '@/pages/Maintenance';

// import evtImage from '@/_statics/images/ambassador_.png';
// import evtImageButton from '@/_statics/images/ambassador_button.png';
// import EvtModal from '@/_components/commons/modals/EvtModal';

const App: React.FC<any> = (): JSX.Element => {
    const dispatch = useDispatch();

    /**
     * popup state & function
     */
    const [popup, setPopup] = useState(
        localStorage.getItem('popup') ? +localStorage.getItem('popup') - Date.now() < 0 : true,
    );
    const handleClose = () => setPopup(false);

    /**
     * Detected Window width breakpoint
     */
    useEffect(() => {
        // 1. 초기 렌더에서 전역변수에 저장할 contractList info 를 요청한다.
        dispatch(loadCollections());

        // 2. Browser Window Size Detect Handler
        let pass = 0;
        const detectBreakpoint = () => {
            if (++pass % 10 === 0) {
                dispatch(detectedBreakpoint());
                dispatch(isMobile());
            }
        };
        window.addEventListener('resize', detectBreakpoint);

        return () => {
            window.removeEventListener('resize', () => {
                detectBreakpoint();
            });
        };
    }, []);

    return (
        <>
            {/*점검페이지 띄우기 */}
            {/* <Maintenance></Maintenance> */}

            <Helmet>
                <title>{window.envHeadTitle}</title>
            </Helmet>
            <GLoader />
            <Toast />

            <Layout>
                <Routers />
            </Layout>

            {/*{popup ? <EvtModal image={evtImage} button={evtImageButton} handleClose={handleClose} /> : null}*/}
        </>
    );
};
export default App;
