import Caver from 'caver-js';
import { getStakeAvailable, setPromiseAll, spliceArray } from '@/helpers/_common';
import axios, { AxiosResponse } from 'axios';
import {
    dequeueSalesList,
    getIsAddress,
    getIsAllowance,
    getIsApproved,
    getIsApprovedKip37,
    getIsOwner,
    getKlayFromPeb,
    getPebFromKlay,
    getENMySalesList,
} from '@/helpers/klaymint.api';
import KASHelper from '@/helpers/KAS.helper';
import { WALLETS } from '@/reducers/Wallet.reducer';
import { envFactoryAddress, perAddress, perSymbol } from '@/includes/envVariables';
import { perTokenAbi } from '@/includes/abi';
import perLogo from '@/_statics/images/PerPlus/Per_logo.svg';
import perLogoTest from '@/_statics/images/PerPlus/listing_per_logo.png';

/**
 * contractAddress 와 factoryAddress 를 받아서 블록체인과 통신 가능한 인스턴스 객체를 생성할 수 있는 인스턴스
 * if(window.klaytn) check 는 윈도우에 카이카스가 설치되있을 경우 실행조건 else일 경우는 backend 요청으로 보낸다.
 *
 */
export default class KlayMintUtil {
    // for oldFactory
    private exceptionFactoryAddress: string[];

    // util
    protected readonly caver: Caver;
    protected readonly caverExtKas: KASHelper;

    // getToken 메서드를 사용하기 위해서 접근하는 인스턴스 객체는 contractAddress 와 factory
    constructor() {
        this.exceptionFactoryAddress = [envFactoryAddress.toLowerCase()];

        this.caver = new Caver(window.klaytn);
        this.caverExtKas = new KASHelper();
    }

    /**
     *  @@@@@@@@  validation check  @@@@@@@@
     */
    /**
     * 지갑상태를 조회하는 함수
     * 연결이 되어있지 않다면 false 반환
     * 지갑이 카이카스 && 윈도우 klaytn address와 redux wallet address 랑 같지 않다면 false 반환
     * @param wallet
     * @param exceptionCallback
     * @param Lang
     */
    protected walletStatusCheck = async (
        wallet: WALLETS,
        exceptionCallback: () => void,
        Lang: Record<string, any>,
    ): Promise<boolean> => {
        if (!wallet.isConn) {
            this.endFunction(exceptionCallback, Lang.err_msg_fail_connect_wallet);
            return false;
        }

        if (wallet.type === 'kaikas' && window.klaytn?.selectedAddress !== wallet.info.address) {
            this.endFunction(exceptionCallback, Lang.err_msg_address_check_kaikas);
            return false;
        }

        return true;
    };
    /**
     * 파라미터로 tokenId , contractAddress를 받아서 isOwner 함수로 owner의 여부를 조회
     * isOwner의 반환값과 redux wallet address가 다르다면 에러토스트 메시지 출력
     * @param input
     * @param output
     * @param util
     */
    protected tokenOwnerCheck = async (
        input: {
            wallet: WALLETS;
            tokenId: number;
            contractAddress: string;
        },
        output: {
            exceptionCallback: () => void;
        },
        util: {
            Lang: Record<string, any>;
        },
    ) => {
        const isOwner = await this.isOwner(input.tokenId, input.contractAddress);
        if (isOwner.toLowerCase() !== input.wallet.info.address.toLowerCase()) {
            this.endFunction(output.exceptionCallback, util.Lang.err_msg_fail_not_token_owner);
            return false;
        }

        return true;
    };
    /**
     * 팩토리안에서 해당 토큰에대한 owner를 조회
     * 1step : isOwner를 통해 token 조회
     * 2stpe : 판매중인 토큰이 oldFactory에 있을경우 isowner를 반환하면서 cunstruct 정의되있는 this.factoryAddress로 변환
     * 3step : isOwner와 factoryAddress가 다를경우 큐에서 삭제 후 false 반환
     * @param input
     * @param output
     * @param util
     */
    protected tokenOwnerCheckInFactory = async (
        input: {
            wallet: WALLETS;
            tokenId: number;
            contractAddress: string;
            factoryAddress: string;
        },
        output: {
            exceptionCallback: () => void;
        },
        util: {
            Lang: Record<string, any>;
        },
    ): Promise<string | boolean> => {
        const isOwner = await this.isOwner(input.tokenId, input.contractAddress);

        // 판매중인 토큰이 oldFactory 에 들어있을때, this.factoryAddress 를 구컨주소로 바꿔야함
        if (this.exceptionFactoryAddress.indexOf(isOwner.toLowerCase()) !== -1) {
            return isOwner;
        }

        // 판매중인 토큰이 아닐때 삭제하는 로직
        if (isOwner.toLowerCase() !== input.factoryAddress.toLowerCase()) {
            dequeueSalesList(input.contractAddress.toLowerCase());
            this.endFunction(output.exceptionCallback, util.Lang.err_msg_fail_not_token_owner_factory);
            return false;
        }

        return true;
    };

    /* klay -> Peb */
    /**
     * price(klay)를 파라미터로 받아 peb단위로 변경
     * @param price
     */
    public toPebFromKlay = async (price: string | number): Promise<any> => {
        if (window.klaytn) {
            return this.caver.utils.convertToPeb(price + '', 'KLAY');
        } else {
            const res = await getPebFromKlay(price);
            return res.data;
        }
    };

    /* Peb -> klay */
    /**
     * peb을 파라미터로 받아 klay 단위로 변경
     * @param peb
     */
    public toKlayFromPeb = async (peb): Promise<any> => {
        if (window.klaytn) {
            return this.caver.utils.fromPeb(peb, 'KLAY');
        } else {
            return await getKlayFromPeb(peb);
        }
    };

    /* address validation check */
    /**
     * 파라미터로 받은 address 가 유효한지 검사
     * return boolen ( true : 유효)
     * @param address
     */
    public isAddress = async (address: string) => {
        if (window.klaytn) {
            const caver = new Caver(window.klaytn);
            return caver.utils.isAddress(address);
        } else {
            const res = await getIsAddress(address);
            return res.data;
        }
    };
    /**
     * perplus에서 per토큰을 눌렀을때 kaikas지갑에 퍼코인을 리스팅하는 func
     */
    public listingPerToken = () => {
        if (window?.klaytn) {
            const tokenImage = perLogoTest;

            window.klaytn.sendAsync(
                {
                    method: 'wallet_watchAsset',
                    params: {
                        type: 'ERC20', // Initially only supports ERC20, but eventually more!
                        options: {
                            address: perAddress, // The address that the token is at.
                            symbol: perSymbol, // A ticker symbol or shorthand, up to 5 chars.
                            decimals: 18, // The number of decimals in the token
                            image: tokenImage, // A string url of the token logo
                        },
                    },
                    id: Math.round(Math.random() * 100000),
                },
                (err, added) => {
                    if (added) {
                        console.log('Thanks for your interest!');
                    } else {
                        console.log('Your loss!');
                    }
                },
            );
        }
    };

    /* exception callback */
    /***
     * 예외상황 처리 콜백 func
     * message를 Lang 인자로 받아 toast 'err' 메시지 출력력     * @param callback
     * @param massage
     */
    public endFunction = (callback: () => void, massage: string) => {
        window?.toast('error', massage);
        callback();
    };

    /**
     * 해당 tokenURI 를 ipfs 를 사용한다면 전용게이트웨이로 사용해서 로딩 속도를 증가
     * @param origin
     */
    public replaceIPFSGateway = (origin: string) => {
        const case1 = origin.replace('https://ipfs.io/ipfs/', 'https://klaymint.mypinata.cloud/ipfs/');
        const case2 = case1.replace('ipfs://', 'https://klaymint.mypinata.cloud/ipfs/');
        const case3 = case2.replace('gateway.pinata.cloud/ipfs/', 'klaymint.mypinata.cloud/ipfs/');

        return case3;
    };
    /**
     * window.klaytn이 있다면 operator에게 owner가 소유한 모든 토큰을 전송할 권한이 있다면 true 반환
     * 없다면 백엔드 호스트를 통해 조회
     * @param owner
     * @param operator
     * @param contractAddress
     */
    public isApproved = async (owner: string, operator: string, contractAddress: string) => {
        if (window.klaytn) {
            const caver = new Caver(window.klaytn);

            const kip17 = caver.kct.kip17.create(contractAddress);
            return await kip17.isApprovedForAll(owner, operator);
        } else {
            const res = await getIsApproved(owner, contractAddress, operator);

            return res.data;
        }
    };
    /**
     * per 토큰의 컨트랙트 (= spender)가 owner의 잔액에서 인출하도록 허락받은 토큰 수량을 반환
     * return은 bigNumber반환
     * @param owner
     * @param spender
     * @param contractAddress
     */
    public getAllowance = async (owner: string, spender: string, contractAddress: string) => {
        if (window.klaytn) {
            const caver = new Caver(window.klaytn);

            const PER = new caver.contract(perTokenAbi, contractAddress);

            const allowance = await PER.methods.allowance(owner, spender).call();
            return allowance;
        } else {
            const res = await getIsAllowance(owner, spender);
            console.log(res);
            return res.data;
        }
    };
    /**
     * window.klaytn 이 있으면 kip37 인스턴스 생성 => operator의 승인상태를 조회
     * owner가 operator를 승인했으면 true 반환
     * @param owner
     * @param operator
     * @param contractAddress
     */
    public isApprovedKip37 = async (owner: string, operator: string, contractAddress: string) => {
        if (window.klaytn) {
            const caver = new Caver(window.klaytn);
            const kip37 = caver.kct.kip37.create(contractAddress);
            return await kip37.isApprovedForAll(owner, operator);
        } else {
            const res = await getIsApprovedKip37(owner, contractAddress, operator);
            return res.data;
        }
    };

    /**
     * token ownerCheck util method
     */
    /**
     * window.klaytn이 있으면 ownerOf (특정 토큰 ID를 소유한 계정의 주소 반환)
     * return string
     * @param tokenId
     * @param contractAddress
     */
    public isOwner = async (tokenId: number, contractAddress: string): Promise<string> => {
        if (window.klaytn) {
            const caver = new Caver(window.klaytn);

            const kip17 = caver.kct.kip17.create(contractAddress);
            return await kip17.ownerOf(tokenId);
        } else {
            // 클레이튼이 없으면 백엔드 en axios
            const res = await getIsOwner(tokenId, contractAddress);

            return res.data;
        }
    };

    /**
     * 해당 카이카스 지갑 또는 클립에 존재하는 토큰들을 모두 불러오기
     * 토큰을 불러올때 cursor가 빈값이 아니라면 loop로 받아온다
     * loopArr에 푸시된 데이터들은 flat을 이용해 하위배열을 이어붙은 새로운 배열을 return 한다.
     * @param wallet
     * @param list
     */
    public getToken = async (wallet, list) => {
        if (list.length === 0) return;

        const deepArr = [...list];

        let loopArr = [];
        const getTokenLoop = async (walletAddress, contractAddress, cursor) => {
            const data = await this.caverExtKas.getMyTokens(wallet.info.address, contractAddress, cursor);
            loopArr.push(data.items);

            if (data.cursor !== '') return await getTokenLoop(walletAddress, contractAddress, data.cursor);

            return loopArr.flat();
        };

        const contractAddress = spliceArray(deepArr, deepArr.length / 10, (item) => item.contract_address);

        const tokenArr = [];

        await setPromiseAll(contractAddress, async (splicedArr) => {
            const data = await this.caverExtKas.getMyTokens(wallet.info.address, splicedArr);
            tokenArr.push(data.items);

            if (data.cursor !== '') {
                const loopItems = await getTokenLoop(wallet.info.address, splicedArr, data.cursor);
                tokenArr.push(loopItems);
                loopArr = [];
            }
        });

        const unlistedTokens = tokenArr.flat();

        const unlistedTokensInfo = [];
        const exceptionArr = [];

        const caver = new Caver(window.klaytn ? window.klaytn : window.envEnNode);

        await setPromiseAll(unlistedTokens, async (item) => {
            try {
                const { tokenId } = item.extras;
                const kip17Instance = caver.kct.kip17.create(item.contractAddress);
                const tokenURI = await kip17Instance.tokenURI(tokenId);

                const uri = this.replaceIPFSGateway(tokenURI);

                let response;
                let exception = false;

                try {
                    response = await axios.get(uri);
                } catch (error) {
                    try {
                        exceptionArr.push(uri);
                        // 해당 부분 개선 필요 corsHandler 여러번 요청이 아니라 한번 요청에 한번 응답받는
                        response = await axios.post(`${window.envBackHost}/exception/corsHandler`, { uri: uri });
                    } catch (e) {
                        exception = true;
                    }
                }

                /**
                 * unlistedTokensInfo 배열에 token properties 추가 또는 변경
                 */
                if (!exception) {
                    if (response.data.animation_url) {
                        response.data.video = this.replaceIPFSGateway(response.data.animation_url);
                    }

                    if (response.data.image) {
                        response.data.image = this.replaceIPFSGateway(response.data.image);
                    }

                    response.data.contractAddress = item.contractAddress;
                    response.data.token_id = Number.parseInt(item.extras.tokenId, 16);

                    const result = await getStakeAvailable(item.contractAddress, response.data.token_id);
                    response.data.isStaking = result === '0' ? false : true;

                    unlistedTokensInfo.push(response.data);
                }
            } catch (e) {
                console.log(e);
            }
        });

        const onSaleTokens = await getENMySalesList(wallet.info.address);

        return {
            unlisted: unlistedTokensInfo,
            onSale: onSaleTokens.data,
        };
        // }
    };

    /**
     * min <= returnValue:Integer <= max
     */
    public getRandomInt = (min: number, max: number) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    public SafeMath = {
        defaultMaxDecimal: 5,

        round: (num: number, offset = 0): number => {
            const e = (num: number, p: number) => Number(num + 'e' + p);

            const pos = offset > 0 ? offset - 1 : offset;
            return e(Math.round(e(num, pos)), -pos);
        },

        // 덧셈
        safeAdd: function (x: number, y: number, maxDecimal: number = this.SafeMath.defaultMaxDecimal): number {
            return this.round(x + y, maxDecimal + 1);
        },

        // 뺄셈
        safeSubtract: function (x: number, y: number, maxDecimal: number = this.SafeMath.defaultMaxDecimal): number {
            return this.round(x - y, maxDecimal + 1);
        },

        // 곱셈
        safeMultiply: function (x: number, y: number, maxDecimal: number = this.SafeMath.defaultMaxDecimal): number {
            return this.round(x * y, maxDecimal + 1);
        },

        // 나눗셈
        safeDivide: function (x: number, y: number, maxDecimal: number = this.SafeMath.defaultMaxDecimal): number {
            return this.round(x / y, maxDecimal + 1);
        },

        /**
         * x <= returnValue:float <= y
         */
        safeRandom: (x: number, y: number, maxDecimal: number = this.SafeMath.defaultMaxDecimal): number => {
            if (x > y) throw 'y must be less than x';

            const minInt = this.SafeMath.safeMultiply(x, 10 ** maxDecimal, maxDecimal);
            const maxInt = this.SafeMath.safeMultiply(y, 10 ** maxDecimal, maxDecimal);
            const randomInt = this.getRandomInt(maxInt, minInt);

            return this.SafeMath.safeDivide(randomInt, 10 ** maxDecimal, maxDecimal);
        },
    };

    /**
     * 낙관적인(optimistic) 업데이트
     * 해당기능은 블록체인으로 tx 발송이 성공했다면 실행 - 내 월렛의 리덕스 정보를 업데이트
     * tx 를 쏘고 , 업데이트 된 컨트랙트의 정보로 UI 를 업데이트하는게 아니라, 성공했다면 그 즉시 해당 토큰을
     * 업데이트 될거라 믿고(낙관적인) UI 를 수정하는 optimisticUpdate
     */
    optimisticInsert = (prevArr: any[], item: any, setState: any) => {
        const tempArr = [...prevArr];
        tempArr.push(item);
        setState(tempArr);
    };
    optimisticDelete = (prevArr: any[], item: any, setState: any) => {
        const prev = [...prevArr];
        const next = prev.filter((v) => v.token_id !== item.token_id);
        setState(next);
    };
}
