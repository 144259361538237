import CaverExtKas from 'caver-js-ext-kas';
import { envKASAccessKey, envKASSecretKey, envKlaytnAPIToken, stakerAddress } from '@/includes/envVariables';
import axios from 'axios';
import KlayMintUtil from '@/helpers/KlayMint.util';
import { setPromiseAll } from './_common';

class KASHelper {
    private caverExtKas: CaverExtKas;

    constructor() {
        this.caverExtKas = new CaverExtKas('8217', envKASAccessKey, envKASSecretKey);
    }

    /**
     * KAS를 이용해서 토큰히스토리를 조회, contractAddress 안에서 정의된 컨트랙 안에서 walletAddress가 가지고있는 리스트들을 리턴
     * 사이즈보다 큰 데이터를 받을경우 cursor를 리턴
     * cursor를 포함해서 다시 요청을 보내면 전에 받은 응답의 size를 제외하고 리턴
     * @param walletAddress
     * @param contractAddress
     * @param cursor
     */
    public getMyTokens = async (walletAddress: string, contractAddress: string[], cursor?: string | undefined) => {
        return await this.caverExtKas.kas.tokenHistory.getTokenListByOwner(walletAddress, {
            size: 1000,
            kind: this.caverExtKas.kas.tokenHistory.queryOptions.kind.NFT,
            caFilters: `${contractAddress}`,
            cursor: cursor ? cursor : undefined,
        });
    };
    /**
     * 인자로 contractAddress를 받아 특정 NFT컨트랙트를 조회한다.
     * @param address contractAddress
     */
    public validateContract = async (address: string): Promise<boolean> => {
        try {
            await this.caverExtKas.kas.tokenHistory.getNFTContract(address);
            return true;
        } catch (e) {
            return false;
        }
    };
    /**
     * 특정 EOA가 가진 모든 토큰 목록을 조회
     *  **EOA란 ? External owner Address 외부소유계정이다.
     *  쿼리파라미터 => 커서방식 페이지네이션으로 API응답을 받아올 수 있다.
     * @param contractAddress nft 컨트랙트 address
     * @param cursor  페이지네이션으로 다음 요청을 보낼 때 필요한 커서
     */
    public getTransferHistory = async (contractAddress: string, cursor?: string | undefined) => {
        try {
            return await this.caverExtKas.kas.tokenHistory.getNFTListByOwner(contractAddress, stakerAddress, {
                size: 1000,
                cursor: cursor ? cursor : undefined,
            });
        } catch (e) {
            return false;
        }
    };

    //KAS KIP37 조회
    /**
     * 해당 walletAddress의 kip37토큰 리스트를 불러옵니다.
     * @param walletaddress 지갑주소
     * @param contractAddress kip37에 대한 컨트랙트 address
     */
    public checkKIP37 = async (walletaddress, contractAddress) => {
        const util = new KlayMintUtil();
        const res = await this.caverExtKas.kas.tokenHistory.getMTListByOwner(contractAddress, walletaddress);
        const itemTokens = [];
        if (res.items.length > 0) {
            await setPromiseAll(res.items, async (item) => {
                const { tokenUri } = item;

                const uri = util.replaceIPFSGateway(tokenUri);
                try {
                    const response = await axios.get(uri);
                    response.data.tokenId = parseInt(item.tokenId, 16);
                    response.data.balance = parseInt(item.balance, 16);
                    response.data.tokenAddress = item.tokenAddress;
                    itemTokens.push(response.data);
                } catch (e) {
                    console.log(e);
                }
            });
            return itemTokens;
        }
    };
}

export default KASHelper;
