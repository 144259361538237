import React, { useEffect, useRef, useState } from 'react';
import css from '@/pages/CollectionsDetail/components/Article.module.scss';
import cx from 'classnames';
import _ from 'lodash';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux/connectors.redux';
import { ArticleModalProps } from '@/_components/commons/modals/_ArticleModal.interfaces.declare';

import useList from '@/hooks/useList.hook';
import { useLanguages } from '@/hooks/useLanguages.hook';

import MyPageModal from '@/pages/myPage/components/Modal';
import NoItems from '@/_components/commons/NoItems';
import KlayMint from '@/helpers/KlayMint';
import S from '@/_statics/images/PerPlus/s.svg';
import { completeTransfer } from '@/helpers/klaymint.api';

const Unlisted = ({ loading }) => {
    const dispatch = useDispatch();
    const wallet = useSelector((store: RootState) => store.Wallet);
    const { list, classs } = useSelector((store: RootState) => store.Collections);
    const { mobile } = useSelector((store: RootState) => store.GlobalStatus);

    const Lang = useLanguages();

    const [modal, setModal] = useState(false);
    const [modalProps, setModalProps] = useState({} as ArticleModalProps);

    const {
        state,
        onRefresh,
        onAppend,

        pages,
        movePage,

        scrollEventListener,
    } = useList({});

    const modalOnClickHandler = async (item) => {
        const currentContract = list.filter(
            (obj) => obj.contract_address.toLowerCase() === item.data.contractAddress.toLowerCase(),
        );

        const currentClasss = classs[currentContract[0]?.id];

        // 나중에 같은 컨트랙트에서 두개의 팩토리가 나오는 경우에는 로직을 수정해야하는 부분
        // 인덱스가 0이 아니라 별도의 방법을 통해 두개의 팩토리중 해당 팩토리를 찾아야함
        const klaymint = new KlayMint(currentContract[0]?.contract_address, currentContract[0]?.factory_address, list);

        const isWallet = wallet.info.address !== '';
        const attrArr = item.data.attributes
            ?.filter((value) => value.trait_type !== 'id')
            .map((value) => {
                if (value.trait_type === 'CLASS') {
                    try {
                        item.data.class = JSON.parse(value.value)[1];
                    } catch (error) {
                        item.data.class = value.value;
                    }
                }

                return Object.values(value);
            });

        setModalProps({
            collection: currentContract[0],
            props: {
                title: `${item.data.name}`,
                mainImage: `${item.data.image}`,
                mainVideo: item.data.video ?? null,
                mainDesc: item.data.description,
                mainAttrs: attrArr,
                mainAttrDesc: currentClasss,
                footerInput: true,
                footerButtons: isWallet ? 'SELL' : 'CONNECT WALLET',
                onClick: {
                    event: isWallet
                        ? klaymint.sellRequest
                        : () => window?.toast('error', Lang.err_msg_fail_connect_wallet),
                    argument: [item.data.token_id, dispatch],
                },
                item: item,
            },
        });
        setModal(true);
    };

    const transferHandler = (item) => {
        setModalProps({
            collection: { is_class: true },
            props: {
                jsxContent: <TransferModal token={item} setModal={setModal} />,
            },
            style: {
                width: 'auto',
            },
        });
        setModal(true);
    };

    useEffect(
        () =>
            scrollEventListener(
                document,
                () => {
                    console.log('on [unlistied] Refresh');
                },
                () => {
                    if (loading) return;
                    console.log('on [unlistied] load append');
                    //onRefresh(wallet.info.myToken.unlisted);
                    /** test code */
                    //__TEST__fakeAsyncFunciton();
                    //asyncFinxtion();
                },
            ),
        [],
    );

    useEffect(() => {
        onRefresh(wallet.info.myToken.unlisted);
    }, [wallet.info.myToken.unlisted]);

    useEffect(() => {
        modal ? (document.body.style.overflowY = 'hidden') : (document.body.style.overflowY = 'unset');
    }, [modal]);

    if (loading) {
        return (
            <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <>
            <section className={cx('row', css.ArticleSection, css.myPageArticleList)}>
                {_.keys(state.view_list).length <= 0 && <NoItems>{Lang.list_no_my_items}</NoItems>}
                {_.map(state.view_list, (item) => {
                    return (
                        <div key={item.id} className={cx('col-md-2', css.articleContainer, css.unlistedArticle)}>
                            <div className={css.card}>
                                <div className={css.imgContainer}>
                                    {item.data.video ? (
                                        <video
                                            className="articleVideos"
                                            controls
                                            controlsList="nodownload"
                                            autoPlay
                                            muted
                                            loop
                                            playsInline
                                            poster={item.data.image}
                                        >
                                            <source src={item.data.video} type="video/mp4" />
                                        </video>
                                    ) : (
                                        <img src={item.data.image} alt="nft" />
                                    )}
                                    {item.data.isStaking ? (
                                        <img src={S} alt="S" className={css.StakingcardMark} />
                                    ) : null}
                                </div>
                                <div className={css.txtContainer} style={mobile ? { display: 'block' } : undefined}>
                                    <h5>{item.data.name}</h5>
                                    <div className={css.buttonDiv}>
                                        <button type="button" onClick={() => modalOnClickHandler(item)}>
                                            <span>{Lang.sell_button}</span>
                                        </button>
                                        <button
                                            type="button"
                                            onClick={(e) => {
                                                transferHandler(item);
                                                e.stopPropagation();
                                            }}
                                        >
                                            <span>{Lang.transfer_button}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </section>
            {modal ? <MyPageModal setModal={setModal} modalProps={modalProps} /> : null}
        </>
    );
};

const TransferModal = ({ token, setModal }) => {
    const wallet = useSelector((store: RootState) => store.Wallet);
    const { list } = useSelector((store: RootState) => store.Collections);
    const Lang = useLanguages();
    const dispatch = useDispatch();

    const [toAddress, setToAddress] = useState('');

    const addressInputRef = useRef(null);
    const validateCheckRef = useRef(null);

    const addressOnChangeHandler = (e) => {
        if (e.target.value.length === 0 && addressInputRef.current !== null) {
            validateCheckRef.current.style.display = 'none';
            addressInputRef.current.style.border = '1px solid';
        } else if (
            e.target.value.length !== 42 &&
            validateCheckRef.current !== null &&
            addressInputRef.current !== null
        ) {
            validateCheckRef.current.style.display = 'block';
            addressInputRef.current.style.border = '1px solid rgb(249 81 81 / 80%)';
            addressInputRef.current.style.outline = 'none';
        } else if (
            e.target.value.length === 42 &&
            validateCheckRef.current !== null &&
            addressInputRef.current !== null
        ) {
            validateCheckRef.current.style.display = 'none';
            addressInputRef.current.style.border = '1px solid';
        }

        setToAddress(e.target.value);
    };

    const transferHandler = async () => {
        const currentContract = list.filter(
            (obj) => obj.contract_address.toLowerCase() === token.data.contractAddress.toLowerCase(),
        );

        // 나중에 같은 컨트랙트에서 두개의 팩토리가 나오는 경우에는 로직을 수정해야하는 부분
        // 인덱스가 0이 아니라 별도의 방법을 통해 두개의 팩토리중 해당 팩토리를 찾아야함
        const klaymint = new KlayMint(
            currentContract[0]?.contract_address,
            currentContract[0]?.factory_address,
            list,
            currentContract[0].id,
        );

        const isAddress = await klaymint.isAddress(toAddress);
        if (!isAddress) return window.toast('error', Lang.err_msg_transfer_invalid_address);

        klaymint.transfer(
            { wallet: wallet, toAddress: toAddress, tokenId: token.data.token_id },
            {
                sucCallback(receipt?) {
                    setModal(false);
                    window.toast('success', Lang.suc_msg_transfer);
                },
                failCallback() {
                    setModal(false);
                    window.toast('error', Lang.err_msg_transfer);
                },
                exceptionCallback() {
                    setModal(false);
                },
                saveTxCallback(txHash) {
                    completeTransfer(toAddress, wallet.info.address, this.ctl_idx, token.data.token_id, txHash);
                },
            },
            { dispatch: dispatch, Lang: Lang },
        );
    };

    return (
        <section className={css.transferSection}>
            <div className={css.transferTitleContainer}>
                <h3>{Lang.title_transfer}</h3>
            </div>
            <div className={css.transferImageContainer}>
                <div className={css.transferMainImage}>
                    <img src={token.data.image} alt="tImage" />
                    {token.data.isStaking ? <img src={S} alt="S" className={css.StakingcardMark} /> : null}
                </div>
                <h5>{token.data.name}</h5>
            </div>
            <div className={css.transferFromContainer}>
                <p>{Lang.from_transfer}</p>
                <input defaultValue={wallet?.info?.address} readOnly />
            </div>
            <div className={css.transferToContainer}>
                <p>{Lang.to_transfer}</p>
                <input
                    type="text"
                    onChange={addressOnChangeHandler}
                    placeholder={Lang.placeholder_msg_transfer}
                    ref={addressInputRef}
                />
                <p ref={validateCheckRef} className={css.transferValidate}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="img"
                        width="1em"
                        height="1em"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                    >
                        <path d="M13 9h-2V7h2m0 10h-2v-6h2m-1-9A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2z" />
                    </svg>
                    {Lang.err_msg_transfer_invalid_address}
                </p>
            </div>
            <div className={css.transferButtonContainer}>
                <p>{Lang.warning_msg_transfer}</p>
                <button type="button" onClick={transferHandler}>
                    {Lang.transfer_button}
                </button>
            </div>
        </section>
    );
};

export default React.memo(Unlisted);
