import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

export const getViewListJson = async (brandName) => {
    return await axios.get(`${window.envBackHost}/market/viewList/json?brand=${brandName}`);
};

export const getViewListJsonPost = async (brandName) => {
    return await axios.post(`${window.envBackHost}/market/viewList/json`, { brandName });
};

export const getView = async (data) => {
    return await axios.post(`${window.envBackHost}/market/view`, data, { responseType: 'arraybuffer' });
};

export const getOneDaySalesHistory = async (brandName, daysAgo) => {
    if (daysAgo)
        return await axios.get(
            `${window.envBackHost}/market/salesHistory?brandName=${brandName}&daysAgo=${daysAgo}&limit=50`,
        );
    return await axios.get(`${window.envBackHost}/market/salesHistory?brandName=${brandName}&limit=50`);
};

export const getSalesHistory = async (brandName, tokenId?) => {
    if (tokenId)
        return await axios.get(`${window.envBackHost}/market/salesHistory?brandName=${brandName}&tokenId=${tokenId}`);
    return await axios.get(`${window.envBackHost}/market/salesHistory?brandName=${brandName}`);
};

export const getKlayFromAddress = async (address) => {
    return await axios.post(`${window.envBackHost}/en/balance`, { address });
};

export const getPebFromKlay = async (balance) => {
    return await axios.post(`${window.envBackHost}/en/balance/convertFromPeb`, { balance });
};

export const getKlayFromPeb = async (balance) => {
    return await axios.post(`${window.envBackHost}/en/balance/convertFromKlay`, { balance });
};

export const getIsOwner = async (token_id, contractAddress) => {
    return await axios.post(`${window.envBackHost}/en/isOwner`, { token_id, contractAddress });
};

export const getIsApproved = async (address, contractAddress, factoryAddress) => {
    return await axios.post(`${window.envBackHost}/en/isApproved`, { address, contractAddress, factoryAddress });
};
export const getIsApprovedKip37 = async (address, contractAddress, factoryAddress) => {
    return await axios.post(`${window.envBackHost}/en/isApprovedKip37`, {
        address,
        contractAddress,
        factoryAddress,
    });
};

export const getIsAllowance = async (address, enforceContractAddress) => {
    return await axios.post(`${window.envBackHost}/en/allowance`, { address, enforceContractAddress });
};

export const getPerBalance = async (address) => {
    return await axios.post(`${window.envBackHost}/en/loadPerBalance`, { address });
};

export const getIsStakeAvailable = async (_contractAddres, _tokenId) => {
    return await axios.post(`${window.envBackHost}/en/stakeAvailable`, { _contractAddres, _tokenId });
};

export const getIsAddress = async (address) => {
    return await axios.post(`${window.envBackHost}/en/isAddress`, { address });
};

export const getENSalesList = async (contractAddress) => {
    return await axios.post(`${window.envBackListenHost}/forkRouter/salesList`, { contractAddress });
};

export const getENMySalesList = async (address) => {
    return await axios.post(`${window.envBackListenHost}/forkRouter/mySalesList`, { address });
};

export const enqueueSalesList = async (contractAddress) => {
    return await axios.post(`${window.envBackListenHost}/forkRouter/salesList/enqueue`, { contractAddress });
};

export const dequeueSalesList = async (contractAddress) => {
    return await axios.post(`${window.envBackListenHost}/forkRouter/salesList/dequeue`, { contractAddress });
};

export const getContracts = async () => {
    return await axios.post(`${window.envBackHost}/contracts`);
};

export const getTradeChart = async (contract_id) => {
    return await axios.post(`${window.envBackHost}/contracts/chart`, { contract_id: contract_id });
};

export const completeTransfer = async (to_address, from_address, ctl_idx, token_id, tx_hash) => {
    return await axios.post(`${window.envBackHost}/market/completeTransfer`, {
        to_address,
        from_address,
        ctl_idx,
        token_id,
        tx_hash,
    });
};

export const postContractRegister = async (data) => {
    return await axios.post(`${window.envBackHost}/contracts/register`, data, { timeout: 30000 });
};

export const postCreateUser = async (data) => {
    return await axios.post(`${window.envBackHost}/users/create`, data);
};

export const postUser = async (data) => {
    return await axios.post(`${window.envBackHost}/users/user`, data);
};

export const postUpdateNickname = async (data) => {
    return await axios.post(`${window.envBackHost}/users/nickname`, data);
};

export const postUpdateThumbnail = async (data) => {
    return await axios.post(`${window.envBackHost}/users/thumbnail`, data);
};

export const getEctl = async (address?) => {
    return await axios.get(`${window.envBackHost}/contracts/ectl_list/${address}`);
};

export const getStakingViewData = async (data) => {
    return await axios.post(`${window.envBackHost}/en/getStakingViewData`, data);
};

export const getHasList = async (address) => {
    return await axios.post(`${window.envBackHost}/en/hasList`, { address });
};

export const getReceiptByTxHash = async (txHash) => {
    return await axios.post(`${window.envBackHost}/en/getReceiptByTxHash`, { txHash });
};

export const getHoldCount = async (data: any) => {
    return await axios.post(`${window.envBackHost}/en/holdCount`, data);
};
export const postBuyEvent = async (address) => {
    return await axios.post(`${window.envBackHost}/en/postBuyEvent`, { address });
};
export const postEstimatePos = async (data) => {
    return await axios.post(`${window.envBackHost}/en/estimatePos`, data);
};
