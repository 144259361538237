import React, { useCallback, useState } from 'react';
import _ from 'lodash';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux/connectors.redux';

import KlayMint from '@/helpers/KlayMint';
import { useLanguages } from '@/hooks/useLanguages.hook';

import './main.css';

import bg00 from '@/_statics/images/minigame.jpg';

// import Next12 from '@/_statics/images/main_next_series/Next1_2.png';
// import Next13 from '@/_statics/images/main_next_series/Next1_3.png';
// import Next14 from '@/_statics/images/main_next_series/Next1_4.png';
// import Next5 from '@/_statics/images/main_next_series/Next4.png';
import Next4 from '@/_statics/images/main_next_series/Next5.png';

import klayicon from '@/_statics/images/icon_kaikas.svg';

import { remoteWizardOpener, Wizard } from '@/_components/commons/wizard';
import { remoteModalOpener } from '@/_components/commons/modals';

import { Slide } from 'react-reveal';
import ImageSlider from '../_layouts/components/ImageSlider';
import Section from './components/Section';
import Box from './components/Box';
import MintArea from './components/MintArea';
import { setToken } from '@/redux/reducers/Wallet.reducer';
import { MessageEventType, MessageType } from '@/helpers/WebSocket/index.declare';
import useSendMessage from '@/hooks/Websocket/useSendMessage.hook';

const index = () => {
    const dispatch = useDispatch();
    const Lang = useLanguages();
    const wallet = useSelector((store: RootState) => store.Wallet);
    const { list } = useSelector((store: RootState) => store.Collections);
    const { mobile } = useSelector((store: RootState) => store.GlobalStatus);

    //초기 고정 데이터
    const [choiceMint, setChoiceMint] = useState(null);
    const [mintLock, setMintLock] = useState(false);
    const [mintAmount, setMintAmount] = useState(1);

    const soldOutState = useSendMessage(
        { MessageType: MessageType.soldOutData, MessageEventType: MessageEventType.soldOutData },
        60000,
    );
    const mintState = useSendMessage(
        { MessageType: MessageType.mintData, MessageEventType: MessageEventType.soldOutData },
        1000,
    );

    /**
     * Minnting 민팅 시도 시 발생 이밴트
     */
    const onMintModalOpenHandler = useCallback(() => {
        if (wallet.type === 'none') {
            remoteModalOpener('conn_modal');
        } else {
            remoteWizardOpener('wizzard');
        }
    }, [wallet]);

    const mintNowButtonSubmitHandler = async () => {
        if (wallet?.info?.balance < choiceMint.mtl_price)
            return window.toast('error', Lang.modal_check_mint_lack_to_klay);

        try {
            const klaymint = new KlayMint(choiceMint.contract_address, choiceMint.factory_address, list);

            await setMintLock(true);
            await klaymint.mintRequest(
                { wallet, mtl_idx: choiceMint.mtl_idx, mtl_price: choiceMint.mtl_price, amount: mintAmount },
                {
                    sucCallback(receipt?) {
                        remoteWizardOpener('wizzard');
                        setMintLock(false);
                        klaymint.getToken(wallet, list).then((res) => {
                            dispatch(
                                setToken({
                                    ...wallet.info.myToken,
                                    unlisted: res.unlisted,
                                    onSale: res.onSale,
                                }),
                            );
                        });
                        setMintAmount(1);
                        window.toast('success', Lang.suc_msg_sucs_mint);
                    },
                    failCallback() {
                        remoteWizardOpener('wizzard');
                        setMintLock(false);
                        setMintAmount(1);
                        window.toast('error', Lang.err_msg_sucs_mint);
                    },
                    exceptionCallback() {
                        remoteWizardOpener('wizzard');
                        setMintLock(false);
                        setMintAmount(1);
                    },
                },
                { dispatch: dispatch, Lang: Lang },
            );
        } catch (err) {
            setMintLock(false);
            console.error(err);
        }
    };

    return (
        <>
            <ImageSlider />
            <main className="container">
                {/* ************************** Minting... ************************** */}

                {/*/!* 오토민팅하는 데이터가 아직 로드 되지 않은 경우 *!/*/}
                {/*{_.size(mintingDatas.automint) <= 0 && _.size(mintingDatas.mint) <= 0 && (*/}
                {/*    <>*/}
                {/*        <Slide bottom>*/}
                {/*            <Section>*/}
                {/*                <Box className="p-3" title={Lang.main_box_title_mint}>*/}
                {/*                    <div className="sur flex-center">*/}
                {/*                        <div className="dove coll-lg-5 text-center mb-5">*/}
                {/*                            <h5 className="text-danger">series</h5>*/}
                {/*                            <h2 className="tit">Auto Mint</h2>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                    <Loader />*/}
                {/*                </Box>*/}
                {/*            </Section>*/}
                {/*        </Slide>*/}

                {/*        <Slide bottom>*/}
                {/*            <Section>*/}
                {/*                <Box className="p-3" title={Lang.main_box_title_mint}>*/}
                {/*                    <div className="sur flex-center">*/}
                {/*                        <div className="dove coll-lg-5 text-center mb-5">*/}
                {/*                            <h5 className="text-danger">series</h5>*/}
                {/*                            <h2 className="tit">MINTING</h2>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                    <Loader />*/}
                {/*                </Box>*/}
                {/*            </Section>*/}
                {/*        </Slide>*/}
                {/*    </>*/}
                {/*)}*/}

                {_.size(mintState) > 0 && (
                    <Slide bottom>
                        <Section>
                            <Box className="p-3" title={Lang.main_box_title_mint}>
                                <div className="sur flex-center">
                                    <div className="dove coll-lg-5 text-center mb-5">
                                        <h5 className="text-danger">series</h5>
                                        <h2 className="tit">MINTING</h2>
                                    </div>
                                </div>
                                {/* <div className="card-header border-0 text-center pb-2 btnm">
                            <button type="button" className="btnmint">
                                More
                            </button>
                        </div> */}
                                <MintArea
                                    id="mint"
                                    data={mintState}
                                    // requestRerendering={requestRerendering}
                                    // rerenderFlag={rerenderFlag}
                                    // count={mintCounts}
                                    onMintCallback={(chilrenData) => {
                                        onMintModalOpenHandler();
                                        setChoiceMint(chilrenData);
                                    }}
                                    style={mobile ? { padding: '0' } : {}}
                                />
                            </Box>
                        </Section>
                    </Slide>
                )}

                {/************************* next nft series 시작 *************************/}
                {false && (
                    <section className="py-7">
                        <div className="container-flu">
                            <div className="sur flex-center">
                                <div className="bg-holder" id="holder__dan"></div>
                                <div className="dove coll-lg-5 text-center">
                                    <h5 className="text-danger">{Lang.main_box_title_coming_soon}</h5>
                                    <h2 className="tit">{Lang.main_box_title_next}</h2>
                                </div>
                                <div className="pantoe slip pt-6" id="pantoeExampleDark" data-bs-ride="pantoe">
                                    <div className="pantoe-inner">
                                        <div className="pantoe-item act" data-bs-interval="10000">
                                            <div
                                                className="sur hf-100"
                                                style={{ display: 'flex', justifyContent: 'center' }}
                                            >
                                                <Slide bottom>
                                                    <div className="coll-md-4 mlb-3 mlb-mld-0">
                                                        <div className="carde hf-100 card-span pd-3">
                                                            {/* <div className="carde-body"> */}
                                                            <img
                                                                src={Next4}
                                                                alt="Next01"
                                                                onClick={
                                                                    () => window.open('https://www.moonnightpunks.io/')
                                                                    // (window.location.href = 'https://www.moonnightpunks.io/')
                                                                }
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                            {/* </div> */}
                                                        </div>
                                                    </div>
                                                </Slide>
                                                {/*<Slide bottom delay={100}>*/}
                                                {/*    <div className="coll-md-4 mlb-3 mlb-mld-0">*/}
                                                {/*        <div className="carde hf-100 card-span pd-3">*/}
                                                {/*            <img src={Next13} alt="Next02" />*/}
                                                {/*        </div>*/}
                                                {/*    </div>*/}
                                                {/*</Slide>*/}
                                                {/*<Slide bottom delay={200}>*/}
                                                {/*    <div className="coll-md-4 mlb-3 mlb-mld-0">*/}
                                                {/*        <div className="carde hf-100 card-span pd-3">*/}
                                                {/*            <img src={Next5} alt="Next03" />*/}
                                                {/*        </div>*/}
                                                {/*    </div>*/}
                                                {/*</Slide>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
                {/* our next nft series 끝 */}

                {/* ************************** SOLD OUT... ************************** */}
                <Slide bottom>
                    <Section>
                        <Box className="p-3" title={Lang.main_box_title_mint}>
                            <div className="sur flex-center">
                                <div className="dove coll-lg-5 text-center mb-5">
                                    <h5 className="text-danger">series</h5>
                                    <h2 className="tit">HISTORY</h2>
                                    {/*<h2 className="tit">Minting</h2>*/}
                                </div>
                            </div>
                            {/* <div className="card-header border-0 text-center pb-2 btnm">
                            <button type="button" className="btnmint">
                                More
                            </button>
                        </div> */}
                            <MintArea id="soldout" data={soldOutState} />
                        </Box>
                    </Section>
                </Slide>

                {/* ************* 게임 아이템 ************* */}
                <Slide bottom>
                    <Section>
                        <Box className="p-3" title={Lang.main_box_title_game_items}>
                            <div className="card mt-5">
                                {/* <div className="card-body">Coming Soon....</div> */}
                                <img width="100%" height="100%" src={bg00} style={{ position: 'relative' }} />
                                <p>
                                    KLAY MINT
                                    <br />
                                    MINIGAME
                                </p>
                            </div>
                        </Box>
                    </Section>
                </Slide>
            </main>

            {/* ********************************************************************************
             *  TRY Minting Modal
             *********************************************************************************/}
            <Wizard
                className="border-0"
                closeHold={true}
                id="wizzard"
                title={Lang.modal_check_mint_title}
                lock={mintLock}
            >
                <div>
                    <div className="row mx-4 mb-2 text-dark">
                        <hr />
                        <div className="d-flex justify-content-between p-0">
                            <p className="text-dark">Enter the amount</p>
                            <input
                                className="mint_amount_input"
                                type="number"
                                value={mintAmount}
                                onChange={(e) =>
                                    setMintAmount(() => {
                                        if (+e.target.value <= 10) return +e.target.value;
                                        else return 10;
                                    })
                                }
                                max={10}
                                min={1}
                            />
                        </div>
                        <div className="d-flex justify-content-between p-0 my-3">
                            <p className="text-dark">Mint</p>
                            <span className="text-dark">
                                <img width={16} src={klayicon} /> {choiceMint?.mtl_price * mintAmount} Klay
                            </span>
                        </div>
                        <div className="d-flex justify-content-between p-0" style={{ marginBottom: '1rem' }}>
                            <small className="fw-lighter">{Lang.modal_check_mint_text_hold}</small>
                            <small className="fw-lighter">{wallet.info.balance} Klay</small>
                        </div>
                    </div>
                    <div className="position-bottom bg-dark p-0 text-center">
                        <button disabled={mintLock} className="btn m-0 p-3" onClick={mintNowButtonSubmitHandler}>
                            {mintLock ? (
                                <div className="spinner-border text-light" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            ) : (
                                Lang.modal_check_mint_mint_now
                            )}
                        </button>
                    </div>
                </div>
            </Wizard>
        </>
    );
};

export default React.memo(index);
