export default {
    alert_please_excuting_mobile: '請在手機上使用',

    word_expiration_time: '截止時間',

    /**
     * 시스템 점검 관련 메세지
     */
    maintenance_system_check: '系統維修中',
    maintenance_upgrade_warning: '註意！',
    maintenance_upgrade_check: '正在收集能量以添加新功能.',
    maintenance_upgrade_full_charge_is_1hour: '需要大概1小時的能量收集時間',

    /****************************************************************************,
     * 화면 우측 하단의 Toast alert (메세지 알림 창) KLIP 연결 및 상태 업데이트 알람,
     */
    approved_klip_title: '交易批準',
    sell_klip_title: '銷售登錄交易',
    sell_cancel_klip_title: '銷售取消',
    buy_klip_title: '購買交易',
    mint_klip_title: '鑄造交易',

    // Toast Success message,
    suc_msg_sucs_sell_approved: '銷售登錄已獲批準。\n請繼續下一步.',
    suc_msg_sucs_sell: '銷售登錄完成',
    suc_msg_sucs_sellCancel: '銷售取消完成',
    suc_msg_sucs_buy: '購買完成',
    suc_msg_sucs_mint: '鑄造完成',

    // Toast Error message,
    err_msg_sucs_sell_approved: '無法批準銷售登錄.\n請重試.',
    err_msg_sucs_sell: '銷售無法註冊.\n請重試.',
    err_msg_sucs_sellCancel: '無法取消購買.\n請重試.',
    err_msg_sucs_buy: '購買失敗.\n請重試.',
    err_msg_sucs_mint: '鑄造失敗.\n請重試.',

    /**
     * 지갑 연결 시도 후 성공 메세지,
     * Toast Success message,
     */
    suc_msg_sucs_connect_kaikas: '已連接Kaikas',
    suc_msg_sucs_connect_klip: '已連接Klip',

    err_msg_address_check_kaikas: '請確認連接Kaikas的錢包地址',

    /**
     * 마이페이지의 Listed nft 카드 선택후 가격 입력시 메세지,
     */
    err_msg_fail_price_too_big: '數字太大',
    err_msg_fail_price_not_integers: '最多可輸入兩位小數.',
    err_msg_fail_price_empty: '請輸入Klay.',
    err_msg_fail_price_not_valid: '號碼無效',

    err_msg_fail_not_token_owner: '不是代幣持有者.',
    err_msg_fail_not_token_owner_factory: '代幣不出售',
    err_msg_fail_connect_wallet: '錢包未連接\n請重新連接',
    err_msg_fail_connect_kaikas: '連接Kaikas錢包失敗\n請重試.',
    err_msg_fail_connect_klip: '連接Klip錢包失敗\n請重試.',
    err_msg_need_kaikas: '連接Klip錢包失敗\n請重試.',
    err_msg_access_denaid: '拒絕連接. 請確認錢包狀態.',
    err_msg_fail_request: '無法加載數據.\n請稍後再做嘗試.',
    err_msg_fail_check_extension: '無法加載數據.\n請檢查Kaikas擴展程序.',
    err_msg_fail_do_not_test_network: '無法在測試網絡上使用',

    /**
     * Klip 연결 만료 Toast 메세지,
     */
    inf_msg_expires_in_one_minute: '一分鐘後將斷開連接',
    inf_msg_expires_in_disconnect: '已與Klip斷開連接',

    /**
     * 메인 메뉴 우측 상단 지갑주소 클릭시 나오는 메뉴,
     */
    inf_msg_copy_to_clipboard: '已復製',

    /**
     * 메인 상단 중앙의 메뉴 이름
     */
    header_menu0: '首頁',
    header_menu1: '收藏',
    header_menu2: '儀表板',
    header_menu3: '幫助',
    header_menu4: '個人中心',
    header_menu5: 'Per Plus',
    header_logout: '登出',
    /**
     * 메인 우측 상단 내 메뉴 클릭시 나오는 박스 메뉴,
     */
    header_my_menu: '我的菜單',

    header_collections: '收藏',
    header_my_page: '我的頁面',
    header_conn_wallet: '連接',
    header_disconn_wallet_Questions: '確定斷開連接？',
    header_disconn_wallet: '斷開連接',

    /**
     * 메인 우측 상단 연결하기 클릭시 나오는 모달 윈도우 내용,
     */
    header_modal_conn_title: '啟動Klayswap',
    header_modal_conn_kaikas: '連接Kaikas錢包',
    header_modal_separator: '或',
    header_modal_conn_desciption: '輕松安全地開始使用 KakaoTalk',
    header_modal_conn_klip: '使用KakaoTalk連接Klip',
    header_modal_conn_klip_notice: '介紹Kakao數字錢包‘Klip’',
    header_modal_auto_conn_wallet: '自動連接',

    header_modal_loading_title: '等待連接Kaikas錢包',
    header_modal_loading_description: '等待連接Kaikas錢包',

    header_modal_klip_title: '使用KakaoTalk連接Klip QR',
    header_modal_klip_description: '通過KakaoTalk 程序進行二維碼掃描.',
    header_modal_klip_footer_desc1: '實行KakaoTalk',
    header_modal_klip_footer_desc2: '點擊頂部搜索鍵',
    header_modal_klip_footer_desc3: '二維碼掃描',

    /**
     * 이용 약관,
     */
    footer_term_of_use: '使用條款',
    footer_supports: '客服',

    /**
     * 404 Not Found Page
     */
    not_found_404_title: '404 找不到網頁 :(',
    not_found_404_subscript: '無法找到該網頁',
    not_found_404_description1: '頁面不存在或頁面不可用',
    not_found_404_description2: '請再次確認輸入的地址',
    not_found_404_button_text: '回首頁',

    /**
     * 메인 페이지 내용 중 단위별 타이틀,
     */
    main_box_title_minting_now: '鑄造',
    main_box_title_coming_soon: '即將推出',
    main_box_title_next: '即將推出的NFT系列',
    main_box_title_market: '交易所',
    main_box_title_qna: 'Q & A',
    main_box_title_game_items: '遊戲物品',
    main_box_title_minting_create: '鑄幣廠',
    main_box_title_minigame: '迷你遊戲',

    /**
     * 콜렉션 리스트
     */
    list_no_collections: '沒有收藏',

    /**
     * 콜렉션 페이지 리스트 안내 문구,
     */
    list_no_my_items: '沒有該商品',
    //검색시 가져올 리스트가 없을때.
    list_no_sales: '沒有出售商品',

    list_more_item: '更多...',
    //더이상 가져올 리스트가 없을 때.
    list_end_response_lock: '沒有更多的出售商品',

    /**
     * 도움말 페이지의 질문 & 답변,
     */
    help_cent_block_1_title: 'KlayMint ?',
    help_cent_question_1_1: '1. 什麽是KlayMint?',
    help_cent_answer_1_1:
        'KlayMint 是一個提供可訪問性的平臺，因此任何人都可以通過 NFT 發起新價值和市場，並通過 NFT 輕松參與有趣且有價值的生態系統.\nKlaymint 通過 Klaytn 區塊鏈啟動為眾多尋求提供專業服務.\nKlayMint 創造了一個從項目啟動到交易階段的輕松有效平臺環境.',
    help_cent_question_1_2: '2. 如何使用KlayMint?',
    help_cent_answer_1_2:
        '任何想要發行NFT的個人、藝術家等都可以使用KlayMint. (在 Beta 和 Phase 1 階段，所有 NFT 都可以通過申請表發行 .)連接Kaikas 或 Klip錢包後， 可以享受各種不同的服務，例如：參與鑄造，交易服務，NFT 合成, 質押服務（Per Plus) 等.',

    help_cent_question_1_3: '3. 如何開始使用KlayMint?',
    help_cent_answer_1_3: '如要使用KlayMint, 請務必創建Kaikas或Klip錢包.',
    help_cent_answer_1_3_1: 'https://chrome.google.com/webstore/detail/kaikas/jblndlipeogpafnldhgmapagcccfchpi',
    help_cent_answer_1_3_2: '\n2) Klip : ',
    help_cent_answer_1_3_3: 'https://klipwallet.com/',
    help_cent_answer_1_3_4: '\n\n將您的錢包帳戶連接到 Klaymint以使用這些服務.',

    help_cent_question_1_4: '4. 如何在KlayMint上售賣NFTs?',
    help_cent_answer_1_4:
        "1) 点击右上角的‘连接’.\n\n2) 选择想连接的钱包(Kaikas或Klip)\n\n3)点击右上角‘我的菜单’裏的‘我的页面’.\n\n4)选择'UNLISTED'里的‘NFT Card SELL'.\n\n5)写下想要卖出的价钱后点击’SELL'",

    help_cent_question_1_5: '5. 如何取消KlayMint上的銷售交易？',
    help_cent_answer_1_5:
        "1) 從'我的菜單'裏選擇'我的頁面'.\n\n2) 點擊'ON SALE'.\n\n3)選擇已賣的NFT卡然後點擊'SELL CANCEL'.",

    help_cent_question_1_6: '6. 如何在KlayMint上發行我的收藏？',
    help_cent_answer_1_6:
        '任何想通過KlayMint發行NFT的個人/藝術家等可以點擊首頁底部的上市申請.\n\n 欲知更多詳情，可以聯系klaytn@klaymint.io.  ',
    help_cent_answer_1_6_1:
        'https://docs.google.com/forms/d/e/1FAIpQLSckNvWTn_cOFtMpOQfE_BSrM9C1otZCUDIuOww_DEpSsveBsA/viewform',

    help_cent_question_1_7: '7. KlayMint 在發行 NFT 時如何產生收益？',
    help_cent_answer_1_7:
        '除了一定費用外，KlayMint 的所有鑄幣銷售都將屬於該項目。 \n\n 1% 的 KlayMint 費用和 0~10%（可配置）的項目費用將通過鑄造 NFT 的二級交易支付.',

    help_cent_question_1_8: '8.無法正常使用質押服務？',
    help_cent_answer_1_8: ['按', '這裏', '然後發送交易便可以使用質押.'],

    help_cancel_center_check: "無法在'ON SALE'中查看您的 NFT 代幣？",
    help_cancel_center: '- KlayMint 從銷售清單中強製取消銷售',

    /**
     * 이용 약관,
     */
    term_of_use: '使用條款',
    term_of_use_title: 'KlayMint',

    validate_msg_required: '必須',
    validate_msg_num_char_or_less: '最多可輸入（）字',
    validate_msg_more_then_num_char: '最少得輸入（）字',

    modal_check_mint_title: '交易請求',
    modal_check_mint_text_hold: '保留',
    modal_check_mint_mint_now: '去鑄造',
    modal_check_mint_pending_transactions: '已有交易進行中',
    modal_check_mint_lack_to_klay: 'Klay不足',

    modal_check_text_approved: '承認',
    modal_check_need_data_check_a_approved: '需要確認批準狀態.',
    modal_check_sell_item_need_after_approved: '狀態批準後才能開始銷售',
    modal_check_little_fee: '手續費',

    /**
     * 프로젝트 등록 (collection 리스트 위)
     */
    collections_register_btn_title: '可用KLAYTN 鏈發行的 NFT進行交易',
    collections_register_btn_subtitle: '點擊這裏可以註冊項目',

    register_kip17_pfp_sections_title: '基於 KIP-17 標準的 PFP-NFT 項目.',
    register_kip17_pfp_sections_description_title: '條件',
    register_kip17_pfp_sections_description1: '1.交易支持的註冊人必須是鑄造人之一，並且必須輸入發行NFT合約.',
    register_kip17_pfp_sections_description2: '2. 註冊的合約審核通過後可以在第一時間進行交易。',
    register_kip17_pfp_sections_input_placeholder: '輸入合約地址',
    register_kip17_pfp_sections_btn_name: '註冊申請',

    suc_cfm_register_question: '確定要註冊嗎?',

    suc_msg_register_contract_address: '合約註冊成功',
    err_msg_invalid_contract_address: '合約地址無效',
    err_msg_duplicate_contract_address: '合約註冊失敗.',

    /**
     * my page
     */
    suc_msg_sucs_changed_nickname: '昵稱已更改',
    err_msg_faild_changed_nickname: '昵稱更改失敗. 請重新嘗試.',
    noti_msg_duplication_nickname: '此昵稱已註冊',

    /**
     * swap 관련 msg,
     */
    suc_msg_per_swap: 'Swap已完成',
    err_msg_per_swap: 'Swap失敗',
    err_msg_kilp_swap_denied: 'Swap服務不適用於Klip用戶',
    err_msg_check_per_balance: '請檢查所保留的PER數量後並重新輸入.',
    err_msg_check_klay_balance: '請檢查所保留的KLAY數量後並重新輸入.',

    /**
     * MegaPhone에서 출력되는 문자
     */
    mega_msg_reinforce_success: '太棒了！{nickname} 成功地合成<a className="{rateCSS}">{rating}</a>級別了.',

    /**
     * 합성페이지 관련
     */

    enforce: '合成',
    enforce_main_content_title: '請試試將2個NFT合成！',
    enforce_main_content_desc: '以獲得更高等級的NFT.',
    enforce_main_content_choice_main_card: '選擇一張要進行合成的卡',
    enforce_main_content_choice_sub_card: '選擇一張材料卡.',
    enforce_main_content_main_card: '合成時需要的卡',
    enforce_main_content_sub_card: '材料目標',
    enforce_main_content_main: '合成目標',
    enforce_main_content_item_amount: '物件數',
    enforce_main_content_sub: '材料',
    enforce_main_content_enforce_percentage: '成功率',
    enforce_main_content_enforce_percentage_m: '合成成功率',
    enforce_main_content_enforce_item_Betting: '物件使用',
    enforce_main_content_enforce_item_Betting_txt: '使用物件以 \n提高合成成功率！',
    enforce_main_content_enforce_item_etc_txt: '點擊該項目以取消選擇',
    enforce_main_content_enforce_info: '合成情報',
    enforce_main_content_enforce_class_scenario: '情景',
    enforce_main_content_enforce_choice_card: '請選擇2張卡',

    enforce_main_content_enforce_percentage_desc: [
        '材料卡只能以與合成卡相同或更低的等級進行.',
        '每個級別的合成概率如下',
    ],
    enforce_main_content_start_enforce_title: '合成已開始！',
    enforce_main_content_start_enforce_title_fail: '合成嘗試失敗!請稍後再做嘗試.',
    enforce_main_content_start_enforce_desc: ['结果可以在仪表板上查看.', '合成完成后, 将在仪表板上收到通知'],

    enforce_main_content_return: '回到首頁',
    enforce_main_content_result_fail_title: '合成失敗😓',
    enforce_main_content_result_fail_desc: ['別傷心', '送您一些有助於提高成功率的禮物.'],
    enforce_main_content_result_fail_item_desc: ['💎獲得 ', '物件！'],
    enforce_main_content_result_suc_title: '✨合成成功 🎉',
    enforce_main_content_result_suc_desc: ['合成卡與材料卡合成成功.', '合成過程中所使用的卡已被銷毀.'],

    enforce_perPlus_modal_transaction: '交易請求',
    enforce_perPlus_modal_transaction_confirmation: '若已檢查所有內容，是否要繼續？',
    enforce_perPlus_modal_kip7_approve_desc: '只在第一次執行一次的批準交易，以允許合約上的代幣.',
    enforce_perPlus_modal_kip17_approve_desc: '批準交易僅在合成所需的 KIP17 代幣轉移授權的第一次執行.',
    enforce_perPlus_modal_kip37_approve_desc:
        '關於KIP37代幣轉移的授權，僅在首次進行時批準交易，這是提高合成的成功率所必需的.',
    enforce_perPlus_modal_approve_next_desc: '交易成功後才可申請下一筆交易',

    enforce_perPlus_klip_modal_title: '執行交易',
    enforce_perPlus_modal_approve_suc: '進行下一個交易',
    enforce_perPlus_modal_approve_fail: '交易失敗',

    enforce_perPlus_modal_expected_result: '預測結果',
    enforce_perPlus_modal_have_per: '保留',

    enforce_inventory_choice_button: '選擇',
    enforce_inventory_cancel_button: '取消',
    enforce_inventory_sort_list_1: '低至高等級排序',
    enforce_inventory_sort_list_2: '高至低等級排序',
    enforce_inventory_sort_list: ['低至高等級排序', '高至低等級排序'],
    enforce_inventory_searchBox_placeholder: '請嘗試搜索NFT ID',
    enforce_inventory_myItems: '我的物件',

    enforce_dashboard_estimated_time: '約1分30秒',
    enforce_dashboard_estimated_time_desc: '預計所需時間 ',

    enforce_network_delay: 'Klaytn 網絡塊同步被延遲.\n 請稍後再做嘗試.',
    enforce_request_fail: '合成請求失敗.\n 請稍後再做嘗試.',

    enforce_Inventory: '目錄',
    enforce_Dashboard: '儀表板',

    mining_Power_grade: '可以查看每個等級的采礦力.',

    mining_power_th1: '等級',
    mining_power_th2: '采礦力',
    mining_power_th3: '可以質押的數量 ',
    mining_power_th4: '日期',
    mining_power_th5: '狀態',

    mining_power_th2_m: '采礦力',
    mining_power_th3_m: '數量',

    suc_klip_msg: '交易完畢',
    fail_klip_msg: '交易失敗. 請重新嘗試.',

    mobile_swap_alert: '此功能不適用於手機用戶.',

    enforce_cannot_be_used: '無法使用此卡',
    enforce_expected_mining_power: '預計挖礦量',
    /**
     * Swap 페이지 관련
     */

    swap: 'Swap',
    cancel_msg_per_swap: '取消',
    swap_header_h1: '直接交換到您選擇的資產',
    swap_header_h3: ['和', '網站提供的功能一致.請參閱指南.'],
    swap_hold: '保留',
    swap_input_placeholder: '請輸入數量',
    swap_exchange_rate: '轉換率',
    swap_expected_tarnce_value: '預計交易數量',
    swap_fee: '手續費',
    swap_transaction_history: '交易內容',

    /* 소각 페이지 관련 */
    Status: '銷毀',
    Status_h1: '顯示被銷毀的代幣狀態',
    Status_h3: '請查看PER代幣的最新狀態.',
    Status_burn_per: '當前已燒毀的PER',
    Status_circulation_per: '當前PER的流通量',

    /* 스테이킹 페이지 관련 */
    Staking: '質押',
    Staking_h1: '質押累計獎勵',
    Staking_h3: '存入PerFriends以獲得獎勵！',
    Staking_Klay_total_quantity: 'KLAY總數量',
    Staking_PER_total_quantity: 'PER總數量',

    imposableStaking: '無法質押的NFTs',
    // Staking_over_count: '스테이킹은 5장까지 할 수 있습니다.',
    Staking_over_count: ['最多能質押 ', '張卡'],
    // Staking_over_count: ['스테이킹은 ', '장 까지 가능합니다.'],
    Staking_multi_count_over: ['最多可以對 ', '張卡片進行多重抵押/取消抵押.'],

    staking_tx: '質押交易',
    staking_tx_suc: '質押完成.',
    staking_tx_fail: '質押未完成.',

    unStaking_tx: '取消質押交易',
    unStaking_tx_suc: '獎勵已領取完成.',
    unStaking_tx_fail: '取消質押失敗.',

    //================== staking common word ================================
    staking_common_staking: '質押',
    staking_common_unstaking: '取消質押',
    staking_common_gohome: '回到質押首頁',
    //=======================================================================
    staking_enter_button: '去質押',
    //================== staking Index page =================================
    staking_index_h1: '通过质押获得奖励',

    staking_tooltip_h6_1: '■ 獲得獎勵',
    staking_tooltip_p_1: '通過質押 NFT，您可以獲得正在開采的資產作為補償.',
    staking_tooltip_h6_2: '■ 質押',
    staking_tooltip_p_2:
        '通過質押持有的 NFT，您可以根據自己的采礦力對存入的資產進行挖礦. 如有多次抵押的情況，采礦力將被綜合.',
    staking_tooltip_h6_3: '■ 取消質押',
    staking_tooltip_p_3: '取消已被質押的NFT卡以獲得開采的資產.',

    staking_pool_text_klay: '總流動性',

    staking_historybox_amount: '可提款',

    staking_allWithdraw_button: '獎勵',
    staking_dostaking_button: '質押',
    staking_dounstaking_button: '取消質押',

    staking_separateItem_miningpower: '采礦力',
    staking_separateItem_shareratio: '持股比例',
    staking_separateItem_cumulative: '累計提款額',

    staking_expected_24h: '預計獎勵(24h)',
    staking_plz_login: '請登錄.',

    staking_list_carousel: '質押清單',

    //=======================================================================

    //================== staking doStaking page =============================
    staking_scenterContent_selectcard: ['選擇想要質押的', 'NFT'],
    staking_confirm_header: ['質押信息 | ', '總', '物件'],
    unStaking_confirm_header: ['取消質押信息 | ', '總', '物件'],
    //=======================================================================

    //================== staking successStaking =============================
    staking_success_h1: '質押完成！',
    staking_success_small: '請在交易記錄上查看結果',
    staking_transactionTable_caption: '交易內容',
    staking_transactionTable_thead1: '類別',
    staking_transactionTable_thead2: 'Hash',
    staking_transactionTable_thead3: '狀態',
    staking_transactionTable_thead4: 'Date',
    //=======================================================================

    //================== staking successUnStaking ===========================
    staking_unstakingsuccess_cardunstaking: '的取消質押已完畢',
    staking_unstakingsuccess_unstaking: '取消質押已完畢',
    staking_unstakingsuccess_small: '請在交易記錄上查看結果',
    //=======================================================================

    /* 기타 사항 */
    LogOut: '登出',
    Exit: '退出',
    NickName: '更換昵稱',
    Tooltip_Possible: '現在可以進行NFT合成',
    Tooltip_InPossible: '現在無法進行NFT合成. 請稍後再做嘗試.',
    Possible_value: '狀態',
    Possible_Instability: '不穩定',
    Possible_Stability: '穩定',
    Possible_Delay: '延遲',
    CheckCard_Modal_close_btn: '關閉',

    /* 更換昵稱 모달창 */
    Input_NickName: '昵稱',
    Input_Please: '輸入昵稱',
    Input_NickName_Edit: '修改',
    Input_Etc_comment: '只能輸入2-8韓/英文字, 不能輸入符號',

    /**
     * Tutorial
     **/
    tutorial_line: [
        "您好！很高興見到您. 我是小變色龍 ’Mel'!\n準備好和我一起探索PER Plus星球了嗎？ " /* 120px */,
        '首先,我們得升級以提升卡牌等級.\n升級時需要2張卡（強化與材料卡） ',
        '右側的物件欄中放置了一張時尚的 PoPo 卡片.\n讓我們把這張 PoPo 卡變得更強大更酷吧！ ' /* 120px */,
        '點擊一張卡片, 將它註冊為強化卡.\n讓我們把PoPo卡註冊為強化卡吧！ ' /* 120px */,
        "這時尚的PoPo卡等級’稀有‘！\n'材料卡'必須與'升級卡'的等級相同或低於! ",
        '點擊一張卡片, 將它註冊為材料卡.\n 用作強化的卡將會消失, 所以請謹慎選擇.\n現在, 讓我們選擇KAON卡作為升級PoPo卡的材料卡吧！ ' /* 120px */,
        '選擇完畢. 現在可以進行升級.\n別忘了下次升級的時候需要PER幣哦！ ' /* 120px */,
        '這次可以讓您免費體驗. 😊\n 現在, 點擊升級以強化PoPo卡！ ',
        '哇！您成功了！正在升級中.\n讓我們給一點時間讓時尚達人PoPo換服裝. ' /* 120px */,
        '升級結果顯示在儀表板上！\n讓我們在儀表板裏檢查升級結果！ ' /* 120px */,
        '成功！原本是稀有的PoPo卡現在已經升級為獨特.\n今天真的很開心, 謝謝您關註我. ',
        '好的, 時間到了！\n是時候為即將在 PER PLUS 星球上舉行的各種活動做好準備了! ' /* 120px */,
        '您也可以看看Swap和銷毀, 請四處看看！\n未來也會有新的活動和功能，請敬請期待! ',
        '如果您加入KlayMint的頻道以及PerFriends的Discord, \n我將會給您們帶來更多的好消息以及未來會舉辦的活動. 好期待我們下一次的見面！ ',
    ],
    tutorial_end: '您確定要退出教程嗎？',

    tutorial_Tooltop: '您可以再看一遍教程.',

    /**
     * 급작스런 모바일...
     */
    mobile_header_profile_dropdown_nickname_change: '更換昵稱',
    /**
     * 네비게이션 메뉴
     * */
    navi_menu_text_arr: ['合成', '交換', '質押', /*'銷毀',*/ 'Plus Minting'],

    add_per_token: '登錄代幣',

    add_per_token_suc: 'PER代幣登錄成功',
    add_per_token_fail: 'PER代幣登錄失敗。 請稍後再做嘗試.',

    swap_require_login: '登錄以使用Swap功能',
    not_not_enough_per: 'PER不足',

    /**
     * myPage 전송기능 관련
     */
    sell_button: '售賣',
    transfer_button: '傳送',

    err_msg_transfer: '傳送失敗. \n請重試.',
    suc_msg_transfer: '傳送成功',

    placeholder_msg_transfer: '輸入收款人的錢包地址',
    err_msg_transfer_invalid_address: '收款人地址無效',
    warning_msg_transfer: '* 傳送功能在某些項目中起不了作用',
    title_transfer: 'NFT 傳送',
    from_transfer: '發件人',
    to_transfer: '收件人',

    /**
     * evt modal text
     */
    evt_modal_session: '今日不再提示',
    evt_modal_close: '關閉',

    /**
     * 플러스 민팅 (auto minting) 페이지
     */
    plusMinting_pubilc_mint: '公開',
    plusMinting_pubilc_mint_price: '售賣價格',
    plusMinting_owner_mint: 'NFT',
    plusMinting_owner_mint_price: '名持有人',
    plusMinting_remaining_numbers: [' 剩余', '個'],
    plusMinting_check_number_of_nft_owned: '請檢查擁有的PerFriends NFT 數量',
    plusMinting_modal_enter_quantity: '請輸入NFT的數量',
    plusMinting_modal_sale_price: '鑄造價',
    plusMinting_modal_header: ['', '請選擇用來支付的資產。', '請求交易.'],
};
