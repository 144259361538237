import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import cx from 'classnames';
import flags from '@/_statics/languages/flags';

import { RootState } from '@/redux/connectors.redux';
import { keyNameLanguage, setLanguage } from '@/redux/reducers/GlobalStatus.reducer';
import css from '@/pages/_layouts/_layout.module.scss';

const BtnIconLanguages: React.FC<any> = (props): JSX.Element => {
    const { className } = props;

    const dispatch = useDispatch();
    const { language } = useSelector((store: RootState) => store.GlobalStatus);

    const [_langugae, _setLanguage] = useState<string>(language);

    const changeHandler = (strLanguage: string) => {
        _setLanguage(strLanguage);
        dispatch(setLanguage(strLanguage));
    };

    return (
        <div className="btn-group btn-group-link" role="group">
            <button
                id="language_flags"
                type="button"
                className={cx('btn btn-link btn-sm connectWalletButton', className)}
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <img
                    style={{ border: 'none' }}
                    className="img-thumanil rounded-circle border-2 border-secondary"
                    src={flags[_langugae].src}
                    width="24px"
                    height="24px"
                />
            </button>
            <ul className="dropdown-menu" aria-labelledby="BtnLanguage">
                {_.map(flags, (el, key) => {
                    return (
                        <li key={key}>
                            <button type="button" className="dropdown-item" onClick={() => changeHandler(key)}>
                                <img src={el.src} width="32px" /> {el.name}
                            </button>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

const BtnStrLanguages: React.FC<any> = (props): JSX.Element => {
    const { className, style } = props;

    const dispatch = useDispatch();
    const { language } = useSelector((store: RootState) => store.GlobalStatus);

    const [_langugae, _setLanguage] = useState<string>(language);

    const changeHandler = (strLanguage: string) => {
        _setLanguage(strLanguage);
        dispatch(setLanguage(strLanguage));
    };

    const changeHandler_copy = () => {
        // 언어팩 지원이 추가되면 여기에 추가만 하면 되게끔
        const allLang = [ 'zh-CHT', 'zh-CHS', 'ko-KR','en-US'];

        const currentLangIdx = allLang.indexOf(language);
        const nextLangIdx = currentLangIdx ? currentLangIdx - 1 : allLang.length - 1;

        _setLanguage(allLang[nextLangIdx]);
        dispatch(setLanguage(allLang[nextLangIdx]));
    };

    return (
        <div className="btn-group btn-group-link" role="group">
            <button
                id="language_flags"
                type="button"
                className={cx('btn connectWalletButton text-white', className, css.headerModalButton)}
                // data-bs-toggle="dropdown"
                aria-expanded="false"
                onClick={changeHandler_copy}
                // style={style}
                style={{ marginTop: '1px' }}
            >
                <b>{flags[_langugae].code}</b>
                {/*<small>*/}
                {/*    <i className="fas fa-chevron-down mx-2" />*/}
                {/*</small>*/}
            </button>
            <div className="dropdown-menu dropdown-menu-end bg-dark border-white" aria-labelledby="BtnLanguage">
                {_.map(flags, (el, key) => {
                    return (
                        <button
                            key={key}
                            type="button"
                            className="dropdown-item text-white"
                            onClick={() => changeHandler(key)}
                        >
                            <b>{el.code}</b> <span className="mx-3">{el.name}</span>
                        </button>
                    );
                })}
            </div>
        </div>
    );
};

export { BtnIconLanguages, BtnStrLanguages };
