/**
 * @title 웹 소켓 매니저 리덕스
 * @author pts
 * @date 20210730
 * @version 1.0.0
 * @description 웹 소켓관리
 */

import { handleActions } from 'redux-actions';
import { applyPenders } from 'redux-pender';
import WebSocketClientModel from '@/helpers/WebSocket/webSocketClient.helper';

const initialState = {
    webSocketClientModel: new WebSocketClientModel(new WebSocket(window.envBackWebSocketHost)),
};

/** ***************** ACTIONS  ****************** */

/** ***************** ACTION FUNCTIONS ****************** */

const reducer = handleActions({}, initialState);

export default applyPenders(reducer, []);
