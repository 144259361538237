import React from 'react';
import cx from 'classnames';
import css from './ProfileImage.module.scss';
import web from '@/_statics/images/sns/web.svg';
import twitter from '@/_statics/images/sns/twitter.svg';
import discord from '@/_statics/images/sns/discord.svg';
import medium from '@/_statics/images/sns/medium.svg';
import instagram from '@/_statics/images/sns/instagram.svg';
import telegram from '@/_statics/images/sns/telegram.svg';
import kakao from '@/_statics/images/sns/kakao.png';
import kakaoCh from '@/_statics/images/sns/kakaoCh.png';

const ProfileImage = (props) => {
    const snsSvgPicker = (name) => {
        switch (name) {
            case 'web':
            case 'web2':
            case 'web3':
                return (
                    <svg className="" fill="#8A939B" viewBox="0 0 20 16">
                        <path d="M17.569.5H2.43C1.39.5.548 1.344.548 2.375l-.01 11.25A1.89 1.89 0 002.43 15.5H17.57a1.89 1.89 0 001.892-1.875V2.375A1.89 1.89 0 0017.57.5zm-4.73 13.125H2.43v-3.75h10.408v3.75zm0-4.688H2.43v-3.75h10.408v3.75zm4.73 4.688h-3.785V5.187h3.785v8.438z"></path>
                    </svg>
                );
                break;
            case 'twitter':
            case 'twitter2':
            case 'twitter3':
                return (
                    <svg className="" fill="#8A939B" viewBox="0 0 18 16">
                        <path d="M.09 13.791c1.992.14 3.728-.344 5.327-1.571-.816-.098-1.527-.311-2.127-.786-.584-.466-1.032-1.047-1.272-1.841h1.48c.008-.033.016-.066.024-.107-.816-.237-1.512-.663-2.032-1.342-.52-.67-.775-1.448-.8-2.3.52.148 1.016.295 1.52.434.016-.033.04-.065.056-.098-.72-.606-1.24-1.334-1.431-2.275a3.92 3.92 0 01.391-2.7c2 2.389 4.511 3.715 7.598 3.936-.096-.778-.104-1.498.16-2.202.912-2.463 3.983-3.249 5.894-1.481.216.196.4.229.632.147.632-.229 1.255-.474 1.903-.72-.248.81-.784 1.408-1.415 1.989.615-.164 1.231-.336 1.839-.5.024.025.048.041.072.066-.464.491-.912 1.007-1.415 1.449-.272.237-.36.458-.376.818-.144 4.01-1.752 7.25-5.175 9.289-3.487 2.07-7.077 1.947-10.612-.025-.064-.04-.12-.09-.24-.18z"></path>
                    </svg>
                );
                break;
            case 'discord':
            case 'discord2':
            case 'discord3':
                return (
                    <svg className="" fill="#8A939B" viewBox="0 0 22 16">
                        <path d="M8.11.5c-.28.002-2.574.067-4.996 1.873 0 0-2.584 4.665-2.584 10.408 0 0 1.507 2.593 5.473 2.719 0 0 .664-.792 1.202-1.477-2.278-.685-3.14-2.108-3.14-2.108s.18.126.502.307c.018 0 .035.019.07.036.055.035.108.054.162.09.448.252.896.45 1.31.611.736.307 1.615.576 2.639.774 1.346.252 2.925.342 4.646.019a12.954 12.954 0 002.603-.774 10.118 10.118 0 002.062-1.063s-.896 1.458-3.247 2.125c.538.666 1.185 1.439 1.185 1.439 3.965-.126 5.473-2.72 5.473-2.7 0-5.746-2.584-10.409-2.584-10.409C16.32.446 13.861.5 13.861.5l-.251.288c3.05.918 4.468 2.27 4.468 2.27a14.856 14.856 0 00-5.4-1.711 15.048 15.048 0 00-3.626.036c-.107 0-.197.019-.306.035-.628.072-2.153.288-4.073 1.135-.663.288-1.059.505-1.059.505S5.088 1.635 8.317.717L8.137.5h-.028zm-.457 6.645c1.022 0 1.849.882 1.83 1.981 0 1.1-.808 1.982-1.83 1.982-1.005 0-1.83-.883-1.83-1.982s.806-1.981 1.83-1.981zm6.55 0c1.004 0 1.83.882 1.83 1.981 0 1.1-.809 1.982-1.83 1.982-1.006 0-1.83-.883-1.83-1.982s.806-1.981 1.83-1.981z"></path>
                    </svg>
                );
                break;
            case 'medium':
            case 'medium2':
            case 'medium3':
                return (
                    <svg className="" fill="#8A939B" viewBox="0 0 18 15">
                        <path d="M17.83 14.847h-7.003c.088-.186.129-.34.225-.444.37-.388.781-.744 1.143-1.131.113-.122.218-.324.218-.485.016-2.56.008-5.113.008-7.674 0-.04-.008-.073-.032-.21-.089.218-.153.355-.21.493-1.191 3.029-2.39 6.066-3.582 9.095-.072.178-.096.372-.386.372-.282.008-.362-.153-.45-.355a4583.69 4583.69 0 01-4.163-9.112c-.072-.154-.144-.307-.281-.445v.3c0 2.124-.016 4.248.008 6.38 0 .243.12.526.273.728.532.694 1.103 1.357 1.65 2.036.09.113.137.258.234.444H.122c.088-.186.136-.348.233-.46.523-.647 1.046-1.293 1.585-1.931.234-.283.338-.582.338-.954-.016-2.544 0-5.089-.024-7.633 0-.267-.129-.574-.29-.792-.426-.582-.91-1.107-1.352-1.664C.507 1.268.452 1.098.33.872h2.182c.87 0 1.747.008 2.616-.008.282-.008.418.089.531.347 1.192 2.658 2.407 5.3 3.614 7.949.049.105.105.21.186.371.563-1.405 1.11-2.754 1.65-4.103.571-1.422 1.127-2.844 1.706-4.257.049-.121.193-.291.29-.291C14.667.864 16.22.872 17.878.872c-.112.194-.16.34-.257.444-.37.372-.773.711-1.12 1.099-.144.161-.265.412-.265.622-.016 3.215-.016 6.43 0 9.653 0 .21.121.46.266.622.338.388.724.719 1.079 1.09.08.106.136.251.25.445z"></path>
                    </svg>
                );
                break;
            case 'instagram':
            case 'instagram2':
            case 'instagram3':
                return (
                    <svg className="" fill="#8A939B" viewBox="0 0 24 24">
                        <path d="M15.75 2H8.25C4.79875 2 2 4.79875 2 8.25V15.75C2 19.2012 4.79875 22 8.25 22H15.75C19.2012 22 22 19.2012 22 15.75V8.25C22 4.79875 19.2012 2 15.75 2ZM20.125 15.75C20.125 18.1625 18.1625 20.125 15.75 20.125H8.25C5.8375 20.125 3.875 18.1625 3.875 15.75V8.25C3.875 5.8375 5.8375 3.875 8.25 3.875H15.75C18.1625 3.875 20.125 5.8375 20.125 8.25V15.75Z"></path>
                        <path d="M12 7C9.23875 7 7 9.23875 7 12C7 14.7613 9.23875 17 12 17C14.7613 17 17 14.7613 17 12C17 9.23875 14.7613 7 12 7ZM12 15.125C10.2775 15.125 8.875 13.7225 8.875 12C8.875 10.2763 10.2775 8.875 12 8.875C13.7225 8.875 15.125 10.2763 15.125 12C15.125 13.7225 13.7225 15.125 12 15.125Z"></path>
                        <path d="M17.375 7.29124C17.743 7.29124 18.0413 6.99295 18.0413 6.62499C18.0413 6.25703 17.743 5.95874 17.375 5.95874C17.0071 5.95874 16.7088 6.25703 16.7088 6.62499C16.7088 6.99295 17.0071 7.29124 17.375 7.29124Z"></path>
                    </svg>
                );
                break;
            case 'telegram':
            case 'telegram2':
            case 'telegram3':
                return (
                    <svg className="" fill="#8A939B" viewBox="0 0 24 16">
                        <path
                            clipRule="evenodd"
                            d="M18.28 15.456c.317.168.725.21 1.09.107.363-.104.631-.337.712-.62.854-3.013 2.928-10.64 3.706-13.38.06-.207-.04-.421-.256-.56A1 1 0 0022.748.9C18.625 2.045 5.921 5.62.729 7.06c-.329.092-.543.33-.532.59.012.262.246.488.583.564 2.329.522 5.385 1.25 5.385 1.25s1.428 3.234 2.173 4.88c.093.206.309.369.593.425.283.055.586-.003.798-.153l3.046-2.157s3.513 1.933 5.506 2.997zM7.45 9.054L9.1 13.14l.367-2.587 10.02-6.778c.106-.072.12-.193.032-.278-.088-.085-.249-.104-.37-.047L7.45 9.054z"
                            fillRule="evenodd"
                        ></path>
                    </svg>
                );
                break;
            case 'kakao':
            case 'kakao2':
            case 'kakao3':
                return <img src={kakao} alt="kakaoSNS" />;
                break;
            case 'kakaoCh':
            case 'kakaoCh2':
            case 'kakaoCh3':
                return <img src={kakaoCh} alt="kakaoChSNS" />;
                break;
            default:
                return null;
                break;
        }
    };

    return (
        <div className={css.imgSection}>
            <h3>{props.name}</h3>
            <img src={props.src} alt="" className={cx(css.circleImg, 'rounded-circle')} />
            {props.collection.sns && (
                <div className={css.snsContainer}>
                    {Object.keys(props.collection.sns).map((item) => {
                        return (
                            <div
                                key={props.collection.sns[`${item}`]['sss_idx']}
                                onClick={() => window.open(props.collection.sns[`${item}`]['sss_uri'])}
                                className={css.snsBoxContainer}
                            >
                                {snsSvgPicker(item)}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default ProfileImage;
