import React, { useState } from 'react';
import cx from 'classnames';
import css from './registers.module.scss';

import { useForm } from 'react-hook-form';

import { useLanguages } from '@/hooks/useLanguages.hook';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/connectors.redux';

import { postContractRegister } from '@/helpers/klaymint.api';
import { required, hexFormat } from '@/helpers/validate.helper';
import KASHelper from '@/helpers/KAS.helper';

const caverExtKas = new KASHelper();

const Registers: React.FC<any> = (): JSX.Element => {
    const Lang = useLanguages();
    const wallet = useSelector((store: RootState) => store.Wallet);
    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        setFocus,
        setValue,

        formState: { isDirty, isSubmitting, errors },
    } = useForm({ mode: 'all', criteriaMode: 'firstError', shouldFocusError: true });

    const onSubmit = async (formData: Record<string, any>) => {
        setLoading(true);

        const validate = await caverExtKas.validateContract(formData['contract_address']);

        if (!validate) {
            setLoading(false);
            return window.toast('error', Lang.err_msg_invalid_contract_address);
        }

        if (!window.confirm(Lang.suc_cfm_register_question)) {
            setLoading(false);
            return;
        }

        formData['request_address'] = wallet.info.address ? wallet.info.address : null;

        postContractRegister(formData)
            .then((res) => {
                setLoading(false);
                if (res.data[0] <= 0) {
                    window.toast('error', Lang.err_msg_duplicate_contract_address);
                    return;
                }
                window.toast('info', Lang.suc_msg_register_contract_address);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    return (
        <div className="container-fluid min-vh-100">
            <div className="contaniner">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row d-flex justify-content-center mt-5">
                        <div className={cx('card col-8 mt-5', css.registerCard)}>
                            <div className="card-body text-white">
                                <div className="card-title">
                                    <h4>{Lang.register_kip17_pfp_sections_title}</h4>
                                </div>
                                <div className="card-text text-center mt-4 mb-1">
                                    <div className="alert alert-warning d-flex text-start" role="alert">
                                        <i className="fas fa-exclamation-triangle mx-2" />
                                        {Lang.register_kip17_pfp_sections_description_title}
                                        <br /> {Lang.register_kip17_pfp_sections_description1}
                                        <br /> {Lang.register_kip17_pfp_sections_description2}
                                    </div>
                                </div>
                                <input type="text" value="kip17" {...register('type')} style={{ display: 'none' }} />
                                <input
                                    type="text"
                                    className={cx('col-12', css.caInput)}
                                    placeholder={Lang.register_kip17_pfp_sections_input_placeholder}
                                    maxLength={100}
                                    {...register('contract_address', {
                                        validate: {
                                            required: required,
                                            pattern: hexFormat,
                                        },
                                    })}
                                />
                                <div className={cx('m-2', css.formatErrorTooltip)}>
                                    <small>{errors['contract_address']?.message}</small>
                                </div>

                                <div className="card-footer text-end px-0">
                                    <button
                                        className={cx('btn btn-light', css.registerButton)}
                                        type="submit"
                                        disabled={loading}
                                    >
                                        {loading && (
                                            <span
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                        )}
                                        {Lang.register_kip17_pfp_sections_btn_name}
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* <div className={cx('card col-8 mt-5', css.registerCard)}>
                        <div className="card-body">
                            <div className="card-title">KIP-17</div>
                            <div className="card-text">fdsfjdlskjklfjdlskjfksdj</div>
                        </div>
                        <div className="card-footer">flksdfkldsjflksdfjklsdj</div>
                    </div>

                    <div className={cx('card col-8 mt-5', css.registerCard)}>
                        <div className="card-body">
                            <div className="card-title">KIP-17</div>
                            <div className="card-text">fdsfjdlskjklfjdlskjfksdj</div>
                        </div>
                        <div className="card-footer">flksdfkldsjflksdfjklsdj</div>
                    </div> */}
                    </div>
                </form>
            </div>
        </div>
    );
};
export default Registers;
