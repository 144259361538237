import React, { useEffect, useState, useRef, useCallback } from 'react';
import css from './Article.module.scss';
import cx from 'classnames';
import _ from 'lodash';
import { Slide } from 'react-reveal';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux/connectors.redux';
import { ArticleModalProps } from '@/_components/commons/modals/_ArticleModal.interfaces.declare';
import { numberWithCommas } from '@/helpers/common.helper';

import useList from '@/hooks/useList.hook';
import { useLanguages } from '@/hooks/useLanguages.hook';

import DetailModal from '@/pages/CollectionsDetail/components/Article.DetailModal';
import NoItems from '@/_components/commons/NoItems';

import iconKaikas from '@/_statics/images/icon_kaikas.svg';
import S from '@/_statics/images/PerPlus/s.svg';
import KlayMint from '@/helpers/KlayMint';
import { remoteModalOpener } from '@/_components/commons/modals';
import { CollectionsDetailSearchProps } from '@/pages/CollectionsDetail/CollectionsDetail.interfaces.declare';

interface Props {
    search: CollectionsDetailSearchProps;
    collection: any;
    originList: any;
    optimisticDelete: any;
}

const Article: React.FC<any> = ({ search, collection, originList, optimisticDelete }: Props): JSX.Element => {
    const { classs, list } = useSelector((store: RootState) => store.Collections);
    const { mobile } = useSelector((store: RootState) => store.GlobalStatus);

    const klaymint = new KlayMint(collection.contract_address, collection.factory_address, list);

    const moreRef = useRef(null);
    const Lang = useLanguages();

    const [loading, setLoading] = useState<boolean>(false);
    const [listEnd, setListEnd] = useState<boolean>(false);

    const [modal, setModal] = useState(false);
    const [modalProps, setModalProps] = useState({} as ArticleModalProps);

    const [scrollList, setScrollList] = useState([]);

    const {
        state,
        onRefresh,
        onAppend,

        pages,
        movePage,
        onCallback,
        scrollEventListener,
    } = useList({});

    const wallet = useSelector((store: RootState) => store.Wallet);
    const dispatch = useDispatch();

    const onMintHandler = useCallback(() => {
        if (wallet.type === 'none') {
            remoteModalOpener('conn_modal');
        }
    }, [wallet]);

    const loadArticleModal = async (item) => {
        const isWallet = wallet.info.address !== '';
        const isOwner = wallet.info.address.toLowerCase() === item.data.owner_address.toLowerCase();

        const attrArr = item.data?.attributes
            ?.filter((item) => item.trait_type !== 'id')
            .map((item) => Object.values(item));

        const currentClasss = classs[collection?.id];

        setModalProps({
            collection: collection,
            props: {
                title: `${item.data.name}`,
                mainImage: `${item.data.image}`,
                mainVideo: item.data.video ?? null,
                mainDescButtons: [
                    `OWNED BY ${item.data.owner_address.slice(0, 3)}...${item.data.owner_address.slice(-3)}`,
                ],
                mainDesc: item.data.description,
                mainAttrs: attrArr,
                mainAttrDesc: currentClasss,
                footerPrice: item.data.sales_price,
                footerPriceHistory: item.data.last_price !== '0.00' ? item.data.last_price : null,
                footerButtons: !isWallet ? 'CONNECT WALLET' : !isOwner ? 'BUY' : 'SELL CANCEL',
                onClick: {
                    event: !isWallet ? onMintHandler : !isOwner ? klaymint.buyRequest : klaymint.sellCancelRequest,
                    argument: !isOwner
                        ? [item.data.token_id, item.data.sales_price, dispatch]
                        : [item.data.token_id, dispatch],
                },
                item: item,
            },
            optimisticDelete: optimisticDelete,
        });
        setModal(true);
    };

    const requestMoreList = useCallback(
        (prev, next) => {
            if (loading || listEnd) return; //로딩중이거나 리스트 마지막이면 요청 안함
            //if (!collection.id) return; //첫 렌더의 props 값에 contractListId 가 없으면 요청안함

            setLoading(true);

            const newArr = prev.concat(next.slice(prev.length, prev.length + 10));
            setScrollList(newArr);
            if (newArr.length === originList.length) setListEnd(true);
            setLoading(false);
        },
        [originList, search, scrollList],
    );

    useEffect(() => {
        if (loading) return; //로딩중이거나 리스트 마지막이면 요청 안함

        setLoading(true);

        const initArr = originList.slice(0, 10);
        setScrollList(initArr);

        if (initArr.length === originList.length) setListEnd(true);
        else setListEnd(false);
        setLoading(false);
    }, [search, originList]);

    /************************************************************
     * 스크롤 이밴트 핸들링 1회 등록
     ***********************************************************/
    useEffect(
        () =>
            scrollEventListener(
                document,
                () => {
                    console.log('on [collections] Refresh');
                },
                () => {
                    console.log('on [collections] load append more list');
                    moreRef?.current?.click();
                    // if (!listEnd) requestMoreList([...scrollList], [...originList]);
                },
            ),
        [],
    );

    useEffect(() => {
        modal ? (document.body.style.overflowY = 'hidden') : (document.body.style.overflowY = 'unset');
    }, [modal]);

    return (
        <>
            <section className={cx('row', css.ArticleSection)}>
                {_.size(scrollList) <= 0 && <NoItems>{Lang.list_no_sales}</NoItems>}
                {_.map(scrollList, (item, index) => {
                    item.data.image = item.data.image?.replace('ipfs://', 'https://klaymint.mypinata.cloud/ipfs/');

                    if (item.data.sales_price !== '0.00' || item.data.sales_price !== 0) {
                        return (
                            <Slide key={item.id} duration={500} bottom cascade>
                                <div
                                    className={cx('col-md-2', css.articleContainer)}
                                    onClick={() => loadArticleModal(item)}
                                >
                                    <div className={css.card}>
                                        <div className={css.imgContainer}>
                                            {item.data.video ? (
                                                <video controls autoPlay muted loop>
                                                    <source src={item.data.video}></source>
                                                </video>
                                            ) : (
                                                <img src={item.data.image} alt="nft" />
                                            )}
                                            {item.data.isStaking ? (
                                                <img src={S} alt="S" className={css.StakingcardMark} />
                                            ) : null}
                                        </div>
                                        <div
                                            className={css.txtContainer}
                                            style={mobile ? { display: 'block' } : undefined}
                                        >
                                            <div>
                                                <h5>{item.data.name}</h5>
                                                <h5>{item.data.class}</h5>
                                            </div>
                                            <button className="col-12 text-break" type="button">
                                                <div className={css.buttonImgSection}>
                                                    <img alt="KLAY" src={iconKaikas} />
                                                </div>
                                                <span className="overflow-visible">
                                                    {numberWithCommas(item?.data?.sales_price, '')}
                                                </span>
                                            </button>
                                        </div>
                                        {item.data.last_price !== '0.00' && item.data.last_price ? (
                                            <span className={css.lastPriceSpan}>
                                                {/* last <img alt="KLAY" src={iconKaikas} /> {numberToEng(item.data.last_price)} */}
                                                last <img alt="KLAY" src={iconKaikas} /> {item.data.last_price}
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                            </Slide>
                        );
                    }
                })}
                {/* 로딩 & 더보기 버튼 */}
                <div
                    ref={moreRef}
                    className="d-flex justify-content-center"
                    onClick={() => requestMoreList([...scrollList], [...originList])}
                >
                    <div className={cx('card border-0 col-10', css.moreBtn)}>
                        <div className="card-body text-center text-white">
                            {loading ? (
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            ) : listEnd ? (
                                Lang.list_end_response_lock
                            ) : (
                                Lang.list_more_item
                            )}
                        </div>
                    </div>
                </div>
            </section>
            {/* Item 클릭시 Modal 열기 */}
            {modal ? (
                <DetailModal
                    setModal={setModal}
                    modalProps={modalProps}
                    collection={collection}
                    optimisticDelete={optimisticDelete}
                />
            ) : null}
        </>
    );
};

export default Article;
