import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { prepare } from 'klip-sdk';
import cx from 'classnames';
import css from './../_layout.module.scss';

import { useLanguages } from '@/hooks/useLanguages.hook';
import usePreventLeave from '@/hooks/usePreventLeave.hook';
import { copyToClipboard } from '@/helpers/common.helper';
import Profile from '@/_components/commons/profiles/profile1';
import perLogo_ from '@/_statics/images/PerPlus/perplus_icon.svg';
import { autologin } from '@/redux/reducers/GlobalStatus.reducer';
import { saveWallet, setWallet, resetWallet } from '@/redux/reducers/Wallet.reducer';
import { RootState } from '@/redux/connectors.redux';

import { ModalButton, Modal, remoteModalOpener } from '@/_components/commons/modals';
import { BtnStrLanguages } from '@/_components/commons/buttons/BtnLanguages';
import { getKlayFromAddress } from '@/helpers/klaymint.api';

import iconKlip from '@/_statics/images/icon_klip.svg';
import iconKlip_W from '@/_statics/images/icon_klip_w.png';
import iconKaikas from '@/_statics/images/icon_kaikas.svg';
import logo from '@/_statics/images/mint-logo.svg';

import { myBAppName } from '@/includes/envVariables';
import KaikasHelper from '@/helpers/Wallet/kaikas.helper';
import KlipHelper from '@/helpers/Wallet/klip.helper';

const Header: React.FC<any> = (): JSX.Element => {
    const dispatch = useDispatch();
    const Lang = useLanguages();

    const closeConnectModalRef = useRef(null);
    const closeLoadingModalRef = useRef(null);

    /**
     * 새로고침 또는 페이지 이동 발생시 redux Store에 있는 유저정보를
     * sessionStorage에 임시 저장
     * */
    const { enablePrevent, disablePrevent } = usePreventLeave(() => {
        dispatch(saveWallet(wallet));
    });

    const { mobile } = useSelector((store: RootState) => store.GlobalStatus);
    const wallet = useSelector((store: RootState) => store.Wallet);

    /**
     * KAIKAS 연결 핸들러
     */
    const connectKaikasHandler = async () => {
        const kaikasHelper = new KaikasHelper(window.klaytn);
        await kaikasHelper.login({ wallet }, { Lang, dispatch });

        setTimeout(() => {
            if (closeLoadingModalRef.current !== null) closeLoadingModalRef.current.click();
        }, 1000);
    };

    /**
     * KLIP 연결 핸들러
     */
    const connectKlipHandler = async () => {
        closeConnectModalRef.current.click();

        const klipHelper = new KlipHelper();
        const res = await prepare.auth({ bappName: myBAppName, successLink: '#', failLink: '#' });
        res.type = 'login';

        await klipHelper.sendTransaction(
            res,
            {
                async sucCallback(result) {
                    const balance = await getKlayFromAddress(result.klaytn_address);
                    await dispatch(
                        setWallet({
                            isConn: true,
                            type: 'klip',
                            info: {
                                ...wallet.info,
                                name: 'My Wallet',
                                address: result.klaytn_address,
                                balance: balance.data,
                                expire: res.expiration_time * 1000,
                            },
                        }),
                    );

                    setTimeout(() => {
                        window?.toast('noti', Lang.inf_msg_expires_in_one_minute);
                    }, res.expiration_time * 1000 - new Date().getTime() - 60000);

                    setTimeout(() => {
                        dispatch(resetWallet());
                        window?.toast('alert', Lang.inf_msg_expires_in_disconnect);
                    }, res.expiration_time * 1000 - new Date().getTime());

                    await dispatch(autologin(true));
                    sessionStorage.setItem('klmin_lt', 'klip');
                },
                failCallback() {},
            },
            { dispatch, Lang },
        );
    };

    /**
     * 지갑 연결 해제
     */
    const disconnectWallet = () => {
        if (!confirm(Lang.header_disconn_wallet_Questions)) return;
        dispatch(resetWallet());
        dispatch(autologin(false));
        sessionStorage.removeItem('klmin_lt');
        window?.toast('error', Lang.header_disconn_wallet);
    };

    useEffect(() => {
        const { klaytn } = window;

        if (klaytn && wallet.type === 'kaikas') {
            const kaikasHelper = new KaikasHelper(klaytn);
            kaikasHelper.isUnlocked({ wallet: wallet }, { dispatch, Lang });
            kaikasHelper.accountsChanged({ wallet: wallet }, { dispatch, Lang });
            kaikasHelper.networkChanged({ wallet: wallet }, { dispatch, Lang });
        }

        //새로고침시 지갑 정보 저장 이밴트 핸들러 시작
        enablePrevent();

        //새로고침시 지갑 정보 저장 이밴트 핸들러 종료
        return () => {
            console.log('disable Restore Wallat Data');
            disablePrevent();
        };
    }, [wallet.type]);

    return (
        <div className={cx('position-absolute top-0 start-0 p-3', css.headerSection)}>
            {/* *********************************************************************************************** */}
            {/* **************************************** Header Section *************************************** */}
            {/* *********************************************************************************************** */}
            <header className="blog-header position-relective top-0 start-0">
                {mobile ? (
                    <MobileHeaderJSX wallet={wallet} disconnectWallet={disconnectWallet} />
                ) : (
                    <PcHeaderJSX wallet={wallet} disconnectWallet={disconnectWallet} />
                )}
            </header>

            {/* *********************************************************************************************** */}
            {/* **************************************** Connect Modal **************************************** */}
            {/* *********************************************************************************************** */}

            <Modal id="conn_modal" closeHold={true}>
                <div className="d-flex justify-content-end mt-3 mx-3">
                    <button
                        ref={closeConnectModalRef}
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div className={cx('modal-body', css.modalBodyContainer)}>
                    <div className="row m-3">
                        {/* PC 화면일 때 */}
                        {!mobile && (
                            <>
                                <ModalButton
                                    id="modalLoading"
                                    type="button"
                                    className={cx('col-md-12 btn py-2 mb-3 fw-bold', css.kaiasButton)}
                                    onClick={connectKaikasHandler}
                                >
                                    <img src={iconKaikas} width="25px" height="24px" /> {Lang.header_modal_conn_kaikas}
                                </ModalButton>

                                <hr className="mt-3" />
                            </>
                        )}
                        {/* 모바일 화면일 때 */}
                        <div className="col-12 p-0 mb-0 text-center">
                            <p className="pt-1 pb-3 text-black">{Lang.header_modal_conn_desciption}</p>

                            <button
                                onClick={connectKlipHandler}
                                type="button"
                                className={cx('col-12 fw-bold btn py-3 mb-2', css.klipButton)}
                            >
                                <img src={iconKlip} width="25px" /> {Lang.header_modal_conn_klip}
                            </button>
                            <a
                                className="col-12 py-2 fw-lighter"
                                href="https://www.kakaocorp.com/page/service/service/Klip"
                                target="_blank"
                            >
                                {Lang.header_modal_conn_klip_notice} <i className="fas fa-chevron-right" />
                            </a>
                        </div>
                    </div>
                </div>
            </Modal>

            {/* *********************************************************************************************** */}
            {/* **************************************** kaikas Loading Modal ********************************* */}
            {/* *********************************************************************************************** */}

            <Modal className="text-center" id="modalLoading" closeHold={true}>
                <div className="d-flex justify-content-end mt-3 mx-3">
                    <button
                        ref={closeLoadingModalRef}
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div className={cx('modal-body', css.modalBodyContainer)}>
                    <div className="row align-items-center">
                        <div className="col-12">
                            <img width={24} src={iconKaikas} /> {Lang.header_modal_loading_title}
                        </div>
                        <div className="col-12 mt-3">
                            <div className="spinner-border m-3" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

const MobileHeaderJSX = ({ wallet, disconnectWallet }) => {
    const addressStrRef = useRef(null);
    const history = useHistory();
    const Lang = useLanguages();

    /**
     * 지갑 주소 복사하기 핸들러
     */
    const copyToClipboardHandler = (data: string): void => {
        copyToClipboard(data);
        window.toast('info', Lang.inf_msg_copy_to_clipboard);
    };

    // mobile 은 search bar 클릭시 search 페이지 이동

    return (
        <nav className="navbar navbar-dark p-0 m-0">
            <div className="col-12 d-flex justify-content-between">
                <Link to={'/'}>
                    <img className="py-2" src={logo} width={128} />
                </Link>
                <div className="d-flex justify-content-end align-items-center">
                    <i
                        style={{ color: '#ffffff', fontSize: '14px', margin: '2px 0 0 0' }}
                        className="fas fa-search"
                        onClick={() => history.push('/SearchCollections')}
                    />
                    <BtnStrLanguages />

                    <div className="dropdown">
                        <button
                            className="border-0"
                            type="button"
                            id="mToggleBurger"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <ul
                            className={cx('dropdown-menu dropdown-menu-end', css.burgerDropMenu)}
                            aria-labelledby="mToggleBurger"
                        >
                            {wallet?.type === 'none' ? ( //지갑 연결 안되어 있을때.
                                <li
                                    className="dropdown-item bg-dark text-white justify-content-end"
                                    onClick={() => remoteModalOpener('conn_modal')}
                                >
                                    <button type="button" className="border-0 text-white">
                                        <i className="fas fa-wallet" />
                                        <b> {Lang.header_conn_wallet}</b>
                                    </button>
                                </li>
                            ) : (
                                <>
                                    <li className="dropdown-item bg-dark text-white justify-content-end">
                                        <div className="d-flex justify-content-between" role="group">
                                            {/**************** 지갑 주소 복사 ****************/}
                                            <button
                                                className="btn loginWalletBtn"
                                                onClick={() => copyToClipboardHandler(wallet.info.address)}
                                            >
                                                <i className="far fa-copy" />{' '}
                                                <span ref={addressStrRef}>{wallet.info.address.slice(0, 8)}... </span>
                                            </button>
                                            {/**************** 코인 갯수 ****************/}
                                            <button className="btn loginWalletBtn">
                                                {wallet.type === 'kaikas' && (
                                                    <>
                                                        <img src={iconKaikas} width="16px" height="16px" />{' '}
                                                        {wallet.info.balance}
                                                    </>
                                                )}
                                                {wallet.type === 'klip' && <img src={iconKlip_W} width="25px" />}
                                            </button>
                                        </div>
                                    </li>
                                    <Link className="dropdown-item text-white" to="/myPage">
                                        {/* 마이페이지 버튼 */}
                                        <i className={'fas fa-id-card'} /> {Lang.header_my_page}
                                    </Link>
                                </>
                            )}
                            <Link
                                className="dropdown-item text-white"
                                to="/"
                                onClick={() => {
                                    window.alert('Per PLUS 서비스가 종료되었습니다.');
                                }}
                            >
                                {/* 마이페이지 버튼 */}
                                {/*<i className="fas fa-question-circle" />*/}
                                <img src={perLogo_} alt="" width={18} height={18} style={{ marginRight: '4px' }} />
                                {Lang.header_menu5}
                            </Link>
                            <Link className="dropdown-item text-white" to="/collections">
                                {/* 마이페이지 버튼 */}
                                <i className={'fas fa-trophy'} /> {Lang.header_menu1}
                            </Link>
                            <Link className="dropdown-item text-white" to="/helpCenter">
                                {/* 마이페이지 버튼 */}
                                <i className={'fas fa-question-circle'} /> {Lang.header_menu3}
                            </Link>
                            {/*<button*/}
                            {/*    className="dropdown-item text-white"*/}
                            {/*    onClick={() => remoteModalOpener('myProfile')}*/}
                            {/*>*/}
                            {/*    <i className={'far fa-id-badge'} /> {Lang.header_menu4}*/}
                            {/*</button>*/}
                            {wallet?.type !== 'none' && (
                                <button className="dropdown-item text-white" onClick={disconnectWallet}>
                                    {/* 지갑 연결 해제 (Store 초기화) */}
                                    <i className={'fas fa-unlock-alt'} /> {Lang.header_disconn_wallet}
                                </button>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    );
};

const PcHeaderJSX = ({ wallet, disconnectWallet }) => {
    const { list } = useSelector((store: RootState) => store.Collections);
    const history = useHistory();
    const Lang = useLanguages();

    /**
     * searchHandler
     */
    const [searchFocusIs, setSearchFocusIs] = useState(false);
    const [searchResult, setSearchResult] = useState([]);
    const [selectCollectionsIndex, setSelectCollectionsIndex] = useState(0);
    const searchInputRef = useRef(null);

    const searchSubmitHandler = (collection?) => {
        if (searchInputRef.current !== null) {
            searchInputRef.current.blur();
            searchInputRef.current.value = '';
            setSearchFocusIs(false);
        }
        if (collection) history.push(`/collections/${collection.contract_name}/${collection.brand_name}`);
    };

    const searchOnChangeHandler = useCallback(
        (e: any) => {
            if (e.target.value === '') {
                return setSearchResult([]);
            } else if (e.keyCode === 38) {
                // up btn
                setSelectCollectionsIndex(() =>
                    selectCollectionsIndex === 1 ? searchResult.length : selectCollectionsIndex - 1,
                );
            } else if (e.keyCode === 40) {
                // down btn
                setSelectCollectionsIndex(() =>
                    selectCollectionsIndex === searchResult.length ? 1 : selectCollectionsIndex + 1,
                );
            } else if (e.keyCode === 13) {
                // enter
                const currentItem = searchResult[selectCollectionsIndex - 1];
                if (currentItem) searchSubmitHandler(currentItem);
            } else {
                const filterList = list
                    .filter(
                        (item) => item.contract_view_name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1,
                    )
                    .sort(
                        (a, b) =>
                            a.contract_view_name.toLowerCase().indexOf(e.target.value.toLowerCase()) -
                            b.contract_view_name.toLowerCase().indexOf(e.target.value.toLowerCase()),
                    )
                    .slice(0, 5);

                setSearchResult(filterList);
                setSelectCollectionsIndex(0);
            }
        },
        [searchResult, list, selectCollectionsIndex],
    );

    return (
        <div className="row d-flex justify-content-start m-0">
            {/* ********************************* 메인 로고 ********************************* */}

            <div className="col-12 col-md-2 d-flex justify-content-between">
                <Link to={'/'}>
                    <img className="py-2" src={logo} />
                </Link>
            </div>

            <div
                className={cx(
                    'col-12 col-md-4 d-flex align-items-center justify-content-between',
                    css.headerSearchContainer,
                    searchFocusIs ? css.onFocusContainer : undefined,
                )}
            >
                <i
                    className="fas fa-search"
                    onClick={() => {
                        if (searchInputRef.current !== null) searchInputRef.current.focus();
                    }}
                />
                {searchFocusIs && (
                    <svg
                        className={css.exitImg}
                        width="13"
                        height="12"
                        viewBox="0 0 13 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onMouseDown={() => searchSubmitHandler()}
                    >
                        <path d="M1.5 11L11.5 0.999999" stroke="#6F6F6F" strokeWidth="2" strokeLinecap="round" />
                        <path d="M1.5 1L11.5 11" stroke="#6F6F6F" strokeWidth="2" strokeLinecap="round" />
                    </svg>
                )}

                <input
                    type="text"
                    placeholder="Search Collections"
                    onFocus={() => setSearchFocusIs(true)}
                    onBlur={() => {
                        setSearchFocusIs(false);
                        setSelectCollectionsIndex(0);
                    }}
                    ref={searchInputRef}
                    onKeyUp={searchOnChangeHandler}
                />
                {searchFocusIs && searchInputRef?.current.value !== '' && searchInputRef?.current.value !== null && (
                    <div className={css.HeaderInputResultContainer}>
                        {searchResult.length ? (
                            <div className={css.searchCollectionHeader}>
                                <span>collection</span>
                            </div>
                        ) : null}

                        {searchResult.map((item, index) => {
                            return (
                                <div
                                    className={cx(
                                        css.searchCollectionResult,
                                        index === selectCollectionsIndex - 1 ? css.selectCollectionHover : undefined,
                                    )}
                                    onMouseDown={() => searchSubmitHandler(item)}
                                    key={item.id}
                                >
                                    <div className={css.searchCollectionItem}>
                                        <img src={window.envBackImageHost + item.brand_img_src} alt="" />
                                        <span>{item.contract_view_name}</span>
                                    </div>
                                    <div className={css.searchCollectionItemNumber}>
                                        <p>{item.sttc_crnt_nft}</p>
                                        <p>items</p>
                                    </div>
                                </div>
                            );
                        })}
                        <div className={css.searchCollectionBottom}>
                            <span>
                                {searchResult.length ? 'Press Enter to search all items' : 'No collections found'}
                            </span>
                        </div>
                    </div>
                )}
            </div>
            {/* ********************************* 메인 메뉴 라인 ********************************* */}

            <div className="col-12 col-md-4 d-flex align-items-center justify-content-between">
                <Link className="link-secondary" to="#"></Link>

                <Link className={cx('p-2 link-secondary rounded', css.headerMainMenus)} to="/collections">
                    <i className="fas fa-trophy" /> {Lang.header_menu1}
                </Link>

                {/* <Link className={cx('p-2 link-secondary rounded', css.headerMainMenus)} to="#">
                            <i className="fas fa-align-justify" /> {Lang.header_menu2}
                            </Link> */}

                <Link className={cx('p-2 link-secondary rounded', css.headerMainMenus)} to="/helpCenter">
                    <i className="fas fa-question-circle" style={{ fontSize: '18px' }} /> {Lang.header_menu3}
                </Link>

                {/* ********************************* 임시 PerPlus menu ********************************* */}
                <Link
                    className={cx('p-2 link-secondary rounded', css.headerMainMenus, css.dFlexHeader)}
                    to="/"
                    onClick={() => {
                        window.alert('Per PLUS 서비스가 종료되었습니다.');
                    }}
                >
                    {/*<i className="fas fa-question-circle" />*/}
                    <img src={perLogo_} alt="" width={18} height={18} />
                    {Lang.header_menu5}
                </Link>

                {wallet?.type !== 'none' && (
                    <Link className={cx('p-2 link-secondary rounded', css.headerMainMenus)} to="/myPage">
                        {/* 마이페이지 버튼 */}
                        <i className={'fas fa-id-card'} /> {Lang.header_my_page}
                    </Link>
                )}
            </div>

            {/* ********************************* 지갑 연결 & 마이 메뉴 ********************************* */}

            <div className="col-12 col-md-2 d-flex justify-content-end m-0 p-0">
                {wallet?.type === 'none' ? ( //case by Wallet Type
                    // kaikas에 로그인
                    <ModalButton
                        className={cx('btn btn-outline-secondary me-2', css.headerModalButton)}
                        id="conn_modal"
                    >
                        <i className="fas fa-wallet" />
                        <b> {Lang.header_conn_wallet}</b>
                    </ModalButton>
                ) : (
                    <Profile logout={disconnectWallet}></Profile>
                )}
                <BtnStrLanguages />
            </div>
        </div>
    );
};

export default Header;
