import React, { useEffect } from 'react';

const TormOfUse_KO: React.FC<any> = (): JSX.Element => {
    useEffect(() => {}, []);

    return (
        <section className="section mt-3">
            <div className="container">
                <div className="row">
                    <div id="koprimary" className="content-area col-md-9 mx-auto">
                        <div id="content" className="site-content" role="main">
                            <article id="post-108" className="post-108 page type-page status-publish hentry">
                                <header className="entry-header text-center py-3">
                                    <h2 className="entry-title text-black">服務使用條款</h2>
                                </header>

                                <div className="entry-content">
                                    <div className="px-3 mb-4">
                                        <small>
                                            <p className="py-1 text-secondary">
                                                在使用由 Klaymint 運營的服務和網站{' '}
                                                <a href={'https://www.klaymint.io'}>https://www.klaymint.io</a>
                                                之前，請仔細閱讀使用條款.
                                            </p>
                                            <p className="py-1 text-secondary">
                                                服務的使用以及訪問必須遵守並同意以下的使用條款.
                                            </p>
                                            <p className="py-1 text-secondary">
                                                此條款適用於所有使用這項服務的藝術家，用戶等
                                            </p>
                                            <p className="py-1 text-secondary">
                                                通過使用我們的服務，您接受以下的使用條款.
                                                若不同意部分條款，將無法繼續此服務.
                                            </p>
                                        </small>
                                    </div>
                                    <ol>
                                        <li className="mb-3">
                                            <strong>
                                                <i className="fas fa-info-circle" /> 專門為全球的NFT藝術家打造
                                            </strong>
                                            <ol>
                                                <li>
                                                    Klaymint是NFT發布者(稱為作者)和 NFT購買者(稱為用戶)的NFT中介和平臺.
                                                </li>
                                            </ol>
                                        </li>

                                        <li className="mb-3">
                                            <strong>
                                                <i className="fas fa-info-circle" /> 提供特別活動功能
                                            </strong>
                                            <ol>
                                                <li>
                                                    Klaymint提供多樣化的活動. 例如：公開NFT作品,交易功能,NFT空投等
                                                </li>
                                            </ol>
                                        </li>

                                        <li className="mb-3">
                                            <strong>
                                                <i className="fas fa-info-circle" /> 為藝術家和用戶提供便利性
                                            </strong>
                                            <ol>
                                                <li>
                                                    Klaymint 允許每位藝術家自由創建和公開自己的NFT.對於用戶,它提供各種不同的NFT.
                                                </li>
                                            </ol>
                                        </li>

                                        <li className="mb-3">
                                            <strong>
                                                <i className="fas fa-info-circle" /> 鑄幣和交易的要點
                                            </strong>
                                            <ol>
                                                <li>
                                                    Klaymint對藝術家的驗證不承擔任何法律責任，用戶在鑄造和交易NFT前必須充分考慮這一點.
                                                </li>
                                            </ol>
                                        </li>

                                        <li className="mb-3">
                                            <strong>
                                                <i className="fas fa-info-circle" /> 為特定活動采取行動.
                                            </strong>
                                            <ol>
                                                <li>
                                                    Klaymint在使用平臺時允許最大程度的獨立性，
                                                    但在不可避免或特殊情況下，
                                                    它可能會對藝術家和用戶的行為采取特定的介入/製裁措施
                                                </li>
                                            </ol>
                                        </li>

                                        <li className="mb-3">
                                            <strong>
                                                <i className="fas fa-info-circle" /> 違反規則的後果
                                            </strong>
                                            <ol>
                                                <li>
                                                    當公開NFT時，
                                                    所有作者需負上以下的責任.若有違規者,平臺的使用可能會遭受限製.
                                                </li>
                                                <li>
                                                    <br />
                                                </li>
                                                <li>1. 若明確侵犯他人的著作權</li>
                                                <li>2. 若發布色情內容</li>
                                                <li>3. 若嚴重侵犯他人的肖像權以及基本權力</li>
                                                <li>4. 若造成嚴重的社會問題</li>
                                            </ol>
                                        </li>

                                        <li className="mb-3">
                                            <strong>
                                                <i className="fas fa-info-circle" /> 所有權類型的定義
                                            </strong>
                                            <ol>
                                                <li>
                                                    通過歸屬於虛擬資產錢包，用戶擁有公開NFT作品的所有權，
                                                    但這並不意味著擁有整個NFT 系列和作者作品的版權.
                                                    可以收藏,出售和營利您所擁有的NFT作品. 同時，
                                                    如果您使用整個NFT系列的版權來從事冒充廣告等商業活動，
                                                    很可能會出現和作者的版權問題.
                                                </li>
                                            </ol>
                                        </li>

                                        <li className="mb-3">
                                            <strong>
                                                <i className="fas fa-info-circle" /> 服務使用的法律責任
                                            </strong>
                                            <ol>
                                                <li>
                                                    在擁有NFT作品的情況下，Klaymint不提供或承諾贈送的空投,NFT遊戲和元界(稱為二級應用程序)的使用承擔法律責任.
                                                </li>
                                            </ol>
                                        </li>

                                        <li className="mb-3">
                                            <strong>
                                                <i className="fas fa-info-circle" /> NFT作品介紹的法律責任
                                            </strong>
                                            <ol>
                                                <li>
                                                    Klaymint上的作品介紹是由作品註冊人登錄，Klaymint不對相關內容負責.
                                                    (雖然在PHASE.1通過申請表有一個基本的驗證流程,但這與Klaymint的法律責任無關)
                                                </li>
                                            </ol>
                                        </li>

                                        <li className="mb-3">
                                            <strong>
                                                <i className="fas fa-info-circle" /> Klaymint的持續更新
                                            </strong>
                                            <ol>
                                                <li>
                                                    Klaymint會通過PHASE.2持續對服務進行更新以擴大獨立的NFT交易平臺.
                                                </li>
                                            </ol>
                                        </li>

                                        <li className="mb-3">
                                            <strong>
                                                <i className="fas fa-info-circle" /> 服務條款的法律效力
                                            </strong>
                                            <ol>
                                                <li>
                                                    本使用條款在發生爭議及法律辯解時具有與NFT Market Platform條款的正常效力
                                                </li>
                                            </ol>
                                        </li>

                                        <li className="mb-3">
                                            <strong className="d-flex text-center">
                                                <h6 style={{ color: 'red' }}>
                                                    <i className="fas fa-exclamation-triangle" /> 註意事項
                                                </h6>
                                            </strong>
                                            <ol>
                                                <li>
                                                    Klaymint對發行量超過提交給Klaymint的設定值或未履行其它承諾的項目不承擔驗證，履行或補償的責任
                                                    <br />
                                                    <br />
                                                    然而,與項目方的溝通不當,掛牌,不履行承諾等其它不當的的行為,
                                                    我們將給予警告.若遇到任何不當的行為,該項目可能會被排除在
                                                    Klaymint的列表和各種支援之外.
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default TormOfUse_KO;
