import React from 'react';
import cx from 'classnames';
import css from './notFound.module.scss';

import { useLanguages } from '@/hooks/useLanguages.hook';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux/connectors.redux';
import { useHistory } from 'react-router';

const NotFound = () => {
    const Lang = useLanguages();
    const { mobile } = useSelector((store: RootState) => store.GlobalStatus);
    const histroy = useHistory();

    return (
        <main
            className={cx(
                'container-fluid min-vh-100 d-flex justify-content-center',
                mobile ? css.backgroundImg_mobile : css.backgroundImg,
            )}
        >
            <div className={cx('align-self-center text-light', mobile ? 'm-0' : 'm-5 p-5')}>
                <h1 className="text-center">
                    <b>{Lang.not_found_404_title}</b>
                </h1>
                <hr />

                <p>{Lang.not_found_404_subscript}</p>
                <br />
                <span className={'fw-lighter'}>
                    {Lang.not_found_404_description1}
                    <br />
                    {Lang.not_found_404_description2}
                </span>
                <hr />
                <button
                    className={'btn btn-light'}
                    type="button"
                    onClick={() => {
                        histroy.push('/');
                    }}
                >
                    {Lang.not_found_404_button_text}
                </button>
            </div>
        </main>
    );
};

export default NotFound;
