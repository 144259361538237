import Caver from 'caver-js';
import { envEnNode } from '@/includes/envVariables';

declare global {
    interface Window {
        klaytn?: any;
        caver?: any;
    }
}

window.caver = new Caver(window.klaytn ? window.klaytn : envEnNode);
