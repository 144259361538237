import React, { useEffect } from 'react';

const TormOfUse_KO: React.FC<any> = (): JSX.Element => {
    useEffect(() => {}, []);

    return (
        <section className="section mt-3">
            <div className="container">
                <div className="row">
                    <div id="koprimary" className="content-area col-md-9 mx-auto">
                        <div id="content" className="site-content" role="main">
                            <article id="post-108" className="post-108 page type-page status-publish hentry">
                                <header className="entry-header text-center py-3">
                                    <h2 className="entry-title text-black">服务使用条款</h2>
                                </header>

                                <div className="entry-content">
                                    <div className="px-3 mb-4">
                                        <small>
                                            <p className="py-1 text-secondary">
                                                在使用由 Klaymint 运营的服务和网站{' '}
                                                <a href={'https://www.klaymint.io'}>https://www.klaymint.io</a>를
                                                之前，请仔细阅读使用条款.
                                            </p>
                                            <p className="py-1 text-secondary">
                                                服务的使用以及访问必须遵守并同意以下的使用条款.
                                            </p>
                                            <p className="py-1 text-secondary">
                                                此条款适用于所有使用这项服务的艺术家，用户等
                                            </p>
                                            <p className="py-1 text-secondary">
                                                通过使用我们的服务，您接受以下的使用条款.
                                                若不同意部分条款，将无法继续此服务.
                                            </p>
                                        </small>
                                    </div>
                                    <ol>
                                        <li className="mb-3">
                                            <strong>
                                                <i className="fas fa-info-circle" /> 专门为全球的NFT艺术家打造
                                            </strong>
                                            <ol>
                                                <li>
                                                    Klaymint是NFT发布者(称为作者)和 NFT购买者(称为用户)的NFT中介和平台.
                                                </li>
                                            </ol>
                                        </li>

                                        <li className="mb-3">
                                            <strong>
                                                <i className="fas fa-info-circle" /> 提供特别活动功能
                                            </strong>
                                            <ol>
                                                <li>
                                                    Klaymint提供多样化的活动.例如：公开NFT作品,交易功能,NFT空投等.
                                                </li>
                                            </ol>
                                        </li>

                                        <li className="mb-3">
                                            <strong>
                                                <i className="fas fa-info-circle" /> 为艺术家和用户提供便利性
                                            </strong>
                                            <ol>
                                                <li>
                                                    Klaymint 允许每位艺术家自由创建和公开自己的NFT.对于用户,它提供各种不同的NFT.
                                                </li>
                                            </ol>
                                        </li>

                                        <li className="mb-3">
                                            <strong>
                                                <i className="fas fa-info-circle" /> 铸币和交易的要点
                                            </strong>
                                            <ol>
                                                <li>
                                                    Klaymint对艺术家的验证不承担任何法律责任，用户在铸造和交易NFT前必须充分考虑这一点.
                                                </li>
                                            </ol>
                                        </li>

                                        <li className="mb-3">
                                            <strong>
                                                <i className="fas fa-info-circle" /> 为特定活动采取行动.
                                            </strong>
                                            <ol>
                                                <li>
                                                    Klaymint在使用平台时允许最大程度的独立性，但在不可避免或特殊情况下，它可能会对艺术家和用户的行为采取特定的介入/制裁措施.
                                                </li>
                                            </ol>
                                        </li>

                                        <li className="mb-3">
                                            <strong>
                                                <i className="fas fa-info-circle" /> 违反规则的后果
                                            </strong>
                                            <ol>
                                                <li>
                                                    当公开NFT时，所有作者需对以下的违规事项负起责任.若有违规者,平台的使用可能会遭受限制.
                                                </li>
                                                <li>
                                                    <br />
                                                </li>
                                                <li>1. 若明确侵犯他人的著作权</li>
                                                <li>2. 若发布色情内容</li>
                                                <li>3. 若严重侵犯他人的肖像权以及基本权力</li>
                                                <li>4. 若造成严重的社会问题</li>
                                            </ol>
                                        </li>

                                        <li className="mb-3">
                                            <strong>
                                                <i className="fas fa-info-circle" /> 所有权类型的定义
                                            </strong>
                                            <ol>
                                                <li>
                                                通过归属于虚拟资产钱包，用户拥有公开NFT作品的所有权，
                                                但这并不意味着拥有整个NFT 系列和作者作品的版权.
                                                可以收藏,出售和营利您所拥有的NFT作品. 同时，
                                                如果您使用整个NFT系列的版权来从事冒充广告等商业活动，
                                                很可能会出现和作者的版权问题.
                                                </li>
                                            </ol>
                                        </li>

                                        <li className="mb-3">
                                            <strong>
                                                <i className="fas fa-info-circle" /> 服务使用的法律责任
                                            </strong>
                                            <ol>
                                                <li>
                                                    在拥有NFT作品的情况下，Klaymint不会承担承诺赠送的空投,NFT游戏和元界(称为二级应用程序)的使用的法律责任.
                                                </li>
                                            </ol>
                                        </li>

                                        <li className="mb-3">
                                            <strong>
                                                <i className="fas fa-info-circle" /> NFT作品介绍的法律责任
                                            </strong>
                                            <ol>
                                                <li>
                                                    Klaymint上的作品介绍是由作品注册人登录，
                                                    Klaymint不对相关内容负责.
                                                    (虽然在PHASE.1通过申请表有一个基本的验证流程,
                                                    但这与Klaymint的法律责任无关)
                                                </li>
                                            </ol>
                                        </li>

                                        <li className="mb-3">
                                            <strong>
                                                <i className="fas fa-info-circle" /> Klaymint的持续更新
                                            </strong>
                                            <ol>
                                                <li>
                                                    Klaymint会通过PHASE.
                                                    2持续对服务进行更新以扩大独立的NFT交易平台.
                                                </li>
                                            </ol>
                                        </li>

                                        <li className="mb-3">
                                            <strong>
                                                <i className="fas fa-info-circle" /> 服务条款的法律效力
                                            </strong>
                                            <ol>
                                                <li>
                                                    本使用条款在发生争议及法律辩解时具有与NFT Market Platform条款的正常效力.
                                                </li>
                                            </ol>
                                        </li>

                                        <li className="mb-3">
                                            <strong className="d-flex text-center">
                                                <h6 style={{ color: 'red' }}>
                                                    <i className="fas fa-exclamation-triangle" /> 注意事项
                                                </h6>
                                            </strong>
                                            <ol>
                                                <li>
                                                    Klaymint对发行量超过提交给Klaymint的设定值或未履行其它承诺的项目不承担验证，
                                                    履行或补偿的责任
                                                    <br />
                                                    <br />
                                                    然而,与项目方的沟通不当,挂牌,不履行承诺等其它不当的的行为,
                                                    我们将给予警告.若遇到任何不当的行为,该项目可能会被排除在
                                                    Klaymint的列表和各种支援之外.
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default TormOfUse_KO;
