import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import _ from 'lodash';

import { useSelector } from 'react-redux';
import { RootState } from '@/redux/connectors.redux';

import cx from 'classnames';
import css from '@/pages/CollectionsDetail/detailGlobal.module.scss';
import ProfileImage from '@/pages/CollectionsDetail/components/ProfileImage';
import ButtonsInfo from '@/pages/CollectionsDetail/components/Buttons';
import Search from '@/pages/CollectionsDetail/components/Search';
import Article from '@/pages/CollectionsDetail/components/Article';
import Loader from '@/_components/commons/loaders/loader';
import KlayMint from '@/helpers/KlayMint';
import { capitalize } from '@/helpers/_common';

const CollectionsDetail = ({ match }) => {
    // props.location 값에 history.push 로 넘어온 요소 존재
    const history = useHistory();
    const [collection, setCollection] = useState(null);

    const _contractName = history.location.pathname.split('/')[2];
    const _brandName = history.location.pathname.split('/')[3];
    let contractName = match.params.contractName ? match.params.contractName : _contractName;
    let brandName = match.params.brandName ? match.params.brandName : _brandName;
    //주소창으로부터 컨트랙트이름과 브랜드이름 추출

    const { list, classs, sns } = useSelector((store: RootState) => store.Collections);

    const [search, setSearch] = useState({
        max_price: '99999999.99',
        min_price: '0.01',
        select_class: 'Rare',
        select_group: 'sales_price',
        select_order: 'ASC',
        token_id: '',
        contract_id: 0,
        token_id_token_name: '',
    });

    const [originList, setOriginList] = useState([]);
    const [sortList, setSortList] = useState([]);

    // 실제로 backend 의 factoryList 에서 사라지기 전 미리 delete 시켜서 사용자 경험증가
    const optimisticDelete = (item) => {
        const prevOriginList = [...originList];
        const nextOriginList = prevOriginList.filter((v) => v.data.token_id !== item.data.token_id);
        setOriginList(nextOriginList);

        const prevSortList = [...sortList];
        const nextSortList = prevSortList.filter((v) => v.data.token_id !== item.data.token_id);
        setSortList(nextSortList);
    };

    useEffect(() => {
        if (list.length <= 0 && originList.length <= 0) return;

        const _collection = _.find(list, (el, i) => el.brand_name === brandName && el.contract_name === contractName);
        _collection['class'] = classs[_collection.id];
        _collection['sns'] = sns[_collection.id];

        setSearch({ ...search, contract_id: _collection.id });

        const klaymint = new KlayMint(_collection.contract_address, _collection.factory_address, list);
        klaymint.getSalesList().then((result) => {
            // if (result.length) {
            const initList = result.sort((a, b) => a.data.sales_price - b.data.sales_price);
            setSortList(initList);
            setOriginList(initList);

            for (let i = 0; i < initList.length; i++) {
                if (initList[i].data?.class) {
                    if (_collection.class[initList[i].data?.class?.toLowerCase()]?.floor_price) {
                        if (
                            +_collection.class[initList[i].data.class.toLowerCase()].floor_price >
                            initList[i].data.sales_price
                        ) {
                            _collection.class[initList[i].data.class.toLowerCase()].floor_price =
                                initList[i]?.data?.sales_price?.toFixed(2);
                        }
                    } else {
                        _collection.class[initList[i].data?.class?.toLowerCase()].floor_price =
                            initList[i]?.data?.sales_price?.toFixed(2);
                    }
                } else {
                    if (
                        +_collection.class['none'].floor_price > initList[i].data.sales_price ||
                        _collection.class['none'].floor_price === null ||
                        _collection.class['none'].floor_price === undefined
                    ) {
                        _collection.class['none'].floor_price = initList[i].data?.sales_price?.toFixed(2);
                    }
                }
            }

            setCollection(_collection);
            // } else {
            //     setCollection(_collection);
            // }
        });
    }, [list, contractName, brandName]);

    /************************************************************
     * 검색어 변경에 의한 데이터 재요청
     ************************************************************/
    useEffect(() => {
        if (list.length <= 0 && originList.length <= 0) return;

        const originArr = [...originList];

        let filterArr = [];

        if (search.token_id_token_name !== '') {
            for (let i = 0; i < originArr.length; i++) {
                if (
                    (originArr[i].data.token_id + '').indexOf(search.token_id_token_name) !== -1 ||
                    originArr[i].data.name.toLowerCase().indexOf(search.token_id_token_name.toLowerCase()) !== -1
                )
                    filterArr.push(originArr[i]);
            }
        } else filterArr = originArr;

        let groupArr = [];

        if (search.select_class !== 'all') {
            for (let i = 0; i < filterArr.length; i++) {
                if (filterArr[i]?.data?.class && filterArr[i]?.data?.class === capitalize(search.select_class))
                    groupArr.push(filterArr[i]);
            }
        } else groupArr = filterArr;

        let priceArr = [];

        if (search.max_price !== '99999999.99' || search.min_price !== '0.01') {
            for (let i = 0; i < groupArr.length; i++) {
                if (
                    +search.min_price <= groupArr[i].data.sales_price &&
                    groupArr[i].data.sales_price <= +search.max_price
                ) {
                    priceArr.push(groupArr[i]);
                }
            }
        } else priceArr = groupArr;

        if (search.select_group === 'sales_price') {
            priceArr.sort((a, b) => a.data.sales_price - b.data.sales_price);
        } else if (search.select_group === 'id') {
            priceArr.sort((a, b) => a.data.id - b.data.id);
        } else if (search.select_group === 'token_id') {
            priceArr.sort((a, b) => a.data.token_id - b.data.token_id);
        } else if (search.select_group === 'last_price') {
            priceArr.sort((a, b) => a.data.last_price - b.data.last_price);
        }

        if (search.select_order !== 'ASC') {
            return setSortList(priceArr.reverse());
        } else {
            return setSortList(priceArr);
        }
    }, [search]);

    useEffect(() => {
        contractName = match.params.contractName ? match.params.contractName : _contractName;
        brandName = match.params.brandName ? match.params.brandName : _brandName;
    }, [window.location.href]);

    return (
        <main>
            {!collection ? (
                <div className="pt-5">
                    <Loader className="text-light" />
                </div>
            ) : (
                <>
                    <div className={cx('row col-12 pt-5', css.imageSectionDiv)}>
                        <ProfileImage
                            src={window.envBackImageHost + collection.brand_img_src}
                            name={collection.contract_view_name}
                            collection={collection}
                        />
                    </div>
                    <div className={cx('row', css.buttonSectionDiv)}>
                        <div className="col-md-6 col-sm-12">
                            <ButtonsInfo contractInfo={collection} />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Search onSearch={setSearch} search={search} classs={classs[collection.id]} />
                        </div>
                    </div>
                    <hr />

                    <Article
                        search={search}
                        collection={collection}
                        originList={sortList}
                        optimisticDelete={optimisticDelete}
                    />
                </>
            )}
        </main>
    );
};

export default CollectionsDetail;
