import { completeTransfer, getIsStakeAvailable, getKlayFromAddress } from '@/helpers/klaymint.api';
import { setWallet } from '@/redux/reducers/Wallet.reducer';
import { prepare, getResult } from 'klip-sdk';
import KlayMint from '@/helpers/KlayMint';
import { remoteWizardOpener } from '@/_components/commons/wizard';
import { dbContractABI, perTokenAbi } from '@/includes/abi';
import { numberWithCommas } from '@/helpers/common.helper';
import { updateTokens } from '@/redux/reducers/PerPlus.reducer';
import PerPlus from '@/helpers/PerPlus';
import Caver from 'caver-js';
import { envDBContractAddress, perAddress, viewPowerAddress } from '@/includes/envVariables';

/**
 * 비동기 요청을 모두 promise 객체로 만든 뒤 promise all 로 한번에 실행, ** 순서 보장x **
 * @param dna
 * @param callback
 */
export const setPromiseAll = async (dna, callback) => {
    const promises = dna.map(callback);
    await Promise.all(promises);
};

/**
 *  peb단위를 Klay 단위로 convert
 * @param caver
 * @param price
 */
const toPebFromKlay = (caver, price): string => {
    return caver.utils.convertToPeb(price + '', 'KLAY');
};

/**
 * klay 단뤼를 peb단위로 convert
 * @param caver
 * @param peb
 */
const toKlayFromPeb = (caver, peb): number => {
    return +caver.utils.fromPeb(peb, 'KLAY');
};
/**
 * str 문자열 0번째를 대문자로 변환하고 str 1번째부터 문자열을 더해서 반환
 * @param str
 */
export const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};
/**
 * address가 없을땐 0을 반환 / per Token의 balance를 불러와 peb단위의 balance를 klay로 변환
 * @param caver
 * @param address
 */
export const loadPerBalance = async (caver: Caver, address: string): Promise<string> => {
    if (!address) return '0';
    const PER = new caver.contract(perTokenAbi, perAddress);
    const perBalance = await PER.methods.balanceOf(address).call();
    const per = toKlayFromPeb(caver, perBalance);
    // per toFixed함수로 인해 소수점 반올림 된 balance를 불러온다.
    // 이로인해 스왑 시 보유 per의 오해로 인해 거래가 실패 할 수 있는 상황을 피하기 위해 소수점 5번째 toFixed (string) 을 소수점 2번째에서 slice
    const _per = per.toFixed(5).slice(0, -3);
    const res = numberWithCommas(+_per, '');

    return res;
};
/**
 * address가 없을땐 0을 반환 / getKlayFormAddress로 백엔드로 axios 요청
 * @param caver
 * @param address
 */
export const loadKlayBalance = async (caver: Caver, address: string): Promise<string> => {
    if (!address) return '0';
    const klayBalance = await getKlayFromAddress(address);
    // klay toFixed함수로 인해 소수점 반올림 된 balance를 불러온다.
    // 이로인해 스왑 시 보유 klay의 오해로 인해 거래가 실패 할 수 있는 상황을 피하기 위해 소수점 5번째 toFixed (string) 을 소수점 2번째에서 slice
    const _klay = (+klayBalance.data).toFixed(5).slice(0, -3);
    // const _leng = _klay.length - 16; // 소수점 2째자리 까지 나오게
    // const balance = +_klay.substring(0, _leng) / 100;
    const res = numberWithCommas(+_klay, '');
    if (!res) return '0';

    return res;
};
/**
 * [더 봐야함] inventory 및 tutorial에서 인벤토리 칸을 뿌려주기위해 받는 배열을 나누는 곳
 * @param arr
 * @param n
 */
export const division = (arr: any[], n: number) => {
    const temp = [...arr];
    const len = arr.length;
    const cnt = Math.floor(len / n) + (Math.floor(len % n) > 0 ? 1 : 0);
    const result = [];

    for (let i = 0; i < cnt; i++) {
        result.push(temp.splice(0, n));
    }

    return result;
};
/**
 * atrr를 받아서 case별로 background 색 지정
 * @param attr
 * @param background
 */
export const getBackgroundColor = (attr, background?) => {
    const style = {
        color: undefined,
        border: undefined,
    };

    switch (attr) {
        case 'Normal':
            if (background) style.border = '1.5px solid #c6c6c6';
            else style.color = '#c6c6c6';
            break;
        case 'Uncommon':
            if (background) style.border = '1.5px solid #f4f4f4';
            else style.color = '#f4f4f4';
            break;
        case 'Rare':
            if (background) style.border = '1.5px solid #27e1d5';
            else style.color = '#27e1d5';
            break;
        case 'Unique':
            if (background) style.border = '1.5px solid #ff0ff5';
            else style.color = '#ff0ff5';
            break;
        case 'Legendary':
            if (background) style.border = '1.5px solid #f6c019';
            else style.color = '#f6c019';
            break;
        case 'Myth':
            if (background) style.border = '1.5px solid #f72919';
            else style.color = '#f72919';
            break;
        case 'Ancient':
            if (background) style.border = '1.5px solid #2ad1ff';
            else style.color = '#2ad1ff';
            break;
        default:
            style.color = undefined;
            break;
    }

    return style;
};

/**
 * mobile deepLink 로 연결됬을 때 실행되는 polling interval 함수
 * @param wallet
 * @param res
 * @param Lang
 * @param dispatch
 * @param closeModal
 * @param contractInfo
 * @param list
 * @param sucCallback
 */
export const makeMobilePolling = (wallet, res, Lang, dispatch, closeModal, contractInfo, list, sucCallback?) => {
    const mobilePolling = setInterval(async () => {
        try {
            const data = await getResult(res.request_key);

            if (data.status === 'completed') {
                const balance = await getKlayFromAddress(wallet.info.address);

                if (res.err) {
                    console.log(res.err);

                    clearInterval(mobilePolling);

                    remoteWizardOpener('trade_chk_stepwizard');
                    closeModal ? closeModal(false) : null;
                    window?.toast('success', Lang.err);
                }

                if (data.result) {
                    const klaymint = new KlayMint(contractInfo.contract_address, contractInfo.factory_address, list);
                    klaymint.getToken(wallet, list).then(async (tokens) => {
                        await dispatch(
                            setWallet({
                                isConn: true,
                                type: 'klip',
                                info: {
                                    ...wallet.info,
                                    address: wallet.info.address,
                                    balance: balance.data,
                                    myToken: {
                                        ...wallet.info.myToken,
                                        unlisted: tokens.unlisted,
                                        onSale: tokens.onSale,
                                    },
                                },
                            }),
                        );

                        sucCallback();
                        /* 유효 기간 */
                        clearInterval(mobilePolling);
                        if (res.type === 'transfer') {
                            completeTransfer(
                                res.toAddress,
                                res.fromAddress,
                                res.ctl_idx,
                                res.tokenId,
                                data.result.tx_hash,
                            );
                        }

                        closeModal ? closeModal(false) : null;
                        window?.toast('success', Lang.suc);
                    });
                }
            }
        } catch (e) {
            console.error(e);
            window?.toast('error', Lang.err);
        } finally {
        }
    }, 1000);
};

/**
 * mobile deepLink 로 연결됬을 때 실행되는 approved polling interval 함수
 * @param wallet
 * @param res
 * @param Lang
 * @param dispatch
 * @param executeData
 * @param closeModal
 * @param contractInfo
 * @param list
 */
export const makeMobileApprovedPolling = (wallet, res, Lang, dispatch, executeData, closeModal, contractInfo, list) => {
    const apporvedPolling = setInterval(async () => {
        try {
            const data = await getResult(res.request_key);

            if (data.status === 'completed') {
                if (res.err) {
                    console.log('sell error');
                    console.log(res.err);

                    clearInterval(apporvedPolling);

                    remoteWizardOpener('trade_chk_stepwizard');
                    closeModal ? closeModal(false) : null;
                    window?.toast('success', Lang.err_msg_sucs_sell_approved);
                }

                if (data.result) {
                    console.log(executeData);
                    console.log('마지막 sell check 하는곳');
                    window?.toast('success', Lang.suc_msg_sucs_sell_approved);
                    clearInterval(apporvedPolling);

                    const sellRes = await prepare.executeContract({
                        ...executeData,
                    });

                    makeMobilePolling(
                        wallet,
                        sellRes,
                        {
                            err: Lang.err_msg_sucs_sell,
                            suc: Lang.suc_msg_sucs_sell_klip,
                        },
                        dispatch,
                        closeModal,
                        contractInfo,
                        list,
                    );
                }
            }
        } catch (e) {
            console.error(e);
            window?.toast('error', Lang.err_msg_sucs_sell_approved);
        } finally {
        }
    }, 1000);
};

// export const perPlusKlipPolling = (wallet, res, Lang, dispatch, callback, contractList) => {
//     const mobilePolling = setInterval(async () => {
//         try {
//             const data = await getResult(res.request_key);
//
//             if (data.status === 'completed') {
//                 if (res.err) {
//                     console.log(res.err);
//
//                     clearInterval(mobilePolling);
//
//                     callback.failCallback();
//                     window?.toast('success', Lang.err);
//                 }
//
//                 if (data.result) {
//                     await dispatch(updateTokens(wallet, new PerPlus({ contractList: contractList })));
//                     clearInterval(mobilePolling);
//
//                     callback.sucCallback();
//                     window?.toast('success', Lang.suc_klip_msg);
//                 }
//             }
//         } catch (e) {
//             callback.failCallback();
//             console.error(e);
//             window?.toast('error', Lang.fail_klip_msg);
//         } finally {
//         }
//     }, 1000);
// };

/**
 * klaymint.util에서 contractAddress에 있는 토큰을 불러올때 자르는 함수
 * @param arr
 * @param n
 * @param mapOption
 */
export const spliceArray = (arr: any[], n: number, mapOption?) => {
    if (mapOption) arr = arr.map(mapOption);

    const result = [];

    for (let i = 0; i < n; i++) {
        const newArr = arr.splice(0, 10);
        result.push(newArr);
    }

    return result;
};
/**
 * 컨트랙트 어드레스와 팩토리 어드레스를 1차 조건으로 true : getPower call()
 * 예외처리 return 0
 * false : axios 요쳥 getIsStakeAvailable
 * @param _contractAddres
 * @param _tokenId
 */
export const getStakeAvailable = async (_contractAddres: string, _tokenId: number): Promise<string> => {
    if (_contractAddres.toLowerCase() === viewPowerAddress.toLowerCase()) {
        if (window.klaytn) {
            const caver = new Caver(window.klaytn);
            const DB = new caver.klay.Contract(dbContractABI, envDBContractAddress);

            try {
                return await DB.methods.getPower(_contractAddres, _tokenId).call();
            } catch (e) {
                return '0';
            }
        } else {
            const res = await getIsStakeAvailable(_contractAddres, _tokenId);
            return res.data;
        }
    } else return '0';
};
