import { createAction, handleActions } from 'redux-actions';
import { applyPenders } from 'redux-pender';
import { loadKlayBalance, loadPerBalance } from '@/helpers/_common';
import PerPlus from '@/helpers/PerPlus';
import { getHoldCount } from '@/helpers/klaymint.api';
import { getEctl, getKlayFromAddress, getPerBalance } from '@/helpers/klaymint.api';
import Caver from 'caver-js';
import _ from 'lodash';
import KASHelper from '@/helpers/KAS.helper';

interface PerPlusInterface {
    classs: any;
    totalClasssSortNumber: any;
    contractList: any[];
    info: {
        nickName: string;
        inventory: any[];
        itemInventory: any[];
        sortInventory: any;
        balance: {
            klay: number | string | null | undefined | any;
            per: number | string | null | undefined | any;
        };
        holds: any;
    };
}

const initialState: PerPlusInterface = {
    classs: {},
    totalClasssSortNumber: {},
    contractList: [],
    info: {
        nickName: '',
        inventory: [],
        itemInventory: [],
        sortInventory: [],
        balance: {
            klay: 0,
            per: 0,
        },
        holds: {},
    },
};

export const loadInitState = async (wallet) => {
    if (wallet.info.address === '') {
        const res = await getEctl();

        return {
            contractList: res.data.list,
            info: {},
            classs: res.data.classs,
            totalClasssSortNumber: res.data.totalClasss,
        };
    }
    const res = await getEctl(wallet.info.address);

    const myInfo = {
        nickName: res.data.nickName?.usr_nickname ?? null,
        dashboard: [],
    };

    /******************************************************/
    const kasHelper = new KASHelper();
    const perPlus = new PerPlus({ contractList: res.data.list });
    /******************************************************/

    const inventory = await perPlus.perGetToken(wallet, res.data.totalClasss);
    const contractAddress = res?.data.list[0].itm_contract_address;
    const itemInventory = await kasHelper.checkKIP37(wallet.info.address, contractAddress);
    const holds = {};
    const ctlArr = [];
    _.forEach(res.data.list, async (item, index) => {
        if (ctlArr.indexOf(item.ctl_contract_address) == -1) {
            const _param = item?.ctl_staking_address
                ? {
                      address: wallet.info.address,
                      contractAddress: item.ctl_contract_address,
                      stakerAddress: item.ctl_staking_address,
                  }
                : {
                      address: wallet.info.address,
                      contractAddress: item.ctl_contract_address,
                  };

            const nftBalance = await getHoldCount(_param);
            console.log('perplus _param : ', _param);
            console.log('perplus  nftBalance.data : ', nftBalance.data);
            holds[item.ctl_contract_address] = nftBalance.data;
            ctlArr.push(item.ctl_contract_address);
        }
    });

    if (window.klaytn) {
        const perBalance = await loadPerBalance(new Caver(window.klaytn), wallet.info.address);
        const klayBalance = await loadKlayBalance(new Caver(window.klaytn), wallet.info.address);

        return {
            contractList: res.data.list,
            info: {
                nickName: myInfo.nickName,
                inventory: inventory,
                itemInventory: itemInventory,
                sortInventory: inventory,
                balance: {
                    per: perBalance,
                    klay: klayBalance,
                },
                holds: holds,
            },
            classs: res.data.classs,
            totalClasssSortNumber: res.data.totalClasss,
        };
    } else {
        const perBalance = await getPerBalance(wallet.info.address);
        const klayBalance = await getKlayFromAddress(wallet.info.address);

        return {
            contractList: res.data.list,
            info: {
                nickName: myInfo.nickName,
                inventory: inventory,
                itemInventory: itemInventory,
                sortInventory: inventory,
                balance: {
                    per: perBalance.data,
                    klay: klayBalance.data,
                },
                holds: holds,
            },
            classs: res.data.classs,
            totalClasssSortNumber: res.data.totalClasss,
        };
    }
};

const updateToken = async (wallet, perPlus) => {
    let per = '';

    if (window.klaytn) per = await loadPerBalance(new Caver(window.klaytn), wallet.info.address);
    else {
        const res = await getPerBalance(wallet.info.address);
        per = res.data;
    }

    const kasHelper = new KASHelper();
    const res = await getEctl(wallet.info.address);
    const contractAddress = res?.data.list[0].itm_contract_address;
    const itemInventory = await kasHelper.checkKIP37(wallet.info.address, contractAddress);

    const inventory = await perPlus.perGetToken(wallet, res.data.totalClasss);

    return {
        info: {
            inventory: inventory,
            itemInventory: itemInventory,
            balance: {
                per: per,
            },
        },
    };
};

const updateAllBalance = async (wallet) => {
    if (window.klaytn) {
        const perBalance = await loadPerBalance(new Caver(window.klaytn), wallet.info.address);
        const klayBalance = await loadKlayBalance(new Caver(window.klaytn), wallet.info.address);

        return {
            info: {
                balance: {
                    per: perBalance,
                    klay: klayBalance,
                },
            },
        };
    } else {
        const perBalance = await getPerBalance(wallet.info.address);
        const klayBalance = await getKlayFromAddress(wallet.info.address);

        return {
            info: {
                balance: {
                    per: perBalance.data,
                    klay: klayBalance.data,
                },
            },
        };
    }
};

/** ***************** ACTIONS  ****************** */
const LOAD = 'PERPLUS/LOAD';
const UPDATE = 'PERPLUS/UPDATE';

const UPDATE_BALACE = 'UPDATE_BALACE/UPDATE';

const DEL_TOKEN = 'PERPLUS/DEL_TOKEN';

const SORT_INVENTORY = 'PERPLUS/SORT_INVENTORY';
const ITEM_INVENTORY = 'PERPLUS/ITEM_INVENTORY';

/** ***************** ACTION FUNCTIONS ****************** */
export const loadPerPlus = createAction(LOAD, async (wallet) => await loadInitState(wallet));

export const updateBalance = createAction(UPDATE_BALACE, async (wallet) => await updateAllBalance(wallet));
export const updateTokens = createAction(UPDATE, async (wallet, perPlus) => await updateToken(wallet, perPlus));

export const delToken = createAction(DEL_TOKEN, (data: { mainTokenId: number; subTokenId: number }) => data);

export const sortInventory = createAction(SORT_INVENTORY, (data) => data);

export const itemInventory = createAction(ITEM_INVENTORY);

const reducer = handleActions(
    {
        [LOAD]: (state, action) => {
            return {
                ...state,
                classs: action.payload?.classs,
                totalClasssSortNumber: action.payload?.totalClasssSortNumber,
                contractList: action.payload?.contractList,
                info: {
                    nickName: action.payload?.info.nickName,
                    inventory: action.payload?.info.inventory,
                    sortInventory: action.payload?.info.inventory,
                    itemInventory: action.payload?.info.itemInventory,
                    balance: action.payload?.info.balance,
                    holds: action.payload?.info.holds,
                },
            };
        },

        [UPDATE_BALACE]: (state, action) => {
            return {
                ...state,
                info: {
                    ...state.info,
                    balance: action.payload?.info.balance,
                },
            };
        },

        [UPDATE]: (state, action) => {
            return {
                ...state,
                info: {
                    ...state.info,
                    inventory: action.payload.info.inventory,
                    itemInventory: action.payload?.info.itemInventory,
                    balance: {
                        per: action.payload.info.balance.per,
                    },
                },
            };
        },

        [DEL_TOKEN]: (state, action: any) => {
            const prevInventory = [...state.info.inventory];
            const prevSortInventory = [...state.info.sortInventory];
            const nextInventory = prevInventory.filter(
                (item) => item.tokenId !== action.payload.mainTokenId && item.tokenId !== action.payload.subTokenId,
            );
            const nextSortInventory = prevSortInventory.filter(
                (item) => item.tokenId !== action.payload.mainTokenId && item.tokenId !== action.payload.subTokenId,
            );

            console.log(prevInventory.length);
            console.log(nextInventory.length);

            console.log(prevSortInventory.length);
            console.log(nextSortInventory.length);

            return {
                ...state,
                info: {
                    ...state.info,
                    sortInventory: nextSortInventory,
                    inventory: nextInventory,
                },
            };
        },
        [ITEM_INVENTORY]: (state, action) => {
            return {
                ...state,
                info: {
                    ...state.info,
                    sortInventory: action.payload,
                },
            };
        },

        [SORT_INVENTORY]: (state, action) => {
            return {
                ...state,
                info: {
                    ...state.info,
                    sortInventory: action.payload,
                },
            };
        },
    },

    initialState,
);

export default applyPenders(reducer, [
    {
        type: LOAD,
        onPending: (state, action) => {
            return state;
        },
        onSuccess: (state, action) => ({
            ...state,
            classs: action.payload.classs,
            totalClasssSortNumber: action.payload.totalClasssSortNumber,
            contractList: action.payload.contractList,
            info: {
                nickName: action.payload.info.nickName,
                inventory: action.payload.info.inventory,
                itemInventory: action.payload?.info.itemInventory,
                sortInventory: action.payload.info.inventory,
                balance: action.payload.info.balance,
                holds: action.payload.info.holds,
            },
        }),
        onFailure: (state, action) => {
            console.log('onFailure', action.payload);
            return {
                ...state,
                classs: {},
                totalClasssSortNumber: {},
                contractList: [],
                info: {
                    nickName: '',
                    inventory: [],
                    sortInventory: [],
                    itemInventory: [],
                    balance: {
                        klay: 0,
                        per: 0,
                    },
                    holds: {},
                },
            };
        },
    },
    {
        type: UPDATE,
        onPending: (state, action) => {
            return state;
        },
        onSuccess: (state, action) => ({
            ...state,
            info: {
                ...state.info,
                inventory: action.payload.info.inventory,
                itemInventory: action.payload.info.itemInventory,
                balance: {
                    ...state.info.balance,
                    per: action.payload.info.balance.per,
                },
            },
        }),
        onFailure: (state, action) => {
            console.log('onFailure', action.payload);
            return {
                ...state,
            };
        },
    },
]);
