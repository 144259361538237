import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/connectors.redux';
import { ClientInitValues, MessageEventType, MessageType } from '@/helpers/WebSocket/index.declare';

const useSendMessage = <T extends keyof ClientInitValues<MessageType>>(
    { MessageType, MessageEventType }: { MessageType: T; MessageEventType: MessageEventType },
    MessageInterval: number,
    MessageData: any = null,
    dependencyList: ReadonlyArray<boolean> = [],
): ClientInitValues<MessageType>[T] => {
    const wsc = useSelector((store: RootState) => store.Socket.webSocketClientModel);

    const [state, setState] = useState(wsc.initValues[MessageType]);

    useEffect(() => {
        if ([wsc.isHealthy, ...dependencyList].indexOf(false) !== -1) return;

        wsc.dependency[MessageType] = '';

        wsc.addEvent(MessageEventType, () => {
            setState(wsc[MessageType]);
        });

        return () => wsc.removeEvent(MessageEventType);
    }, [wsc.isHealthy]);

    useEffect(() => {
        if ([wsc.isHealthy, ...dependencyList].indexOf(false) !== -1) return;

        let timeout: NodeJS.Timeout;

        (function sendMsg() {
            wsc.sendMessage(MessageType, MessageData);

            timeout = setTimeout(sendMsg, MessageInterval);
        })();

        return () => clearTimeout(timeout);
    }, [wsc.isHealthy]);

    return state;
};

export default useSendMessage;
