export const isDev = process.env.NODE_ENV === 'development';

/**
 * Switching .env Define Values
 */

export const envFrontHost = isDev
    ? process.env.REACT_APP_DEVELOPMENT_FRONTEND_HOST
    : process.env.REACT_APP_PRODUCTION_FRONTEND_HOST;

export const envBackImageHost = isDev
    ? process.env.REACT_APP_DEVELOPMENT_BACKEND_IMAGE_HOST
    : process.env.REACT_APP_PRODUCTION_BACKEND_IMAGE_HOST;

export const envBackWebSocketHost = isDev
    ? process.env.REACT_APP_DEVELOPMENT_BACKEND_WEB_SOCKET_HOST
    : process.env.REACT_APP_PRODUCTION_BACKEND_WEB_SOCKET_HOST;

export const envBackHost = isDev
    ? process.env.REACT_APP_DEVELOPMENT_BACKEND_API_HOST
    : process.env.REACT_APP_PRODUCTION_BACKEND_API_HOST;

export const envBackListenHost = isDev
    ? process.env.REACT_APP_DEVELOPMENT_BACKEND_LISTEN_HOST
    : process.env.REACT_APP_PRODUCTION_BACKEND_LISTEN_HOST;

export const envBAppName = isDev
    ? process.env.REACT_APP_DEVELOPMENT_MY_BAPP_NAME
    : process.env.REACT_APP_PRODUCTION_MY_BAPP_NAME;

export const envHeadTitle = isDev
    ? process.env.REACT_APP_DEVELOPMENT_HEAD_TITLE
    : process.env.REACT_APP_PRODUCTION_HEAD_TITLE;

export const envOwnerWalletAddress = isDev
    ? process.env.REACT_APP_DEVELOPMENT_OWNER_WALLET_ADDRESS
    : process.env.REACT_APP_PRODUCTION_OWNER_WALLET_ADDRESS;

export const envFactoryAddress = isDev
    ? process.env.REACT_APP_DEVELOPMENT_FACTORY_ADDRESS
    : process.env.REACT_APP_PRODUCTION_FACTORY_ADDRESS;

export const envForMoveFactoryAddress = isDev
    ? process.env.REACT_APP_DEVELOPMENT_FOR_MOVE_FACTORY_ADDRESS
    : process.env.REACT_APP_PRODUCTION_FOR_MOVE_FACTORY_ADDRESS;

export const envPOPOContractAddress = isDev
    ? process.env.REACT_APP_DEVELOPMENT_POPO_CONTRACT_ADDRESS
    : process.env.REACT_APP_PRODUCTION_POPO_CONTRACT_ADDRESS;

export const envKlaytnAPIToken = isDev
    ? process.env.REACT_APP_DEVELOPMENT_KLAYTN_API_TOKEN
    : process.env.REACT_APP_PRODUCTION_KLAYTN_API_TOKEN;

export const envMintQ = isDev ? process.env.REACT_APP_DEVELOPMENT_MINT_Q : process.env.REACT_APP_PRODUCTION_MINT_Q;

export const envMintRequestValue = isDev
    ? process.env.REACT_APP_DEVELOPMENT_MINT_REQUEST_VALUE
    : process.env.REACT_APP_PRODUCTION_MINT_REQUEST_VALUE;

export const envEnNode = isDev ? process.env.REACT_APP_DEVELOPMENT_EN_NODE : process.env.REACT_APP_PRODUCTION_EN_NODE;

export const envKASAccessKey = process.env.REACT_APP_COMMONS_KAS_ACCESSKEY;
export const envKASSecretKey = process.env.REACT_APP_COMMONS_KAS_SECRETKEY;

export const myBAppName = process.env.REACT_APP_COMMONS_MY_BAPP_NAME;
export const qNumber = envMintQ;
export const EN_NODE = process.env.REACT_APP_COMMONS_EN_NODE;
/**
 *  dev build 할때 필요한놈들 입니다 ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
 */
// export const autoMintFactory = isDev
//     ? '0x0657e70452c3817b8d54b53693A1b0977d54Fe1d'
//     : '0x0657e70452c3817b8d54b53693A1b0977d54Fe1d';
//
// export const poolAddress = isDev
//     ? '0xFD350D211DC3c34984CA0198856A61A25AC803A7'
//     : '0xFD350D211DC3c34984CA0198856A61A25AC803A7';
//
// export const perKlayLP = '0x3012cc7a5a137362f97ab1fc69e61f927ac70973';
// export const klaySwapFactory = '0xc6a2ad8cc6e4a7e08fc37cc5954be07d499e7654';
// export const klayAddress = '0x0000000000000000000000000000000000000000';
// export const perSymbol = isDev ? 'dan2' : 'PER';
// export const perAddress = isDev
//     ? '0x2214B36A80D6981a7dCdabE7E5E6aa7A2FcbcC83'
//     : '0x2214B36A80D6981a7dCdabE7E5E6aa7A2FcbcC83';
//
// export const viewPowerAddress = isDev
//     ? '0xd6DC0CBf526eB868F357854404943187EAAdEa29'
//     : '0xd6DC0CBf526eB868F357854404943187EAAdEa29';
//
// export const stakerAddress = isDev
//     ? '0x82263888046794af55e8d160d19907b55a388576'
//     : '0x82263888046794af55e8d160d19907b55a388576';
//
// export const envDBContractAddress = isDev
//     ? '0x85962FaAaede278218FB39C04c59A9cea1B231Bc'
//     : '0x85962FaAaede278218FB39C04c59A9cea1B231Bc';
/**
 * dev build 할때 필요한놈들 입니다 ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑
 */
/**
 * live build 할때 필요한놈들 입니다 ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
 */
export const autoMintFactory = isDev
    ? '0x0657e70452c3817b8d54b53693A1b0977d54Fe1d'
    : '0xc3A103Ee0FEb34f736A90F59F5FfFb95bEEe61C1';

export const poolAddress = isDev
    ? '0xFD350D211DC3c34984CA0198856A61A25AC803A7'
    : '0xA5D0A6ed6D73e70F2f9e77a3023B49eE1d878362';

export const perKlayLP = '0x3012cc7a5a137362f97ab1fc69e61f927ac70973';
export const klaySwapFactory = '0xc6a2ad8cc6e4a7e08fc37cc5954be07d499e7654';
export const klayAddress = '0x0000000000000000000000000000000000000000';

export const perSymbol = isDev ? 'dan2' : 'PER';
export const perAddress = isDev
    ? '0x2214B36A80D6981a7dCdabE7E5E6aa7A2FcbcC83'
    : '0x7eee60a000986e9efe7f5c90340738558c24317b';

export const viewPowerAddress = isDev
    ? '0xd6DC0CBf526eB868F357854404943187EAAdEa29'
    : '0x0Ed55aEe0399064Cfe51dD3cC10D99734bb796c7';

export const stakerAddress = isDev
    ? '0x82263888046794af55e8d160d19907b55a388576'
    : '0xe53120c32395677cb9a80e1bcbb11a65d7032ba7';

export const envDBContractAddress = isDev
    ? '0x85962FaAaede278218FB39C04c59A9cea1B231Bc'
    : '0x2002F8eAd3462a13f32C3508F892a45b06e37f16';
/**
 * live build 할때 필요한놈들 입니다 ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑
 */

//************ 스왑 테스트를 위해 찐 per */////////////////////
// export const perSymbol = isDev ? 'PER' : 'PER';
// export const perAddress = isDev
//     ? '0x7eee60a000986e9efe7f5c90340738558c24317b'
//     : '0x7eee60a000986e9efe7f5c90340738558c24317b';
//////////////////////////////////////////////////////////

/**
 * define Types
 */
declare global {
    interface Window {
        envFrontHost?: typeof envFrontHost;
        envBackImageHost?: typeof envBackImageHost;
        envBackWebSocketHost?: typeof envBackWebSocketHost;
        envBackHost?: typeof envBackHost;
        envBackListenHost?: typeof envBackListenHost;
        envBAppName?: typeof envBAppName;
        envHeadTitle?: typeof envHeadTitle;
        envFactoryAddress?: typeof envFactoryAddress;
        envForMoveFactoryAddress?: typeof envForMoveFactoryAddress;
        envPOPOContractAddress?: typeof envPOPOContractAddress;
        envMintRequestValue?: typeof envMintRequestValue;
        envDBContractAddress?: typeof envDBContractAddress;
        // envOwnerWalletAddress?: typeof envOwnerWalletAddress;
        // envKlaytnAPIToken?: typeof envKlaytnAPIToken;
        // envMintQ?: typeof envMintQ;
        envEnNode?: typeof envEnNode;
    }
}

/**
 * Add window object
 */
window.envFrontHost = envFrontHost;
window.envBackImageHost = envBackImageHost;
window.envBackWebSocketHost = envBackWebSocketHost;
window.envBackHost = envBackHost;
window.envBackListenHost = envBackListenHost;
window.envBAppName = envBAppName;
window.envHeadTitle = envHeadTitle;
window.envFactoryAddress = envFactoryAddress;
window.envForMoveFactoryAddress = envForMoveFactoryAddress;
window.envPOPOContractAddress = envPOPOContractAddress;
window.envMintRequestValue = envMintRequestValue;
// window.envOwnerWalletAddress = envOwnerWalletAddress;
// window.envKlaytnAPIToken = envKlaytnAPIToken;
// window.envMintQ = envMintQ;
window.envEnNode = envEnNode;
window.envDBContractAddress = envDBContractAddress;

/**
 * env 확인용 print 콘솔 **************************************
 */
console.log('load Define Variable :', process.env.NODE_ENV, {
    'window FrontHost': window.envFrontHost,
    'window envBackImageHost': window.envBackImageHost,
    'window envBackWebSocketHost': window.envBackWebSocketHost,
    'window BackHost': window.envBackHost,
    'window envBackListenHost': window.envBackListenHost,
    'window BAppName': window.envBAppName,
    'window envHeadTitle': window.envHeadTitle,
    'window EnNode': window.envEnNode,
    'window FactoryAddress': window.envFactoryAddress,
    'window envForMoveFactoryAddress': window.envForMoveFactoryAddress,
    'window POPOContractAddress': window.envPOPOContractAddress,
    'window MintRequestValue': window.envMintRequestValue,
    'window DBContractAddress': window.envDBContractAddress,

    '.env OwnerWalletAddress': envOwnerWalletAddress,
    '.env KlaytnAPIToken': envKlaytnAPIToken,
    '.env MintQ': envMintQ,
});
