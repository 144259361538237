import {
    BurnData,
    ClientInitValues,
    MessageType,
    MintList,
    PerPlusChannelBroadCastData,
    ProcessingInterface,
    StakeViewData,
    StakingPoolData,
    TxCountLevel,
} from '@/helpers/WebSocket/index.declare';
import _ from 'lodash';
import KlayMintUtil from '@/helpers/KlayMint.util';

export default class OnMessageEventHandler {
    public isHealthy = false;

    private util: KlayMintUtil = new KlayMintUtil();

    public readonly initValues: ClientInitValues<MessageType> = {
        [MessageType.mintData]: [],
        [MessageType.soldOutData]: [],
        [MessageType.autoMintData]: [],

        [MessageType.burnData]: { burnBalance: 0, marketBalance: 0 },

        [MessageType.stakingPoolData]: { klayBalance: '0', perBalance: '0' },

        [MessageType.processingData]: {
            totalItems: {
                type1: [],
                type2: [],
                type3: [],
            },
            myItems: {
                type3: [],
                type2: [],
                type1: [],
                complete: [],
            },
        },

        [MessageType.broadcastPerPlusData]: {
            serverStatus: { txCount: TxCountLevel.possible, released: true, status: [] },
            megaphoneData: [],
        },
        [MessageType.broadcastPerPlusStakingData]: {
            totalWeight: 0,
            klaySupply: 0,
            erc20Supply: 0,
            dailyKlayReward: 0,
            dailyERC20Reward: 0,
            maxStakingCount: 0,
        },
    };

    public [MessageType.mintData]: MintList = this.initValues[MessageType.mintData];
    public [MessageType.soldOutData]: MintList = this.initValues[MessageType.soldOutData];
    public [MessageType.autoMintData]: MintList = this.initValues[MessageType.autoMintData];

    public [MessageType.burnData]: BurnData = this.initValues[MessageType.burnData];

    public [MessageType.stakingPoolData]: StakingPoolData = this.initValues[MessageType.stakingPoolData];

    public [MessageType.processingData]: ProcessingInterface = this.initValues[MessageType.processingData];

    public [MessageType.broadcastPerPlusData]: PerPlusChannelBroadCastData =
        this.initValues[MessageType.broadcastPerPlusData];
    public [MessageType.broadcastPerPlusStakingData]: StakeViewData =
        this.initValues[MessageType.broadcastPerPlusStakingData];

    protected setStakeView = (_data) => {
        this[MessageType.broadcastPerPlusStakingData] = {
            totalWeight: +_data.totalWeight,
            klaySupply: this.util.SafeMath.safeDivide(_data.klaySupply, 10 ** 18, 10),
            erc20Supply: this.util.SafeMath.safeDivide(_data.erc20Supply, 10 ** 18, 10),
            dailyKlayReward: this.util.SafeMath.safeDivide(_data.dailyKlayReward, 10 ** 18, 10),
            dailyERC20Reward: this.util.SafeMath.safeDivide(_data.dailyERC20Reward, 10 ** 18, 10),
            maxStakingCount: +_data.maxStakingCount,
        };
        console.log(this[MessageType.broadcastPerPlusStakingData]);
    };

    protected setAutoMintData = (_data: any[]) => {
        const _arrAutomint = {};
        const _arrFirst = [];

        _.forEach(_data, (el) => {
            if (_arrAutomint[el.ctl_idx] === undefined) {
                _arrAutomint[el.ctl_idx] = [];
                _arrAutomint[el.ctl_idx].push(el);
                _arrFirst.push(el.ctl_idx);
            } else {
                _arrAutomint[el.ctl_idx].push(el);
            }
        });

        const _arrAutomint_tmp = Object.values(_arrAutomint);
        _.forEach(_arrAutomint_tmp, (el: Array<any>, i) => {
            el.sort(function (a, b) {
                return b['mtl_price'] * 1 - a['mtl_price'] * 1;
            });
        });

        this[MessageType.autoMintData] = _arrAutomint_tmp;
    };
}
