import React, { useEffect } from 'react';
import _ArticleModal from '@/_components/commons/modals/_ArticleModal';
import { ArticleModalProps } from '@/_components/commons/modals/_ArticleModal.interfaces.declare';
import _notExistClassArticleModal from '@/_components/commons/modals/_notExistClassArticleModal';

interface Props {
    setModal: React.Dispatch<React.SetStateAction<boolean>>;
    modalProps: ArticleModalProps;
    collection: any;
    optimisticDelete: any;
}

const DetailModal = ({ modalProps, setModal, collection, optimisticDelete }: Props) => {
    return (
        <section>
            {collection.is_class ? (
                <_ArticleModal
                    props={modalProps.props}
                    setModal={setModal}
                    collection={collection}
                    optimisticDelete={optimisticDelete}
                />
            ) : (
                <_notExistClassArticleModal
                    props={modalProps.props}
                    setModal={setModal}
                    collection={collection}
                    optimisticDelete={optimisticDelete}
                />
            )}
            <div className="backgroundShadow" onClick={() => setModal(false)} />
        </section>
    );
};
export default DetailModal;
