import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import css from '../main.module.scss';
import klayicon from '@/_statics/images/icon_kaikas.svg';
import imgSoldOut from '@/_statics/images/soldout.png';
import imgEndOfSale from '@/_statics/images/endofsale.png';
import _ from 'lodash';
import { useLanguages } from '@/hooks/useLanguages.hook';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/connectors.redux';
import klaySvg from '@/_statics/images/PerPlus/icon/klay.svg';
import SuggestIcon from '@/_statics/images/PerPlus/icon/suggest.svg';

/**
 * Sold Out Commponent
 * 내부에서만 사용
 */
const CloseIcon: React.FC<any> = (props): JSX.Element => {
    const {
        icon,
        contract_name,
        brand_name,
        ctl_idx,
        mtl_auto,
        mtl_createdAt,
        mtl_endAt,
        mtl_idx,
        mtl_imgsrc,
        mtl_is_private,
        mtl_mint_count,
        mtl_max_count,
        mtl_price,
        mtl_startAt,
        mtl_updatedAt,
        sttc_total_mint,
        sttc_crnt_nft,
    } = props;

    const linkTo = `/collections/${contract_name}/${brand_name}`;
    if (mtl_auto > 0) {
        return (
            <div className="card shadow-sm">
                <Link className="closed_overlay position-absolute w-100 h-100" to={linkTo}>
                    <img
                        className="position-absolute top-50 start-50 translate-middle"
                        src={icon}
                        alt="Center Icon"
                        width="80%"
                    />
                </Link>
                <Link to={linkTo}>
                    <img className="closed_img" src={window.envBackImageHost + mtl_imgsrc} width={'100%'} />
                </Link>
                <div className={'carde-body carde-black'}>
                    <div className="row align-items-center justify-content-between">
                        <div className="col-7">
                            <div className="btn-group">
                                <Link
                                    to={linkTo}
                                    className="btn btn-coll btn-sm btn-outline-secondary text-center"
                                    style={{ zIndex: 11 }}
                                >
                                    Collection
                                </Link>
                            </div>
                        </div>
                        <div className="col-5 row text-muted text-end">
                            <small
                                className={cx(css.Quantitiy, 'd-flex align-items-center justify-content-end')}
                                style={{ fontSize: '1em', color: '#7729DA' }}
                            >
                                {sttc_crnt_nft} / {sttc_total_mint}
                            </small>
                            <b className="col-12">
                                <div className="d-flex align-items-center justify-content-end">
                                    <img className="mx-1 mb-1" width={16} src={klayicon} alt="icon" /> {mtl_price}
                                </div>
                            </b>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="card shadow-sm">
                <Link className="closed_overlay position-absolute w-100 h-100" to={linkTo}>
                    <img
                        className="position-absolute top-50 start-50 translate-middle"
                        src={icon}
                        alt="Center Icon"
                        width="80%"
                    />
                </Link>
                <Link to={linkTo}>
                    <img className="closed_img" src={window.envBackImageHost + mtl_imgsrc} width={'100%'} />
                </Link>
                <div className={'carde-body carde-black'}>
                    <div className="row align-items-center justify-content-between">
                        <div className="col-7">
                            <div className="btn-group">
                                <Link
                                    to={linkTo}
                                    className="btn btn-coll btn-sm btn-outline-secondary text-center"
                                    style={{ zIndex: 11 }}
                                >
                                    Collection
                                </Link>
                            </div>
                        </div>
                        <div className="col-5 row text-muted text-end">
                            <small className="col-12">
                                {mtl_mint_count} / {mtl_max_count}
                            </small>
                            <b className="col-12">
                                <div className="d-flex align-items-center justify-content-end">
                                    <img className="mx-1 mb-1" width={16} src={klayicon} alt="icon" /> {mtl_price}
                                </div>
                            </b>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

const Close: React.FC<any> = (props): JSX.Element => {
    const [strTime, setStrTime] = useState('00:00:00:00');
    const {
        //countdown,
        contract_name,
        brand_name,
        ctl_idx,
        mtl_auto,
        mtl_createdAt,
        mtl_endAt,
        mtl_idx,
        mtl_imgsrc,
        mtl_is_private,
        mtl_mint_count,
        mtl_max_count,
        mtl_price,
        mtl_startAt,
        mtl_updatedAt,
        tick,
        sttc_crnt_nft,
        sttc_total_mint,
    } = props;

    useEffect(() => {
        if (props.tick === null && props.tick === undefined) return;
        if (props.tick < 0) return;
        //console.log(props.mtl_idx, props.tick);

        const sec = String(props.tick % 60).padStart(2, '0');
        const min = String(Math.floor((props.tick / 60) % 60)).padStart(2, '0');
        const hour = String(Math.floor((props.tick / (60 * 60)) % 24)).padStart(2, '0');
        const day = String(Math.floor(props.tick / (60 * 60 * 24))).padStart(2, '0');

        setStrTime(day + ':' + hour + ':' + min + ':' + sec);
    }, [props.tick, setStrTime]);

    const linkTo = `/collections/${contract_name}/${brand_name}`;
    if (mtl_auto > 0) {
        return (
            <div className="card shadow-sm">
                <Link className="closed_overlay position-absolute w-100 h-100" to={linkTo}>
                    <div className="position-absolute top-50 start-50 translate-middle">
                        {tick >= 0 && (
                            <div
                                className="text-center p-2 rounded-3 text-white"
                                style={{
                                    fontSize: '3em',
                                    border: '2px solid white',
                                }}
                            >
                                {strTime}
                            </div>
                        )}
                    </div>
                </Link>
                <Link to={linkTo}>
                    <img className="closed_img" src={window.envBackImageHost + mtl_imgsrc} width={'100%'} />
                </Link>
                <div className={'carde-body carde-black'}>
                    <div className="row align-items-center justify-content-between">
                        <div className="col-xl-7 col-lg-12">
                            <div className="btn-group">
                                <Link
                                    to={linkTo}
                                    className="btn btn-coll btn-sm btn-outline-secondary text-center"
                                    style={{ zIndex: 11 }}
                                >
                                    Collection
                                </Link>
                            </div>
                        </div>
                        <div className="col-5 row text-muted text-end">
                            <small className="col-12">
                                {sttc_crnt_nft} / {sttc_total_mint}
                            </small>

                            <b className="col-12">
                                <div className="d-flex align-items-center justify-content-end">
                                    <img className="mx-1 mb-1" width={16} src={klayicon} alt="icon" /> {mtl_price}
                                </div>
                            </b>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="card shadow-sm">
                <Link className="closed_overlay position-absolute w-100 h-100" to={linkTo}>
                    <div className="position-absolute top-50 start-50 translate-middle">
                        {tick >= 0 && (
                            <div
                                className="text-center p-2 rounded-3 text-white"
                                style={{
                                    fontSize: '3em',
                                    border: '2px solid white',
                                }}
                            >
                                {strTime}
                            </div>
                        )}
                    </div>
                </Link>
                <Link to={linkTo}>
                    <img className="closed_img" src={window.envBackImageHost + mtl_imgsrc} width={'100%'} />
                </Link>
                <div className={'carde-body carde-black'}>
                    <div className="row align-items-center justify-content-between">
                        <div className="col-xl-7 col-lg-12">
                            <div className="btn-group">
                                <Link
                                    to={linkTo}
                                    className="btn btn-coll btn-sm btn-outline-secondary text-center"
                                    style={{ zIndex: 11 }}
                                >
                                    Collection
                                </Link>
                            </div>
                        </div>
                        <div className="col-5 row text-muted text-end">
                            <small className="col-12">
                                {mtl_mint_count} / {mtl_max_count}
                            </small>

                            <b className="col-12">
                                <div className="d-flex align-items-center justify-content-end">
                                    <img className="mx-1 mb-1" width={16} src={klayicon} alt="icon" /> {mtl_price}
                                </div>
                            </b>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

const CloseAuto: React.FC<any> = (props): JSX.Element => {
    const [strTime, setStrTime] = useState('00:00:00:00');
    const {
        //countdown,
        contract_name,
        brand_name,
        ctl_idx,
        mtl_auto,
        mtl_createdAt,
        mtl_endAt,
        mtl_idx,
        mtl_imgsrc,
        mtl_is_private,
        mtl_mint_count,
        mtl_max_count,
        mtl_price,
        mtl_startAt,
        mtl_updatedAt,
        sttc_crnt_nft,
        sttc_total_mint,
        mobile,
    } = props[0];

    const { tick } = props;

    useEffect(() => {
        if (props.tick === null && props.tick === undefined) return;
        if (props.tick < 0) return;
        //console.log(props.mtl_idx, props.tick);

        const sec = String(props.tick % 60).padStart(2, '0');
        const min = String(Math.floor((props.tick / 60) % 60)).padStart(2, '0');
        const hour = String(Math.floor((props.tick / (60 * 60)) % 24)).padStart(2, '0');
        const day = String(Math.floor(props.tick / (60 * 60 * 24))).padStart(2, '0');

        setStrTime(day + ':' + hour + ':' + min + ':' + sec);
    }, [props.tick, setStrTime]);

    const linkTo = `/collections/${contract_name}/${brand_name}`;
    const Lang = useLanguages();
    const { language } = useSelector((store: RootState) => store.GlobalStatus);

    return (
        <div className="card shadow-sm">
            <div className="closed_overlay position-absolute w-100 h-100">
                <div className="position-absolute top-50 start-50 translate-middle">
                    {tick >= 0 && (
                        <div
                            className="text-center p-2 rounded-3 text-white"
                            style={{
                                fontSize: '3em',
                                border: '2px solid white',
                            }}
                        >
                            {strTime}
                        </div>
                    )}
                </div>
            </div>
            <div>
                <img className="closed_img" src={window.envBackImageHost + mtl_imgsrc} width={'100%'} />
            </div>

            <div className={'carde-body carde-black'}>
                <div className="row align-items-center justify-content-between">
                    <div className="col-xl-7 col-lg-12">
                        <div className="btn-group">
                            {_.map(props, (el, i) => {
                                if (typeof el === 'object') {
                                    return (
                                        <button
                                            key={i}
                                            className={cx(
                                                // css.MintNowBtn,
                                                'btn btn-automint btn-sm btn-outline-secondary text-center',
                                            )}
                                        >
                                            {/* <i className="fas fa-shopping-cart" />  */}
                                            {el.mtl_min_hold !== 0
                                                ? language == 'en-US'
                                                    ? `owned ${el.mtl_min_hold} NFT`
                                                    : `${el.mtl_min_hold}개 홀더`
                                                : Lang.plusMinting_pubilc_mint}
                                            <br />
                                            {Math.floor(el.mtl_price)}KLAY
                                        </button>
                                    );
                                }
                            })}
                            {/* <Link
                                to={linkTo}
                                className="btn btn-coll btn-sm btn-outline-secondary text-center"
                                style={{ zIndex: 11 }}
                            >
                                Collection
                            </Link> */}
                        </div>
                    </div>
                    <div className="col-5 row text-muted text-end">
                        <small className={cx(css.Quantitiy, 'd-flex align-items-center justify-content-end')}>
                            {sttc_total_mint - sttc_crnt_nft}
                            {language === 'ko-KR' ? '' : mobile ? '' : <br />}
                            {Lang.plusMinting_remaining_numbers[0]}
                            {mobile ? '' : <br />}
                            {Lang.plusMinting_remaining_numbers[1]}
                        </small>
                        {/* <small className="col-12">
                            {sttc_crnt_nft} / {sttc_total_mint}
                        </small> */}

                        {/* <b className="col-12">
                            <div className="d-flex align-items-center justify-content-end">
                                <img className="mx-1 mb-1" width={16} src={klayicon} alt="icon" /> {mtl_price}
                            </div>
                        </b> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

const Soldout_Section: React.FC<any> = (props): JSX.Element => {
    const [strTime, setStrTime] = useState('00:00:00:00');
    const {
        //countdown,
        contract_name,
        brand_name,
        ctl_idx,
        mtl_auto,
        mtl_createdAt,
        mtl_endAt,
        mtl_idx,
        mtl_imgsrc,
        mtl_is_private,
        mtl_mint_count,
        mtl_max_count,
        mtl_price,
        mtl_startAt,
        mtl_updatedAt,
        tick,
    } = props;

    useEffect(() => {
        if (props.tick === null && props.tick === undefined) return;
        if (props.tick < 0) return;
        //console.log(props.mtl_idx, props.tick);

        const sec = String(props.tick % 60).padStart(2, '0');
        const min = String(Math.floor((props.tick / 60) % 60)).padStart(2, '0');
        const hour = String(Math.floor((props.tick / (60 * 60)) % 24)).padStart(2, '0');
        const day = String(Math.floor(props.tick / (60 * 60 * 24))).padStart(2, '0');

        setStrTime(day + ':' + hour + ':' + min + ':' + sec);
    }, [props.tick, setStrTime]);

    const linkTo = `/collections/${contract_name}/${brand_name}`;

    return (
        <div className="card shadow-sm">
            {/* <Link className="closed_overlay position-absolute w-100 h-100" to={linkTo}>
                <img
                    className="position-absolute top-50 start-50 translate-middle"
                    src={mtl_mint_count === mtl_max_count ? imgSoldOut : imgEndOfSale}
                    alt="Center Icon"
                    width="80%"
                />
            </Link> */}
            <Link to={linkTo}>
                <img src={window.envBackImageHost + mtl_imgsrc} width={'100%'} />
            </Link>
            <div className={'carde-body carde-black'}>
                <div className="row align-items-center justify-content-between">
                    <div className="col-xl-7 col-lg-12">
                        <div className="btn-group">
                            <Link
                                to={linkTo}
                                className="btn btn-coll btn-sm btn-outline-secondary text-center"
                                style={{ zIndex: 11 }}
                            >
                                Collection
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Open: React.FC<any> = (props): JSX.Element => {
    const {
        onClick,
        contract_name,
        brand_name,
        ctl_idx,
        mtl_auto,
        mtl_createdAt,
        mtl_endAt,
        mtl_idx,
        mtl_imgsrc,
        mtl_is_private,
        mtl_mint_count,
        mtl_max_count,
        mtl_price,
        mtl_startAt,
        mtl_updatedAt,
        mtl_ready,
        contract_address,
        factory_address,
        sttc_crnt_nft,
        sttc_total_mint,
    } = props;

    const linkTo = `/collections/${contract_name}/${brand_name}`;
    // if (mtl_auto > 0) {
    if (mtl_auto > 0) {
        return (
            <div className="card shadow-sm">
                {mtl_ready !== 0 && (
                    <Link to={linkTo}>
                        <img src={window.envBackImageHost + mtl_imgsrc} width={'100%'} />
                    </Link>
                )}
                {mtl_ready === 0 && <img src={window.envBackImageHost + mtl_imgsrc} width={'100%'} />}

                <div className={'card-body'}>
                    <div className="row align-items-center justify-content-between">
                        <div className="col-xl-5 col-lg-7 col-sm-12">
                            {mtl_ready !== 0 && (
                                <div className="btn-group">
                                    <Link
                                        to={linkTo}
                                        className={cx(
                                            css.AutoMintBtn,
                                            'btn btn-coll btn-sm btn-outline-secondary text-center',
                                        )}
                                    >
                                        Collection
                                    </Link>
                                    <button
                                        className={cx(
                                            css.MintNowBtn,
                                            'btn btn-mint btn-sm btn-outline-secondary text-center',
                                        )}
                                        onClick={onClick}
                                    >
                                        <i className="fas fa-shopping-cart" /> Mint Now
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className="col-xl-5 col-lg-5 col-sm-12 row text-muted text-end">
                            <small className={cx(css.Quantitiy, 'd-flex align-items-center justify-content-end')}>
                                {sttc_total_mint - sttc_crnt_nft}개 남음
                            </small>
                            <b className="d-flex align-items-center justify-content-end">
                                <img
                                    className={cx(css.KlaytnIconSort, 'mx-1 mb-1')}
                                    width={16}
                                    src={klayicon}
                                    alt="icon"
                                />
                                {Math.floor(mtl_price)}
                            </b>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="card shadow-sm">
                {mtl_ready !== 0 && (
                    <Link to={linkTo}>
                        <img src={window.envBackImageHost + mtl_imgsrc} width={'100%'} />
                    </Link>
                )}
                {mtl_ready === 0 && <img src={window.envBackImageHost + mtl_imgsrc} width={'100%'} />}
                <div className={'card-body'}>
                    <div className="row align-items-center justify-content-between">
                        <div className="col-xl-7 col-lg-7 col-sm-12">
                            {mtl_ready !== 0 && (
                                <div className="btn-group">
                                    <Link to={linkTo} className="btn btn-coll btn-sm btn-outline-secondary text-center">
                                        Collection
                                    </Link>
                                    <button
                                        className="btn btn-mint btn-sm btn-outline-secondary text-center"
                                        onClick={onClick}
                                    >
                                        <i className="fas fa-shopping-cart" /> Mint Now
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className="col-xl-5 col-lg-5 col-sm-12 row text-muted text-end">
                            <small className="d-flex align-items-center justify-content-end">
                                {mtl_mint_count} / {mtl_max_count}
                            </small>
                            <b className="d-flex align-items-center justify-content-end">
                                <img className="mx-1 mb-1" width={16} src={klayicon} alt="icon" /> {mtl_price}
                            </b>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

const OpenAuto: React.FC<any> = (props): JSX.Element => {
    const {
        contract_name,
        brand_name,
        ctl_idx,
        mtl_auto,
        mtl_createdAt,
        mtl_endAt,
        mtl_idx,
        mtl_imgsrc,
        mtl_is_private,
        mtl_mint_count,
        mtl_max_count,
        mtl_price,
        mtl_startAt,
        mtl_updatedAt,
        mtl_ready,
        contract_address,
        factory_address,
        sttc_crnt_nft,
        sttc_total_mint,
    } = props[0];

    const { onClick } = props;

    const linkTo = `/collections/${contract_name}/${brand_name}`;

    const Lang = useLanguages();
    const { language } = useSelector((store: RootState) => store.GlobalStatus);
    const { info } = useSelector((store: RootState) => store.PerPlus);
    const wallet = useSelector((store: RootState) => store.Wallet);
    const { mobile } = useSelector((store: RootState) => store.GlobalStatus);

    var suggestFlag = false;

    // const [suggestFlag, setSuggestFlag] = useState(false);
    return (
        <div className={cx(mobile ? css.mobileAutoMint : '', 'card shadow-sm')}>
            {mtl_ready !== 0 && (
                <Link to={linkTo}>
                    <img src={window.envBackImageHost + mtl_imgsrc} width={'100%'} />
                </Link>
            )}
            {mtl_ready === 0 && <img src={window.envBackImageHost + mtl_imgsrc} width={'100%'} />}

            <div className={'card-body'}>
                <div className="row align-items-center justify-content-between">
                    <div className="col-xl-5 col-lg-7 col-sm-12">
                        {mtl_ready !== 0 && (
                            <div className="btn-group">
                                {_.map(props, (el, i) => {
                                    if (typeof el === 'object') {
                                        let _css = '';
                                        if (wallet.isConn) {
                                            const hold = info?.holds ? info?.holds[el.contract_address] : 0; //nft contract별로 소유하고 있는 개수
                                            const nextProp = props[+i + 1];
                                            if (hold >= el.mtl_min_hold && suggestFlag === false) {
                                                // 소유 NFT가 필요 값보다 크거나 같고 추천이 없다면
                                                nextProp?.mtl_min_hold > hold //다음 최소 홀드 개수보다 작다면 현재 민팅 추천
                                                    ? (_css = css.btn_automint_suggest) //'btn-automint-suggest'
                                                    : (_css = css.btn_automint); //'btn-automint';

                                                _css == css.btn_automint_suggest ? (suggestFlag = true) : ''; //추천 이후에는 flag 는 true
                                            } else if (hold < el.mtl_min_hold) {
                                                _css = css.btn_automint; //'btn-automint'
                                            }

                                            if (
                                                wallet.isConn === true && //지갑이 연결되어 있고
                                                suggestFlag === false && //추천받은게 없으며
                                                nextProp === undefined //다음 항목이 없는경우
                                            ) {
                                                _css = css.btn_automint_suggest; //'btn-automint-suggest'; //너무 많아서 성립하는것이 없는것으로 판단, 추천한다
                                                suggestFlag = true;
                                            }
                                        } else {
                                            _css = css.btn_automint; //'btn-automint'; //로그인 하지 않은 회원은 추천이 없다
                                        }

                                        mobile
                                            ? _css == css.btn_automint
                                                ? (_css = css.btn_automint_mobile)
                                                : (_css = css.btn_automint_suggest_mobile)
                                            : '';

                                        return (
                                            <button
                                                className={cx(_css, 'btn  btn-sm btn-outline-secondary text-center')}
                                                onClick={() => onClick(el)}
                                                key={i}
                                            >
                                                {/* <i className="fas fa-shopping-cart" />  */}
                                                {el.mtl_min_hold === 0 ? (
                                                    <>
                                                        {/* 아래는 추천아이콘 표시 */}
                                                        {_css === css.btn_automint_suggest ||
                                                        _css === css.btn_automint_suggest_mobile ? (
                                                            <img className={css.suggestIcon} src={SuggestIcon} />
                                                        ) : (
                                                            ''
                                                        )}
                                                        {Lang.plusMinting_pubilc_mint}
                                                        <br />
                                                        {Lang.plusMinting_pubilc_mint_price}
                                                    </>
                                                ) : (
                                                    <>
                                                        {/* 아래는 추천아이콘 표시 */}
                                                        {_css === css.btn_automint_suggest ||
                                                        _css === css.btn_automint_suggest_mobile ? (
                                                            <img className={css.suggestIcon} src={SuggestIcon} />
                                                        ) : (
                                                            ''
                                                        )}
                                                        {Lang.plusMinting_owner_mint}
                                                        <br />
                                                        {el.mtl_min_hold}
                                                        {Lang.plusMinting_owner_mint_price}
                                                    </>
                                                )}
                                                <span className="mint-button-span">
                                                    <img src={klaySvg} /> {Math.floor(el.mtl_price)}
                                                </span>
                                            </button>
                                        );
                                    }
                                })}
                            </div>
                        )}
                    </div>
                    <div className="col-xl-5 col-lg-5 col-sm-12 row text-muted text-end">
                        <small className={cx(css.Quantitiy, 'd-flex align-items-center justify-content-end')}>
                            {sttc_total_mint - sttc_crnt_nft}
                            {language === 'ko-KR' ? '' : mobile ? '' : <br />}
                            {Lang.plusMinting_remaining_numbers[0]}
                            {mobile ? '' : <br />}
                            {Lang.plusMinting_remaining_numbers[1]}
                        </small>
                        {/* <b className="d-flex align-items-center justify-content-end"> */}
                        {/* <img
                                className={cx(css.KlaytnIconSort, 'mx-1 mb-1')}
                                width={16}
                                src={klayicon}
                                alt="icon"
                            />
                            {mtl_price} */}
                        {/* </b> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

const _timeout = [];
const MintWidget: React.FC<any> = (props): JSX.Element => {
    const [time, setTime] = useState(props.countdown);
    const [soldout, setSoldout] = useState(props.mtl_mint_count >= props.mtl_max_count);
    const [sellout, setSellout] = useState(false);
    const [visivilityState, setVisibilityState] = useState('visible');
    const {
        mtl_idx,
        mtl_soldout_section,
        mtl_auto,
        mtl_mint_count,
        mtl_max_count,
        mtl_endAt,
        mtl_ready,
        sttc_crnt_nft,
        sttc_total_mint,
        rerenderFlag,
        onClick = () => {},
        section,
        type,
    } = props;

    // ================ focuse 변경시 test =======================
    // useEffect(() => {
    //     const _now = new Date().getTime();
    //     const _startAt = new Date(props.mtl_startAt).getTime();
    //     if(time != Math.floor((_startAt - _now) / 1000)){
    //         setTime(Math.floor((_startAt - _now) / 1000));
    //         if(_timeout[mtl_idx] === null){
    //             _timeout[mtl_idx] = setTimeout(() => { setTime(time-1)}, 1000);
    //         }
    //     }
    // },[visivilityState]);

    // useEffect(() => {
    //     if(document.visibilityState != visivilityState){ setVisibilityState(document.visibilityState) }
    // }, [document.visibilityState]);
    // =========================================================

    useEffect(() => {
        if (_timeout !== null) {
            clearTimeout(_timeout[mtl_idx]);
            setTime(props.countdown);
        }
    }, [props.countdown]);

    useEffect(() => {
        if (visivilityState === 'visible') {
            // 1초마다 유저가 보는 갱신값
            // setTime(props.countdown);
            _timeout[mtl_idx] = setTimeout(() => {
                setTime(time - 1);
            }, 1000);
        }
        setSoldout(mtl_mint_count >= mtl_max_count);

        if (props.endcountdown !== null) {
            if (!soldout && time < 0) {
                if (props.endcountdown - Math.abs(time) < 0) {
                    setSellout(true);
                }
            }
        }
    }, [time, soldout]);

    /**
     * SOLD OUT 섹션 처리
     */
    if (mtl_soldout_section) return <Soldout_Section {...props} />;

    /**
     * 민팅 섹션에서의 처리
     */
    if (soldout) return <CloseIcon {...props} icon={imgSoldOut} />;
    if (sellout) return <CloseIcon {...props} icon={imgEndOfSale} />;

    /**
     * 오토민트
     */
    if (mtl_auto || type == 'auto') {
        //오토 위젯 랜더링
        if (props[0].sttc_crnt_nft - props[0].sttc_total_mint < 0 && time <= 0) {
            return <OpenAuto {...props} onClick={(data) => onClick(data)} />;
        } else {
            // return <OpenAuto {...props} onClick={(data) => onClick(data)} />;
            return <CloseAuto {...props} tick={time} />;
        }
    }

    if (mtl_ready == 0) {
        return <Open {...props} onClick={() => onClick(props)} />;
    }
    /**
     * 1회성 민트
     */

    if (!mtl_auto && time <= 0 && sellout === false) {
        return <Open {...props} onClick={() => onClick(props)} />;
    } else {
        console.log(time);
        return <Close {...props} tick={time} />;
    }
};

export default MintWidget;
