import OnMessageEventHandler from '@/helpers/WebSocket/onMessageEventHandler.helper';
import { Channels, ClientInitValues, MessageEventType, MessageType, PACKET_LAYAR } from './index.declare';

/****************************************************
 * @title WebSocket Client용 Model (typescript)
 * @author pts
 * @date 211124
 * @version 1.0.0
 * @description 웹 소켓 클라이언트 모델
 ****************************************************/

class WebSocketClientModel extends OnMessageEventHandler {
    private readonly SERVER_ADDR: string = window.envBackWebSocketHost;

    private ws: WebSocket;
    private uuid = '';

    public dependency: Record<string, string> = {};

    constructor(_ws: WebSocket) {
        super();

        this.initialize(_ws);
    }

    //Member Methods
    private initialize = (_ws: WebSocket = null) => {
        if (_ws) this.ws = _ws; //new WebSocket(this.SERVER_ADDR);

        //register EventHandler
        this.ws.onopen = this._handleOpen;
        this.ws.onclose = this._handleClose;
        this.ws.onerror = this._handleError;
        this.ws.onmessage = this._onMessage;

        // register Custom EventHandler
        console.log('*** Ready to WebSocket ***');
        // console.log(this.ws.CONNECTING); // 0
        // console.log(this.ws.OPEN);       // 1
        // console.log(this.ws.CLOSING);    // 2
        // console.log(this.ws.CLOSED);     // 3
    };

    public sendMessage = (type: MessageType, data: any = null) => {
        if (this.ws.readyState !== this.ws.OPEN) throw new Error('** WebSocket is Close **');

        const _packet: PACKET_LAYAR = {
            uuid: this.uuid,
            type: type,
            data: data,
        };

        this.ws.send(JSON.stringify(_packet));
    };

    private _handleOpen = (e: Event) => {
        console.log('Connected WebSocket >>');
        this.isHealthy = true;
    };

    private _handleClose = (e: CloseEvent) => {
        console.log('Closed WebSocket >>', this.SERVER_ADDR);
        this.isHealthy = false;

        const _interval = setInterval(() => {
            if (this.ws.readyState === this.ws.OPEN) {
                this.initialize();
                clearInterval(_interval);
                console.log('Reconnected Web Socket Server');
            } else {
                this.ws = new WebSocket(window.envBackWebSocketHost);
            }
        }, 10000);
    };
    private _handleError = (err: Event) => {
        console.log('Error WebSocket >>', err);
    };

    private _onMessage = (wssMsg: MessageEvent<any>) => {
        if (wssMsg.data === 'Welcome Klamint Web Socket Server') return;

        const _data: PACKET_LAYAR = JSON.parse(wssMsg.data);
        switch (_data.type) {
            case 'connected':
                this.uuid = _data.uuid;
                break;
            case MessageType.mintData:
                this[MessageType.mintData] = _data.data;
                break;
            case MessageType.soldOutData:
                this[MessageType.soldOutData] = _data.data;
                break;
            case MessageType.autoMintData:
                this.setAutoMintData(_data.data);
                break;
            case MessageType.burnData:
                this[MessageType.burnData] = _data.data;
                break;
            case MessageType.stakingPoolData:
                this[MessageType.stakingPoolData] = _data.data;
                break;
            case MessageType.processingData:
                this[MessageType.processingData] = _data.data;
                break;
            case MessageType.broadcastPerPlusData:
                this[MessageType.broadcastPerPlusData] = _data.data;
                break;
            case MessageType.broadcastPerPlusStakingData:
                this.setStakeView(_data.data);
                break;

            default:
                break;
        }

        this.dispatchEventsHandler(_data, wssMsg.data);
    };

    private dispatchEventsHandler = (data: PACKET_LAYAR, msg: string) => {
        if (this.dependency[MessageType[data.type]] !== msg) {
            this.dependency[MessageType[data.type]] = msg;
            this.ws.dispatchEvent(new Event(MessageEventType[data.type]));
        }
    };

    public addEvent = (eventName: string, eventHandler: (event?) => void | Promise<void>) => {
        this.ws.addEventListener(eventName, eventHandler);
    };

    public removeEvent = (eventName: string, eventHandler?: () => void | Promise<void>) => {
        this.ws.removeEventListener('eventName', eventHandler);
    };

    public enterChannel = (channel: Channels, data: any = null) => {
        if (this.ws.readyState !== this.ws.OPEN) throw new Error('** WebSocket is Close **');

        const _packet: PACKET_LAYAR = {
            channel: channel,
            uuid: this.uuid,
            type: 'enterChannel',
            data: data,
        };

        this.ws.send(JSON.stringify(_packet));
    };

    public leaveChannel = (channel: Channels, data: any = null) => {
        if (this.ws.readyState !== this.ws.OPEN) throw new Error('** WebSocket is Close **');

        const _packet: PACKET_LAYAR = {
            channel: channel,
            uuid: this.uuid,
            type: 'leaveChannel',
            data: data,
        };

        this.ws.send(JSON.stringify(_packet));
    };
}

export default WebSocketClientModel;
