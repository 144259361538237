import languagePack from '@/_statics/languages';

import { useSelector } from 'react-redux';
import { RootState } from '@/redux/connectors.redux';

const useLanguages = (): Record<string, any> => {
    const { language } = useSelector((store: RootState) => store.GlobalStatus);

    return languagePack[language] ?? languagePack[navigator.language];
};

export { useLanguages };
