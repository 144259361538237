export interface PACKET_LAYAR {
    channel?: Channels;
    uuid?: string;
    type: string;
    data: any;
}

export enum Channels {
    PerPlus = 'PerPlus',
    PerPlusStaking = 'PerPlusStaking',
}

export enum MessageType {
    mintData = 'mintData',
    soldOutData = 'soldOutData',
    autoMintData = 'autoMintData',

    burnData = 'burnData',

    stakingPoolData = 'stakingPoolData',

    processingData = 'processingData',

    broadcastPerPlusData = 'broadcastPerPlusData',
    broadcastPerPlusStakingData = 'broadcastPerPlusStakingData',
}

export enum MessageEventType {
    mintData = 'mintDataEvent',
    soldOutData = 'soldOutDataEvent',
    autoMintData = 'autoMintDataEvent',

    burnData = 'burnDataEvent',

    stakingPoolData = 'stakingPoolDataEvent',

    processingData = 'processingEventData',

    broadcastPerPlusData = 'broadcastPerPlusDataEvent',
    broadcastPerPlusStakingData = 'broadcastPerPlusStakingDataEvent',
}

export interface ClientInitValues<MessageType> {
    [MessageType.mintData]: MintList;
    [MessageType.soldOutData]: MintList;
    [MessageType.autoMintData]: MintList;

    [MessageType.burnData]: BurnData;

    [MessageType.stakingPoolData]: StakingPoolData;

    [MessageType.processingData]: ProcessingInterface;

    [MessageType.broadcastPerPlusData]: PerPlusChannelBroadCastData;
    [MessageType.broadcastPerPlusStakingData]: StakeViewData;
}

export enum TxCountLevel {
    possible = 1,
    delay = 2,
}

export type MintList = any[];

export interface PerPlusChannelBroadCastData {
    serverStatus: {
        txCount: TxCountLevel;
        released: boolean;
        status: ServerStatusData[];
    };
    megaphoneData: MegaphoneReturnMessage[];
}

export interface MegaphoneReturnMessage {
    type: string;
    nickname: string;
    rating: string;
}

export interface ServerStatusData {
    server_name: string;
    status: boolean;
}

export interface BurnData {
    burnBalance: number;
    marketBalance: number;
}

export interface StakingPoolData {
    perBalance: string;
    klayBalance: string;
}

export interface EnforceUserData {
    enforce_queue: any[];
    enforce_latestData: string;
    enforce_latest_type: string;
    enforce_latest_nickname: string;
    enforce_latest_rating: string;
}

export interface StakeViewData {
    totalWeight: number;
    klaySupply: number;
    erc20Supply: number;
    dailyKlayReward: number;
    dailyERC20Reward: number;
    maxStakingCount: number;
}

export interface ProcessingInterface {
    totalItems: ProcessingTotalItems;
    myItems: ProcessingMyItems;
}

export interface ProcessingTotalItems {
    type1: number[];
    type2: number[];
    type3: number[];
}

export interface ProcessingMyItems {
    type3: Record<string, any>[];
    type2: Record<string, any>[];
    type1: Record<string, any>[];
    complete?: Record<string, any>[];
}

export interface ProcessingList {
    origin: Record<any, any>[];
    totalItems: ProcessingTotalItems;
    myItems: ProcessingMyItems;
}
