import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import { useSelector } from 'react-redux';
import { RootState } from '@/redux/connectors.redux';

import Splide from '@splidejs/splide';

import MintWidget from './MintWidget';

const MintArea: React.FC<any> = (props): JSX.Element => {
    const [splide, setSplide] = useState(null);
    const { breakpoint, mobile } = useSelector((store: RootState) => store.GlobalStatus);
    const {
        id,
        rerenderFlag,
        data = [],
        count = {},
        onMintCallback = (data) => {
            console.log('onWidgetCallabck', data);
        },
        type,
        style,
    } = props;

    useEffect(() => {
        const splideElement = document.querySelector('.splide' + id);

        // 이미지 슬라이더 초기화
        const _splide = new Splide('.splide' + id, {
            type: 'slide',
            // autoWidth: true,
            start: 2,
            perPage: 3,
            perMove: 0,
            autoplay: false,
            padding: _.size(data) === 1 && type === '' ? { left: '25%', right: '25%' } : 0,
            width: '100%',
            // focus: 'center',
            // trimSpace: 'move',
            //interval: 3000,
            drag: true,
            lazyLoad: 'nearby',
            pagination: true,
            breakpoints: {
                4196: {
                    perPage: 3,
                },
                1000: {
                    perPage: 2,
                },
                800: {
                    perPage: 1,
                },
            },
        });

        let delay = 0;
        _splide.on('resize', () => {
            if (++delay % 5 !== 0) return;
            //_splide.options.perPage = splideElement.clientWidth > 576 ? 3 : 1;
            _splide.refresh();
        });

        _splide.mount();
        setSplide(_splide);
    }, []);

    useEffect(() => {
        if (!splide) return;

        const maxPerPage = _.size(data);

        splide._options.perPage = mobile ? 1 : maxPerPage > 0 && maxPerPage <= 3 ? maxPerPage : 3;
        splide._options.breakpoints = {
            4196: {
                perPage: maxPerPage > 0 && maxPerPage <= 3 ? maxPerPage : 1,
                padding: _.size(data) === 1 && type !== 'auto' ? { left: '25%', right: '25%' } : 0,
            },
            1000: {
                perPage: maxPerPage - 1 > 0 && maxPerPage - 1 <= 3 ? maxPerPage - 1 : 1,
                padding: 0,
            },
            800: {
                perPage: maxPerPage - 2 > 0 && maxPerPage - 2 <= 3 ? maxPerPage - 2 : 1,
                padding: 0,
            },
        };
        splide.refresh();

        // if (ulRef.current !== null) {
        //     if (ulRef.current.children.length <= 3) {
        //         const id = ulRef.current.id.substring(0, 8);
        //         const splideElement = document.getElementById(id);
        //         const arrows = splideElement.children[0].children;
        //
        //         for (let i = 0; i < arrows.length; i++) {
        //             arrows[i].remove();
        //         }
        //     }
        // }
    }, [data, splide]);

    return (
        <div className={'splide' + id}>
            <div className="splide__track" style={style ? style : undefined}>
                <ul className="splide__list">
                    {_.map(data, (el, i) => {
                        if (splide) splide.refresh();
                        if (_.size(count)) el.mtl_mint_count = count[el.mtl_idx].mtl_mint_count;
                        if (type == 'auto') el['countdown'] = el[0].countdown;

                        return (
                            <li
                                key={i}
                                className="splide__slide"
                                // style={mobile ? { width: '100%' } : undefined}
                            >
                                <div className="m-2">
                                    <MintWidget {...el} type={type} onClick={(data) => onMintCallback(data)} />
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default React.memo(MintArea);
