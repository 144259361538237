import { createAction, handleActions, Action } from 'redux-actions';
import { applyPenders } from 'redux-pender';
import _ from 'lodash';

/** ***************** declare  ****************** */
export interface klipQR {
    expiration_time: number;
    request_key: string;
    status: string;
    type?: string;
    device?: string;
    err?: any;
    callback?: { sucCallback(): void; failCallback(): void };
}

// server status === 0, 이용불가
// server status === 1, 이용가능
// server status === 2, 지연가능
declare interface ServerStatus {
    status: number;
    text: string;
}

export declare interface PACKET_LAYER {
    date: number;
    requestId: number;
    type: string;
}

declare interface broadCastData extends PACKET_LAYER {
    data: {
        released: true;
        status: { server_name: string; updatetime: number }[];
        txCount: number;
    };
}

type BREAKPOINT_TYPE = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
/** ***************** inner Function  ****************** */
const _generateBreakpoint = (): BREAKPOINT_TYPE => {
    if (window.innerWidth >= 1400) return 'xxl';
    if (window.innerWidth >= 1200) return 'xl';
    if (window.innerWidth >= 992) return 'lg';
    if (window.innerWidth >= 768) return 'md';
    if (window.innerWidth >= 576) return 'sm';
    return 'xs';
};

/** ***************** LocalStorage Key Names ****************** */

export const keyNameAutoLogin = 'autologin';
export const keyNameLanguage = 'klayLanguage';

/** ***************** ACTIONS ****************** */
const LOAD = 'GLOBAL_STATUS/LOAD';
const SERVER = 'GLOBAL_STATUS/SERVER';

const LANGUAGE = 'GLOBAL_STATUS/LANGUAGE';
const MOBILE = 'GLOBAL_STATUS/MOBILE';
const ORIENTATION = 'GLOBAL_STATUS/ORIENTATION';

const AUTOLOGIN = 'GLOBAL_STATUS/AUTOLOGIN';
const MODAL = 'GLOBAL_STATUS/MODAL';

const BREAKPOINT = 'GLOBAL_STATUS/BREAKPOINT';
const klipQR = 'GLOBAL_STATUS/QR_CANVAS';

/** ***************** ACTION FUNCTIONS ****************** */
export const LoadGLoader = createAction(LOAD, (data: boolean) => data);
export const checkServer = createAction(SERVER, (data: broadCastData) => resultServerStatus(data));

export const setLanguage = createAction(LANGUAGE, (data: string) => data);
export const isMobile = createAction(MOBILE);
export const isOrientation = createAction(ORIENTATION);

export const autologin = createAction(AUTOLOGIN, (data: boolean) => data);
export const setModal = createAction(MODAL, (data: boolean) => data);

export const setKlipQR = createAction(klipQR, (data: klipQR | klipQR[] | null) => data);
export const detectedBreakpoint = createAction(BREAKPOINT);

/**
 *타임아웃 에러로 생각 할 수 있는 시간, 30초동안 서버 시간이 차이나면 서버 상태 불안정
 */
const serverTimeOutValue = 30000;

const getServerStatus = (msg: broadCastData) => {
    const _now = msg?.date;
    const _data = msg?.data.status;
    let severTimeOutFlag = null;

    _.forEach(_data, (el, i) => {
        const _updateTime = el?.updatetime;
        _updateTime + serverTimeOutValue < _now ? (severTimeOutFlag = false) : (severTimeOutFlag = true);
    });
    return severTimeOutFlag;
};

const resultServerStatus = (msg: broadCastData): ServerStatus => {
    const OurServer = getServerStatus(msg);
    const txCount = msg.data.txCount;
    const released = msg.data.released;

    if (!OurServer && !released) return { status: 0, text: 'system down' };
    if (txCount > 1000) return { status: 2, text: 'system confused' };

    return { status: 1, text: 'system all green' };
};

const initialState = {
    Language_browser: navigator.language,
    language: localStorage.getItem(keyNameLanguage) ? localStorage.getItem(keyNameLanguage) : 'en-US',
    server: { status: 1, text: 'system check...' },
    mobile: Boolean(navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i)),
    breakpoint: _generateBreakpoint(),
    orientation: !navigator.maxTouchPoints ? 'desktop' : !window.orientation ? 'portrait' : 'landscape',
    autoconn: localStorage.getItem(keyNameAutoLogin) === '1' ? true : false,
    klipQR: null,
    gLoading: false,
};

const reducer = handleActions(
    {
        [SERVER]: (state, action: Action<any>) => {
            return {
                ...state,
                server: action.payload,
            };
        },
        [LANGUAGE]: (state, action) => {
            let _language = null;
            if (action?.payload === undefined || action?.payload === null) {
                _language = localStorage.getItem(keyNameLanguage) ? localStorage.getItem('klayLenguage') : 'en-US';
            } else {
                localStorage.setItem(keyNameLanguage, action.payload);
                _language = action.payload;
            }

            return {
                ...state,
                language: action.payload,
            };
        },

        [MOBILE]: (state) => ({
            ...state,
            mobile: Boolean(
                navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i),
            ),
        }),

        [ORIENTATION]: (state) => ({
            ...state,
            orientation: !navigator.maxTouchPoints ? 'desktop' : !window.orientation ? 'portrait' : 'landscape',
        }),

        [AUTOLOGIN]: (state, action) => {
            let _autoconn = false;
            if (action?.payload === undefined || action?.payload === null) {
                _autoconn = localStorage.getItem(keyNameAutoLogin) === '1' ? true : false;
            } else {
                localStorage.setItem(keyNameAutoLogin, action.payload ? '1' : '0');
                _autoconn = action.payload;
            }

            return {
                ...state,
                autoconn: _autoconn,
            };
        },

        [MODAL]: (state, action) => ({
            ...state,
            modal: action.payload,
        }),

        [BREAKPOINT]: (state, action) => {
            return {
                ...state,
                breakpoint: _generateBreakpoint(),
            };
        },

        [klipQR]: (state, action) => ({
            ...state,
            klipQR: action.payload,
        }),
        [LOAD]: (state, action: Action<any>) => {
            return {
                ...state,
                gLoading: action.payload,
            };
        },
    },
    initialState,
);

export default applyPenders(reducer, [
    // {
    //     type: SERVER,
    //     onPending: (state, action) => {
    //         return state;
    //     },
    //     onSuccess: (state, action) => {
    //         let returnStatus = true;
    //         for (let i = 0; i < action.payload.length; i++) {
    //             console.log(action.payload[i].status);
    //             if (!action.payload[i].status) returnStatus = false;
    //         }
    //
    //         return {
    //             ...state,
    //             server: { status: returnStatus, text: 'system check...' },
    //         };
    //     },
    //     onFailure: (state, action) => {
    //         console.log('onFailure', action.payload);
    //         return {
    //             ...state,
    //             server: null,
    //         };
    //     },
    // },
]);
