import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import css from '@/pages/_layouts/_layout.module.scss';
import iconKlip from '@/_statics/images/icon_klip.svg';
import Timer from '@/pages/_layouts/components/Timer';
import iconKakao from '@/_statics/images/KakaoTalk_icon_256.png';
import iconRG from '@/_statics/images/KakaoTalk_rg_256.png';
import iconScan from '@/_statics/images/KakaoTalk_scan_256.png';
import { useLanguages } from '@/hooks/useLanguages.hook';
import { useDispatch } from 'react-redux';
import QRCode from 'qrcode';
import { setKlipQR } from '@/redux/reducers/GlobalStatus.reducer';
import { completeTransfer, getReceiptByTxHash } from '@/helpers/klaymint.api';
import { getResult } from 'klip-sdk';

const KlipModal = ({ klipQR }) => {
    const Lang = useLanguages();
    const dispatch = useDispatch();

    /**
     * QR canvas ref & state
     **/
    const canvasRef = useRef(null);
    const [expireKlipTime, setExpireKlipTime] = useState(600);

    const closeModal = () => {
        dispatch(setKlipQR(null));
    };

    /**
     * global state 인 klipQR의 타입에 따라 출력메세지를 return 시켜주는 함수
     **/
    const qrType = (klipQR) => {
        switch (klipQR.type) {
            case 'login':
                return {
                    title: null,
                };
                break;
            case 'sell':
                return {
                    title: Lang.sell_klip_title,
                };
                break;
            case 'kip17Approve':
                return {
                    title: Lang.approved_klip_title,
                };
                break;
            case 'sellCancel':
                return {
                    title: Lang.sell_cancel_klip_title,
                };
                break;
            case 'buy':
                return {
                    title: Lang.buy_klip_title,
                };
                break;
            case 'mint':
                return {
                    title: Lang.mint_klip_title,
                };
                break;
            case 'transfer':
                return {
                    title: Lang.transfer_button,
                };
                break;
            case 'kip7Approve':
                return {
                    title: Lang.approved_klip_title,
                };
                break;
            case 'kip17Approve':
                return {
                    title: Lang.approved_klip_title,
                };
                break;
            case 'enforce':
                return {
                    title: Lang.enforce_perPlus_klip_modal_title,
                };
                break;
            case 'staking':
                return {
                    title: Lang.staking_tx,
                };
                break;
            case 'unStaking':
                return {
                    title: Lang.unStaking_tx,
                };
            default:
                return {
                    title: '',
                };
                break;
        }
    };

    /**
     * global state 의 klipQR이 변경된다면 QR 생성을 위한 useEffect
     **/
    useEffect(() => {
        if (klipQR && canvasRef.current !== null) {
            setExpireKlipTime(Math.floor((klipQR.expiration_time * 1000 - new Date().getTime()) / 1000));

            QRCode.toCanvas(
                canvasRef.current,
                `https://klipwallet.com/?target=/a2a?request_key=${klipQR.request_key}`,
                (error) => {
                    const closeQR = () => {
                        clearInterval(polling);
                        closeModal(); // 모달 닫기
                        canvasRef.current = null;
                    };

                    if (error) {
                        closeQR();
                        klipQR.callback.failCallback();
                        console.error(error);
                    }

                    const polling = setInterval(async () => {
                        try {
                            const data = await getResult(klipQR.request_key);

                            if (data.status === 'completed') {
                                if (data.result.status === 'fail') {
                                    closeQR();
                                    return klipQR.callback.failCallback();
                                }

                                closeQR();
                                if (klipQR.type === 'login') klipQR.callback.sucCallback(data.result);
                                else if (klipQR.type === 'staking' || klipQR.type === 'unStaking' || klipQR.type === 'swap') {
                                    const receipt = await getReceiptByTxHash(data.result.tx_hash);

                                    const requireData = {
                                        transactionHash: data.result.tx_hash,
                                        blockNumber: +receipt.data.blockNumber,
                                        status: data.result.status === 'success' ? true : false,
                                    };

                                    klipQR.callback.sucCallback(requireData);
                                } else {
                                    klipQR.callback.sucCallback();
                                }
                            }

                            if (data.status === 'canceled') {
                                closeQR();
                                klipQR.callback.failCallback();
                            }
                        } catch (e) {
                            closeQR();
                            klipQR.callback.failCallback();
                            console.error(e);
                        }
                    }, 1000);
                },
            );
        }
    }, [klipQR]);

    return (
        <div className={css.klipHeader}>
            <div className={cx('modal-dialog modal-dialog-centered')}>
                <div className={cx('walletModalSection', 'modal-content')}>
                    <div className={cx('modal-body', css.modalKlipBodyContainer)}>
                        <div className="d-flex justify-content-end">
                            <button onClick={closeModal} type="button" className="btn-close"></button>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-12 fw-bold ">
                                <img className="mx-2" width={45} src={iconKlip} /> {Lang.header_modal_klip_title}
                            </div>

                            <div className="d-flex justify-content-center bd-highlight my-2">
                                <div className={cx('p-2 bd-highlight', css.klipCanvasContainer)}>
                                    <span>{qrType(klipQR).title}</span>
                                    <canvas ref={canvasRef} />
                                    <Timer className="mt-1" time={expireKlipTime} />
                                </div>
                            </div>
                            <div className={cx('col-12 fw-lighter', css.klipConnDescription)}>
                                {Lang.header_modal_klip_description}
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer justify-content-center p-3">
                        <div>
                            <img className="rounded-circle" width={32} src={iconKakao} />
                            <p className={cx('mt-2', css.smallFontSize)}>{Lang.header_modal_klip_footer_desc1}</p>
                        </div>
                        <div className="mx-3">
                            <i className="fas fa-arrow-right" />
                        </div>
                        <div>
                            <img className="rounded-circle" width={32} src={iconRG} />
                            <p className={cx('mt-2', css.smallFontSize)}>{Lang.header_modal_klip_footer_desc2}</p>
                        </div>
                        <div className="mx-3">
                            <i className="fas fa-arrow-right" />
                        </div>
                        <div>
                            <img className="rounded-circle" width={32} src={iconScan} />
                            <p className={cx('mt-2', css.smallFontSize)}>{Lang.header_modal_klip_footer_desc3}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default KlipModal;
