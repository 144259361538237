export default {
    alert_please_excuting_mobile: 'Please run on Mobile.',

    word_expiration_time: 'Expiry time',

    /**
     * 시스템 점검 관련 메세지
     */
    maintenance_system_check: 'System Maintenance',
    maintenance_upgrade_warning: 'Warning!',
    maintenance_upgrade_check: 'Klaymint is collecting energy to add a new feature.',
    maintenance_upgrade_full_charge_is_1hour: 'About an hour is needed to collect energy',

    /****************************************************************************,
     * 화면 우측 하단의 Toast alert (메세지 알림 창) KLIP 연결 및 상태 업데이트 알람,
     */
    approved_klip_title: 'Approval Transaction',
    sell_klip_title: 'Sales Registration Transaction',
    sell_cancel_klip_title: 'Sales Cancellation',
    buy_klip_title: 'Purchase Transaction',
    mint_klip_title: 'Minting Transaction',

    // Toast Success message,
    suc_msg_sucs_sell_approved: 'Sales Registration has been approved.\nIt leads to sales registration.',
    suc_msg_sucs_sell: 'Sales Registration completed',
    suc_msg_sucs_sellCancel: 'Sales Cancellation completed',
    suc_msg_sucs_buy: 'Purchase completed',
    suc_msg_sucs_mint: 'Minting completed',

    // Toast Error message,
    err_msg_sucs_sell_approved: 'Failed to approve the Sales Registration.\nPlease try again.',
    err_msg_sucs_sell: 'Failed to register for Sales.\nPlease try again.',
    err_msg_sucs_sellCancel: 'Failed to cancel Sales.\nPlease try again.',
    err_msg_sucs_buy: 'Purchase failed.\nPlease try again.',
    err_msg_sucs_mint: 'Minting failed.\nPlease try again.',

    /**
     * 지갑 연결 시도 후 성공 메세지,
     * Toast Success message,
     */
    suc_msg_sucs_connect_kaikas: 'Connected to Kaikas',
    suc_msg_sucs_connect_klip: 'Connected to Klip',

    err_msg_address_check_kaikas: 'Please check the wallet address that is connected to Kaikas.',

    /**
     * 마이페이지의 Listed nft 카드 선택후 가격 입력시 메세지,
     */
    err_msg_fail_price_too_big: 'Number is too large.',
    err_msg_fail_price_not_integers: 'Up to two decimal places can be input.',
    err_msg_fail_price_empty: 'Please input Klay.',
    err_msg_fail_price_not_valid: 'Invalid number',

    err_msg_fail_not_token_owner: 'Not a token holder.',
    err_msg_fail_not_token_owner_factory: 'These tokens are not for sale',
    err_msg_fail_connect_wallet: 'The wallet is not connected.\nPlease reconnect your wallet.',
    err_msg_fail_connect_kaikas: 'Failed to connect to Kaikas Wallet\nPlease try again.',
    err_msg_fail_connect_klip: 'Failed to connect to Klip Wallet\nPlease try again.',
    err_msg_need_kaikas: 'Kaikas extension is required.',
    err_msg_access_denaid: 'Access denied. Please check the status of your wallet.',
    err_msg_fail_request: 'Unable to load data.\nPlease try again later.',
    err_msg_fail_check_extension: 'Unable to load data.\nPlease check the Kaikas extension.',
    err_msg_fail_do_not_test_network: 'It is not available on the Test Network.',

    /**
     * Klip 연결 만료 Toast 메세지,
     */
    inf_msg_expires_in_one_minute: 'Will be disconnected in one minute.',
    inf_msg_expires_in_disconnect: 'Disconnected to Klip',
    /**
     * 메인 메뉴 우측 상단 지갑주소 클릭시 나오는 메뉴,
     */
    inf_msg_copy_to_clipboard: 'Copied.',
    /**
     * 메인 상단 중앙의 메뉴 이름,
     */
    header_menu0: 'Home',
    header_menu1: 'Collection',
    header_menu2: 'Dashboard',
    header_menu3: 'Helper',
    header_menu4: 'Profile',
    header_menu5: 'Per Plus',
    header_logout: 'Logout',
    /**
     * 메인 우측 상단 내 메뉴 클릭시 나오는 박스 메뉴,
     */
    header_my_menu: 'My Menu',
    header_collections: 'Collection',
    header_my_page: 'My Page',
    header_conn_wallet: 'Connect',
    header_disconn_wallet_Questions: 'Do you want to disconnect?',
    header_disconn_wallet: 'Disconnect',
    /**
     * 메인 우측 상단 연결하기 클릭시 나오는 모달 윈도우 내용,
     */
    header_modal_conn_title: 'Starting Klayswap',
    header_modal_conn_kaikas: 'Connect to Kaikas',
    header_modal_separator: 'OR',
    header_modal_conn_desciption: 'Start with KakaoTalk easily and safely',
    header_modal_conn_klip: 'Connect Klip with KakaoTalk',
    header_modal_conn_klip_notice: 'Introduce to Kakao Digital Wallet "Klip"',
    header_modal_auto_conn_wallet: 'Auto-Connect',

    header_modal_loading_title: 'Waiting to be connected at Kaikas Wallet',
    header_modal_loading_description: 'Waiting to be connected at Kaikas Wallet',

    header_modal_klip_title: 'Connect to Klip QR via KakaoTalk',
    header_modal_klip_description: 'Proceed to QR code scanning via KakaoTalk App.',
    header_modal_klip_footer_desc1: 'Activate KakaoTalk',
    header_modal_klip_footer_desc2: 'Touch Search at the top',
    header_modal_klip_footer_desc3: 'Code Scanning',
    /**
     * 메인 페이지 내용 중 단위별 타이틀,
     */
    main_box_title_minting_now: 'Minting',
    main_box_title_coming_soon: 'COMINGSOON',
    main_box_title_next: 'Upcoming NFT series',
    main_box_title_market: 'Trading Center',
    main_box_title_qna: 'Q & A',
    main_box_title_game_items: 'Game Items',
    main_box_title_minting_create: 'Creater',
    main_box_title_minigame: 'Mini Game',

    /**
     * 이용 약관,
     */
    footer_term_of_use: 'Term of Use',
    footer_supports: 'Support',

    /**
     * 404 Not Found Page
     */
    not_found_404_title: '404 Not Found :(',
    not_found_404_subscript: 'Page not found.',
    not_found_404_description1: 'The page does not exist or the page is unavailable.',
    not_found_404_description2: 'Please double-check the entered address. ',
    not_found_404_button_text: 'Go Home',

    /**
     * 콜렉션 리스트
     */
    list_no_collections: 'There is no Collection.',

    /**
     * 콜렉션 페이지 리스트 안내 문구,
     */
    list_no_my_items: 'Items are not available.',
    //검색시 가져올 리스트가 없을때.
    list_no_sales: 'Sale items are not available.',
    list_more_item: 'More Items...',
    //더이상 가져올 리스트가 없을 때.
    list_end_response_lock: 'Sale items are not available.\n Pull to Up.',

    /**
     * 도움말 페이지의 질문 & 답변,
     */
    help_cent_block_1_title: 'KlayMint ?',
    help_cent_question_1_1: '1. What is Klaymint?',
    help_cent_answer_1_1:
        'Klaymint is a platform providing accessibility for everyone to simply access the new value and market starting with NFT and can easily participate in an exciting and valuable ecosystem through NFT.\n\nKlaymint provides specialized services to numerous projects that seek to start services via the Klaytn blockchain.\nKlaymint creates a platform environment to acquire a successful project easily and effectively from the launching of the project to the transaction stage. ',

    help_cent_question_1_2: '2. How to use Klaymint?',
    help_cent_answer_1_2: `Klaymint can be used by anyone who wants to issue NFTs such as projects/artists/individuals. (At the Beta and Phase 1 stages, all NFTs can be launched via the application form.)
    After connecting to the 'Kaikas Wallet' and 'Klip Wallet', multiple services such as minting participation, transaction support services, NFT mixing, and stacking service(Per Plus) can be used. `,

    help_cent_question_1_3: '3. How do I get started on KlayMint?',
    help_cent_answer_1_3:
        "To use Klaymint, it is mandatory to create either 'Kaikas wallet' or 'Klip wallet'.\n\n1) Kaikas : ",
    help_cent_answer_1_3_1: 'https://chrome.google.com/webstore/detail/kaikas/jblndlipeogpafnldhgmapagcccfchpi',
    help_cent_answer_1_3_2: '\n2) Klip : ',
    help_cent_answer_1_3_3: 'https://klipwallet.com/',
    help_cent_answer_1_3_4: '\n\nTo use the services,connecting your wallet account to Klaymint is required.',

    help_cent_question_1_4: '4. How do I sell NFTs on KlayMint (listing)?',
    help_cent_answer_1_4:
        "1) Click the 'Connect' button in the upper right corner.\n\n2) Select a wallet you can connect to. (Kaikas or Klip)\n\n3) Go to 'My Page' from 'My Menu' in the upper right corner.\n\n4) Select 'NFT Card SELL' in 'UNLISTED'.\n\n5) Write down the price you want to sell and select 'SELL'",

    help_cent_question_1_5: '5. How do I cancel the sale transaction on KlayMint?',
    help_cent_answer_1_5:
        "1) Go to 'My Page' from 'My Menu' in the upper right corner.\n\n2) Go to 'ON SALE'.\n\n3) Select the NFT card that has been sold and click 'SELL CANCEL'.",

    help_cent_question_1_6: '6. How do I publish my collection on Klaymint?',
    help_cent_answer_1_6:
        'Any project/artist/writer/anyone who wants to publish NFT through KlayMint can proceed with listing inquiries through ‘Listing Contact’ at the bottom of the homepage. \n\n For further information, please contact klaytn@klaymint.io.  ',
    help_cent_answer_1_6_1:
        'https://docs.google.com/forms/d/e/1FAIpQLSckNvWTn_cOFtMpOQfE_BSrM9C1otZCUDIuOww_DEpSsveBsA/viewform',

    help_cent_question_1_7: '7. How does KlayMint generate revenue when issuing NFTs?',
    help_cent_answer_1_7:
        'All minting sales from KlayMint will belong to the project except for a certain fee. \n\n A fee of 1% of KlayMint and 0~10%(configurable) of the project fee will be paid through the secondary transaction of the minted NFT,',

    help_cent_question_1_8: '8.Can I use the staking service normally?',
    help_cent_answer_1_8: ['Click', 'here', 'to send a transaction, then use staking.'],

    help_cancel_center_check: "Can't check your NFT token on 'ON SALE'?",
    help_cancel_center: ' - Forced cancellation of sales from the sales list by KlayMint',

    /**
     * 이용 약관,
     */
    term_of_use: 'Term of Use',
    term_of_use_title: 'KlayMint',

    validate_msg_required: 'Required. ',
    validate_msg_num_char_or_less: 'characters maximum.',
    validate_msg_more_then_num_char: 'characters minimum.',

    modal_check_mint_title: 'Request a Transaction',
    modal_check_mint_text_hold: 'Balance',
    modal_check_mint_mint_now: 'Now Mint',
    modal_check_mint_pending_transactions: 'There is already a transaction in progress. ',
    modal_check_mint_lack_to_klay: 'Lack of Klay',

    modal_check_text_approved: 'Approved',
    modal_check_need_data_check_a_approved: 'Confirmation of approval status is required.',
    modal_check_sell_item_need_after_approved: 'After the approval status is confirmed, the sale is possible.',
    modal_check_little_fee: 'Fee',

    /**
     * 프로젝트 등록 (collection 리스트 위)
     */
    collections_register_btn_title: 'Transactions of NFTs issued by the KLAYTN chain are available.',
    collections_register_btn_subtitle: 'It is available to register the project by clicking here.',

    register_kip17_pfp_sections_title: 'The PFP-NFT project based on the KIP-17 standard.',
    register_kip17_pfp_sections_description_title: 'Condition',
    register_kip17_pfp_sections_description1:
        '1. The registrant for Transaction Support must be one of the Minters and inputting the contract that issued the NFT is a must.',
    register_kip17_pfp_sections_description2:
        '2. The registered contract will support for trading as soon as possible after the verification.',
    register_kip17_pfp_sections_input_placeholder: 'Input the Contract Address',
    register_kip17_pfp_sections_btn_name: 'Apply for Registration',

    suc_cfm_register_question: 'Would you like to Register?',

    suc_msg_register_contract_address: 'The contract has been successfully registered.',
    err_msg_invalid_contract_address: 'Invalid contract address',
    err_msg_duplicate_contract_address: 'Contract registration failed.',

    /**
     * my page
     */
    suc_msg_sucs_changed_nickname: 'Nickname changed.',
    err_msg_faild_changed_nickname: 'Failed to change nickname. Please try again.',
    noti_msg_duplication_nickname: 'Nickname already exists. ',

    /**
     * swap 관련 msg,
     */
    suc_msg_per_swap: 'Swap completed.',
    err_msg_per_swap: 'Swap failed.',

    err_msg_kilp_swap_denied: 'Swap services are not available for Klip users.',
    /**
     * MegaPhone에서 출력되는 문자
     */
    mega_msg_reinforce_success:
        'Wow! Now {nickname} has successfully mixed the <a className={rateCSS}>{rating}</a> class!',

    /**
     * 합성페이지 관련
     */
    enforce: 'Mix',
    enforce_main_content_title: 'Mix 2 NFTs!',
    enforce_main_content_desc: 'Able to get higher class NFTs.',
    enforce_main_content_choice_main_card: 'Select a card to mix',
    enforce_main_content_choice_sub_card: 'Select a Material card',
    enforce_main_content_main_card: 'Cards to mix',
    enforce_main_content_sub_card: 'Material card',
    enforce_main_content_main: ' To mix',
    enforce_main_content_item_amount: 'Number of items',
    enforce_main_content_sub: 'Material ',
    enforce_main_content_enforce_percentage: 'Success Rate',
    enforce_main_content_enforce_item_Betting: 'Use of Items',
    enforce_main_content_enforce_item_Betting_txt: 'Use an item to increase \n the success rate of mix!',
    enforce_main_content_enforce_item_etc_txt: 'Press the item to unselect.',
    enforce_main_content_enforce_info: 'Mix Info',
    enforce_main_content_enforce_class_scenario: 'Class Scenario',
    enforce_main_content_enforce_choice_card: 'Please select two cards.',

    enforce_main_content_enforce_percentage_desc: [
        'The material card can only be proceeded by either the same as or lower grade than the mix card.',
        'The mix probability for each level is as follows',
    ],
    enforce_main_content_start_enforce_title: 'Mixing has started!',
    enforce_main_content_start_enforce_title_fail: 'enforce attempt failed! Please try again in a few minutes.!',
    enforce_main_content_start_enforce_desc: [
        'Results can be viewed on the dashboard.',
        'As mixing completes, it will be notified at the dashboard.',
    ],
    enforce_main_content_return: 'Back to Home',
    enforce_main_content_result_fail_title: '😓 Mix Failed!',

    enforce_main_content_result_fail_desc: [
        "Don't be sad!",
        'Here are some items that will help to increase the success rate.',
    ],
    enforce_main_content_result_fail_item_desc: ['💎 Gained ', 'items!'],
    enforce_main_content_result_suc_title: '✨ Mix Success! 🎉',
    enforce_main_content_result_suc_desc: [
        'The mix card and material card has successfully mixed',
        'The used cards were burned during the mixing process',
    ],

    enforce_perPlus_modal_transaction: 'Transaction Request',
    enforce_perPlus_modal_transaction_confirmation: 'If you have checked all the contents, would you like to proceed?',
    enforce_perPlus_modal_kip7_approve_desc:
        'Approve transaction is held only for the first time to allow the contract access to the above token.',
    enforce_perPlus_modal_kip17_approve_desc:
        'Approve transaction is held only for the first time regarding the delegation of authority for the KIP37 token transfer that is needed for a mix.',
    enforce_perPlus_modal_kip37_approve_desc:
        'Approve transaction is held only for the first time regarding the delegation of authority for the KIP37 token transfer, which is necessary to increase the success rate of mixing.',
    enforce_perPlus_modal_approve_next_desc:
        'Requesting for the next transaction is available only after a successful transaction',
    enforce_perPlus_klip_modal_title: 'Enforce Transaction',
    enforce_perPlus_modal_approve_suc: 'Leading to the next transaction.',
    enforce_perPlus_modal_approve_fail: 'Transaction failed. ',

    enforce_perPlus_modal_expected_result: 'Expected Results',
    enforce_perPlus_modal_have_per: 'Holding',

    enforce_inventory_choice_button: 'Select',
    enforce_inventory_cancel_button: 'Cancel',
    enforce_inventory_sort_list_1: 'Class in ascending order',
    enforce_inventory_sort_list_2: 'Class in descending order',
    enforce_inventory_sort_list: ['Class in ascending order', 'Class in descending order'],
    enforce_inventory_searchBox_placeholder: 'Input your NFT ID.',
    enforce_inventory_myItems: 'My items',

    enforce_dashboard_estimated_time: 'About 1 min and 30 sec',
    enforce_dashboard_estimated_time_desc: 'Estimated Time Required ',

    enforce_network_delay: 'Klaytn network block synchronization is delayed.\n' + 'Please try again later.',
    enforce_request_fail: 'Mixing request failed.\n please try again.',

    enforce_Inventory: 'Inventory',
    enforce_Dashboard: 'Dashboard',

    mining_Power_grade: 'You can check the Mining Power by grade.',

    mining_power_th1: 'Grade',
    mining_power_th2: 'Mining Power',
    mining_power_th3: 'Number of Staking \n Available',
    mining_power_th4: 'Date',
    mining_power_th5: 'Status',

    mining_power_th2_m: 'Power',
    mining_power_th3_m: 'Number of StakingAvailable',

    suc_klip_msg: 'Transaction complete. ',
    fail_klip_msg: 'Transaction failed. Please try again later. ',

    mobile_swap_alert: 'This feature is not available for mobile users.',

    enforce_cannot_be_used: 'This card cannot be used.',
    enforce_expected_mining_power: 'Expected Mining Amount',
    /**
     * 스왑 페이지 관련
     */

    swap: 'swap',
    cancel_msg_per_swap: 'Canceled.',
    swap_header_h1: 'Swap directly to the asset you desired. ',
    swap_header_h3: ['This feature is same as the feature given by the ', 'site. Refer to the guide.'],
    swap_hold: 'Hold',
    swap_input_placeholder: 'Please input quantity',
    swap_exchange_rate: 'Exchange Rate',
    swap_expected_tarnce_value: 'Estimated Transaction Quantity',
    swap_fee: 'Commission',
    swap_transaction_history: 'Transaction History',
    err_msg_check_per_balance: 'Please check the amount of PER you have and input it again.',
    err_msg_check_klay_balance: 'Please check the amount of KLAY you have and input it again.',

    /* 소각 페이지 관련 */
    Status: 'Status',
    Status_h1: 'Displays the status of Burned Tokens.',
    Status_h3: 'Check the latest statuses for PER tokens.',
    Status_burn_per: 'Currently burned PER',
    Status_circulation_per: 'Current amount of PER in circulation',

    /* 스테이킹 페이지 관련 */
    Staking: 'Staking',
    Staking_h1: 'Staking  Accumulate Reward',
    Staking_h3: 'Deposit PerFriends and get rewards!',
    Staking_Klay_total_quantity: 'Total KLAY Quantity',
    Staking_PER_total_quantity: 'Total PER Quantity',

    imposableStaking: 'Non-staking NFTs',
    // Staking_over_count: 'You can stake up to 5 cards',
    Staking_over_count: ['You can stake up to ', 'cards'],
    // Staking_over_count: ['Staking is a maximum of ', ''],
    Staking_multi_count_over: ['Staking is a maximum of ', 'at one time'],

    staking_tx: 'Staking Transaction',
    staking_tx_suc: 'Staking is complete.',
    staking_tx_fail: 'Staking failed.',

    unStaking_tx: 'unStaking Transaction',
    unStaking_tx_suc: 'harvest is complete.',
    unStaking_tx_fail: 'Unstaking failed.',

    //================== staking common word ================================
    staking_common_staking: 'Staking',
    staking_common_unstaking: 'Unstaking',
    staking_common_gohome: 'Back to Staking Home',
    //=======================================================================
    staking_enter_button: 'Go Staking',
    //================== staking Index page =================================
    staking_index_h1: 'Earn rewards with staking',

    staking_tooltip_h6_1: '■ Get rewards',
    staking_tooltip_p_1: 'By staking NFTs, you can receive the mined assets as a reward.',
    staking_tooltip_h6_2: '■ Staking',
    staking_tooltip_p_2:
        'By staking the NFTs you have, you can mine the deposited assets according to your mining power. In case of multiple staking, the mining power will be summed up.',
    staking_tooltip_h6_3: '■ Unstaking',
    staking_tooltip_p_3: 'Unstake the staked NFT card to receive the mined assets.',

    staking_pool_text_klay: 'Total Liquidity KLAY',

    staking_historybox_amount: 'Withdrawable',

    staking_allWithdraw_button: 'Rewards',
    staking_dostaking_button: 'Staking',
    staking_dounstaking_button: 'Unstaking',

    staking_separateItem_miningpower: 'Mining Power',
    staking_separateItem_shareratio: 'Share Ratio',
    staking_separateItem_cumulative: 'Cumulative Withdrawals',

    staking_expected_24h: 'Estimated rewards(24h)',

    staking_plz_login: 'Need to login',

    staking_list_carousel: 'Staking List',
    //=======================================================================

    //================== staking doStaking page =============================
    staking_scenterContent_selectcard: ['Select a NFT', 'that you want to stake.'],
    staking_confirm_header: ['Staking Information | ', '', 'total'],
    unStaking_confirm_header: ['UnStaking Information | ', '', 'total'],
    //=======================================================================

    //================== staking successStaking =============================
    staking_success_h1: 'Completed staking successfully!',
    staking_success_small: 'Please check the transaction history.',
    staking_transactionTable_caption: 'Transaction History',
    staking_transactionTable_thead1: 'Action',
    staking_transactionTable_thead2: 'Hash',
    staking_transactionTable_thead3: 'Status',
    staking_transactionTable_thead4: 'Date',
    //=======================================================================

    //================== staking successUnStaking ===========================
    staking_unstakingsuccess_cardunstaking: 'Completed Unstaking successfully!',
    staking_unstakingsuccess_unstaking: 'Completed Unstaking successfully!',
    staking_unstakingsuccess_small: 'Please check the transaction history.',
    //=======================================================================

    /* 기타 사항 */
    LogOut: 'Logout',
    Exit: 'Exit',
    NickName: 'Edit Fun ID',
    Tooltip_InPossible: 'NFT mixing is unavailable. Please try again later',
    Tooltip_Possible: 'NFT mixing is now available',
    Possible_value: 'Status',
    Possible_Instability: 'Unstable',
    Possible_Stability: 'Stable',
    Possible_Delay: 'Delay',
    CheckCard_Modal_close_btn: 'Close',

    /* 닉네임 변경 모달창 */
    Input_NickName: 'Fun ID',
    Input_Please: 'Enter your Fun ID',
    Input_NickName_Edit: 'Edit',
    Input_Etc_comment: ['2 to 8 characters Korean/English only.', 'only, no symbols can be input.'],

    /**
     * Tutorial
     **/
    tutorial_line: [
        "Nice to meet you! I'm the baby chameleon 'Mel'!\nAre U ready to explore the PER Plus planet with me? " /* 120px */,
        "Can we 'Mix' first? Possible to raise the grade of the card with mixing! 2 cards\n('Mix' and 'Material card') are needed for the mix.",
        'A stylish Popo card is placed in the inventory on the right\nCan we make this Popo card more powerful and cool? ' /* 120px */,
        "Click a card to register it as an mix card.\nLet's register the Popo card.! " /* 120px */,
        "This stylish PoPo card's grade is 'Rare'!\nThe ‘Material Card’ grade must be same as or lower than the ‘Mix Card’! ",
        'Click a card to register it as a Material card.\nCards used as mix materials will be disappeared, so choose them carefully! Then, can we choose the KAON card as a Material card to mix the PoPo card?',
        'The selection is complete, it is able to ‘mix’!\nAh, don’t forget that PER coins are needed for the mix next time! ' /* 120px */,
        'It is possible to experience without a PER coin for this time, especially for you! Hehe! Then, can we mix the Popo card by pressing the ‘Mix’ button? ',
        'Wow you did it! Mix is in progress!\nPopo is fashionable, so it takes a while to change clothes! ' /* 120px */,
        "The mix results are available on the dashboard!\nLet's check the mix results in the dashboard menu! " /* 120px */,
        'Success! The Popo card, which used to be Rare, has been mixed to Unique! Thanks for following me, I had a great time too! Thanks a lot! ',
        "Hey, the time is here! It's time to get ready for the various future events will be held on the PER PLUS planet! " /* 120px */,
        'You can also check swap and burn, so take a look around!\nThere will be new events and features available in the future, please look forward to it! ',
        "If you join the KlayMint channels and PerFriends Discord,\nI'll deliver the various events news the fastest!\nExciting! Please look forward to it! ",
    ],
    tutorial_end: 'Do you really want to finish this hard-created tutorial?',
    tutorial_Tooltop: 'You can re-do the tutorial again',

    /**
     * 네비게이션 메뉴
     * */
    navi_menu_text_arr: ['MIX', 'SWAP', 'STAKING', /*'BURNING',*/ 'PLUS MINTING'],

    add_per_token: 'Add Token',

    add_per_token_suc: 'PER token registration is complete.',
    add_per_token_fail: 'PER token registration failed. Please try again in a few minutes.',

    swap_require_login: 'Login is required in order to Swap.',
    not_not_enough_per: 'Lack of PER.',

    /**
     * myPage 전송기능 관련
     */
    sell_button: 'Sell',
    transfer_button: 'Transfer',

    err_msg_transfer: 'Failed to transfer.\nPlease try again.',
    suc_msg_transfer: 'Transfer complete.',

    placeholder_msg_transfer: "Enter the recipient's wallet address.",
    err_msg_transfer_invalid_address: 'Invalid recipient address.',
    warning_msg_transfer: '*The transfer function may not work in some projects.',
    title_transfer: 'NFT Transfer',
    from_transfer: 'From',
    to_transfer: 'To',

    /**
     * evt modal text
     */
    evt_modal_session: 'Not seen for Today',
    evt_modal_close: 'close',

    /**
     * 플러스 민팅 (auto minting) 페이지
     */
    plusMinting_pubilc_mint: 'Public',
    plusMinting_pubilc_mint_price: 'price',
    plusMinting_owner_mint: 'Owned',
    plusMinting_owner_mint_price: ' NFT',
    plusMinting_remaining_numbers: [' NFTs', 'Remain'],
    plusMinting_check_number_of_nft_owned: 'Please check the number of PerFriends NFTs you owned.',
    plusMinting_modal_enter_quantity: 'Enter the quantity of NFT',
    plusMinting_modal_sale_price: 'Mint',
    plusMinting_modal_header: ['', 'Please select an asset to pay', 'Request a Transaction'],
};
