import Caver from 'caver-js';
import {
    changeKlaytnWallet,
    resetWallet,
    setWallet,
    initKlaytnBalance,
    WALLETS,
} from '@/redux/reducers/Wallet.reducer';
import { autologin } from '@/redux/reducers/GlobalStatus.reducer';
import { KlayMintParamsUtil } from '@/helpers/KlayMint';

class KaikasHelper {
    private caver: Caver;
    private klaytn: any;

    constructor(klaytn) {
        this.caver = new Caver(klaytn);
        this.klaytn = klaytn;
    }

    /**
     * 카이카스가 없는 경우(설치링크로 이동)
     */
    private noKaikas = (util: { Lang: Record<string, any> }) => {
        window?.toast('error', util.Lang.err_msg_need_kaikas);
        window.open('https://chrome.google.com/webstore/detail/kaikas/jblndlipeogpafnldhgmapagcccfchpi');

        console.error('Non-Kaikas browser detected. You should consider trying Kaikas!');
    };

    /**
     * networkChanged Event handler
     * 네트워크 변환 핸들러 /테스트넷일땐 토스트 에러
     * @param input
     * @param util
     */
    public networkChanged = (input: { wallet: WALLETS }, util: KlayMintParamsUtil) => {
        if (!this.klaytn) return this.noKaikas({ Lang: util.Lang });

        this.klaytn.on('networkChanged', async (network) => {
            console.log(network);
            if (network == 1001) {
                window?.toast('alert', util.Lang.err_msg_fail_do_not_test_network);
                // return;
            }

            util.dispatch(
                changeKlaytnWallet({
                    network: network,
                }),
            );
            util.dispatch(initKlaytnBalance());
        });
    };

    /**
     * isUnlocked check
     * 카이스카스지갑을 잠금했을때
     * @param input
     * @param util
     */
    public isUnlocked = (input: { wallet: WALLETS }, util: KlayMintParamsUtil) => {
        if (!this.klaytn) return this.noKaikas({ Lang: util.Lang });

        this.klaytn._kaikas
            .isUnlocked()
            .then((res) => (!res ? util.dispatch(resetWallet()) : null))
            .catch(console.log);
    };

    /**
     * 카이카스 지갑 계정이 변경됐을때 wallet adrress를 dispatch 후 balance초기화
     * @param input
     * @param util
     */
    public accountsChanged = (input: { wallet: WALLETS }, util: KlayMintParamsUtil) => {
        if (!this.klaytn) return this.noKaikas({ Lang: util.Lang });

        this.klaytn.on('accountsChanged', async (account) => {
            util.dispatch(
                changeKlaytnWallet({
                    address: account[0],
                }),
            );
            util.dispatch(initKlaytnBalance());
        });
    };
    /**
     * 카이카스 로그인 핸들러 klaytn이 없으면 noKaikas 함수
     * klaytn을 enable해주고 setWallet에 정보를 저장
     * @param input
     * @param util
     */
    public login = async (input: { wallet: WALLETS }, util: KlayMintParamsUtil) => {
        if (!this.klaytn) return this.noKaikas({ Lang: util.Lang });

        try {
            await this.klaytn.enable();

            await util.dispatch(
                setWallet({
                    isConn: await this.klaytn._kaikas.isUnlocked(),
                    type: 'kaikas',
                    info: {
                        ...input.wallet.info,
                        name: 'My Wallet',
                        address: this.klaytn.selectedAddress,
                        //balance: klayBalance,
                        network: this.klaytn.networkVersion,
                        expire: null,
                    },
                }),
            );

            await util.dispatch(initKlaytnBalance());

            util.dispatch(autologin(true));
            sessionStorage.removeItem('klmin_lt');
            window.toast('success', util.Lang.suc_msg_sucs_connect_kaikas);
        } catch (error) {
            console.error(error);
            window?.toast('error', util.Lang.err_msg_fail_connect_kaikas);
        }
    };

    /**
     * kaikas로 transaction을 보내기위한 함수
     * @param input sendTransaction 을 보내기 위한 input Object
     * @param output sendTransaction 을 보낸 후 실행할 functions
     */
    public sendTransaction = async (
        input: {
            toAddress: string;
            fromAddress: string;
            abi: string;
            value?: string;
        },
        output: {
            sucCallback(receipt?): void;
            failCallback(): void;
            saveTxCallback?(txHash): void;
        },
    ): Promise<void> => {
        await this.caver.klay
            .sendTransaction({
                type: 'SMART_CONTRACT_EXECUTION',
                from: input.fromAddress,
                value: input.value,
                to: input.toAddress,
                data: input.abi,
                gas: 3000000,
            })
            .on('transactionHash', (transactionHash) => {
                if (output.saveTxCallback) output.saveTxCallback(transactionHash);
                console.log('txHash', transactionHash);
            })
            .on('receipt', async (receipt) => {
                output.sucCallback(receipt);
                console.log('receipt', receipt);
            })
            .on('error', (error) => {
                output.failCallback();
                console.log('error', error);
            });
    };

    // public signMessage = async (
    //     input: { from: string; message: string },
    //     output: {
    //         sucCallback(): void;
    //         failCallback(): void;
    //         saveTxCallback?(txHash): void;
    //     },
    // ) => {
    //     const signedMessage = await this.caver.klay.sign(input.message, input.from);
    //     console.log(signedMessage, 'd');
    // };
}

export default KaikasHelper;
