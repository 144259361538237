export default {
    alert_please_excuting_mobile: '请在手机上使用',

    word_expiration_time: '截止时间',

    /**
     * 시스템 점검 관련 메세지
     */
    maintenance_system_check: '系统维修中',
    maintenance_upgrade_warning: '注意!',
    maintenance_upgrade_check: '正在收集能量以添加新功能.',
    maintenance_upgrade_full_charge_is_1hour: '需要大概1小时的能量收集时间',

    /****************************************************************************,
     * 화면 우측 하단의 Toast alert (메세지 알림 창) KLIP 연결 및 상태 업데이트 알람,
     */
    approved_klip_title: '交易批准',
    sell_klip_title: '销售登录交易',
    sell_cancel_klip_title: '销售取消',
    buy_klip_title: '购买交易',
    mint_klip_title: '铸造交易',

    // Toast Success message,
    suc_msg_sucs_sell_approved: '销售登录已获批准。\n请继续下一步.',
    suc_msg_sucs_sell: '销售登录完成',
    suc_msg_sucs_sellCancel: '销售取消完成',
    suc_msg_sucs_buy: '购买完成',
    suc_msg_sucs_mint: '铸造完成',

    // Toast Error message,
    err_msg_sucs_sell_approved: '无法批准销售登录.\n请重试.',
    err_msg_sucs_sell: '销售无法注册.\n请重试.',
    err_msg_sucs_sellCancel: '无法取消购买.\n请重试.',
    err_msg_sucs_buy: '购买失败.\n请重试.',
    err_msg_sucs_mint: '铸造失败.\n请重试.',

    /**
     * 지갑 연결 시도 후 성공 메세지,
     * Toast Success message,
     */
    suc_msg_sucs_connect_kaikas: '已连接Kaikas',
    suc_msg_sucs_connect_klip: '已连接Klip',

    err_msg_address_check_kaikas: '请确认连接Kaikas的钱包地址',

    /**
     * 마이페이지의 Listed nft 카드 선택후 가격 입력시 메세지,
     */
    err_msg_fail_price_too_big: '数字太大',
    err_msg_fail_price_not_integers: '最多可输入两位小数.',
    err_msg_fail_price_empty: '请输入Klay.',
    err_msg_fail_price_not_valid: '号码无效',

    err_msg_fail_not_token_owner: '不是代币持有者.',
    err_msg_fail_not_token_owner_factory: '代币不出售',
    err_msg_fail_connect_wallet: '钱包未连接.\n请重新连接',
    err_msg_fail_connect_kaikas: '连接Kaikas钱包失败.\n请重试.',
    err_msg_fail_connect_klip: '连接Klip钱包失败.\n请重试.',
    err_msg_need_kaikas: '需下载Kaikas扩展程序',
    err_msg_access_denaid: '拒绝连接. 请确认钱包状态.',
    err_msg_fail_request: '无法加载数据.\n请稍后再做尝试.',
    err_msg_fail_check_extension: '无法加载数据.\n请检查Kaikas扩展程序.',
    err_msg_fail_do_not_test_network: '无法在测试网络上使用',

    /**
     * Klip 연결 만료 Toast 메세지,
     */
    inf_msg_expires_in_one_minute: '一分钟后将断开连接',
    inf_msg_expires_in_disconnect: '已与Klip断开连接',

    /**
     * 메인 메뉴 우측 상단 지갑주소 클릭시 나오는 메뉴,
     */
    inf_msg_copy_to_clipboard: '已复制',

    /**
     * 메인 상단 중앙의 메뉴 이름
     */
    header_menu0: '首页',
    header_menu1: '收藏',
    header_menu2: '仪表板',
    header_menu3: '帮助',
    header_menu4: '个人中心',
    header_menu5: 'Per Plus',
    header_logout: '登出',
    /**
     * 메인 우측 상단 내 메뉴 클릭시 나오는 박스 메뉴,
     */
    header_my_menu: '我的菜单',

    header_collections: '收藏',
    header_my_page: '我的页面',
    header_conn_wallet: '连接',
    header_disconn_wallet_Questions: '确定断开连接？',
    header_disconn_wallet: '断开连接',

    /**
     * 메인 우측 상단 연결하기 클릭시 나오는 모달 윈도우 내용,
     */
    header_modal_conn_title: '启动Klayswap',
    header_modal_conn_kaikas: '连接Kaikas钱包',
    header_modal_separator: '或',
    header_modal_conn_desciption: '轻松安全地开始使用 KakaoTalk',
    header_modal_conn_klip: '使用KakaoTalk连接Klip',
    header_modal_conn_klip_notice: '介绍Kakao数字钱包“Klip”',
    header_modal_auto_conn_wallet: '自动连接',

    header_modal_loading_title: '等待连接Kaikas钱包',
    header_modal_loading_description: '等待连接Kaikas钱包',

    header_modal_klip_title: '使用KakaoTalk连接Klip QR',
    header_modal_klip_description: '通过KakaoTalk 程序进行二维码扫描.',
    header_modal_klip_footer_desc1: '实行KakaoTalk',
    header_modal_klip_footer_desc2: '点击顶部搜索键',
    header_modal_klip_footer_desc3: '二维码扫描',

    /**
     * 이용 약관,
     */
    footer_term_of_use: '使用条款',
    footer_supports: '客服',

    /**
     * 404 Not Found Page
     */
    not_found_404_title: '404 找不到网页 :(',
    not_found_404_subscript: '无法找到该网页',
    not_found_404_description1: '页面不存在或页面不可用',
    not_found_404_description2: '请再次确认输入的地址',
    not_found_404_button_text: '回首页',

    /**
     * 메인 페이지 내용 중 단위별 타이틀,
     */
    main_box_title_minting_now: '铸造',
    main_box_title_coming_soon: '即将推出',
    main_box_title_next: '即将推出的NFT系列',
    main_box_title_market: '交易所',
    main_box_title_qna: 'Q & A',
    main_box_title_game_items: '游戏物品',
    main_box_title_minting_create: '铸币厂',
    main_box_title_minigame: '迷你游戏',

    /**
     * 콜렉션 리스트
     */
    list_no_collections: '没有收藏',

    /**
     * 콜렉션 페이지 리스트 안내 문구,
     */
    list_no_my_items: '没有该商品',
    //검색시 가져올 리스트가 없을때.
    list_no_sales: '没有出售商品',

    list_more_item: '更多...',
    //더이상 가져올 리스트가 없을 때.
    list_end_response_lock: '没有更多的出售商品',

    /**
     * 도움말 페이지의 질문 & 답변,
     */
    help_cent_block_1_title: 'KlayMint ?',
    help_cent_question_1_1: '1. 什么是KlayMint?',
    help_cent_answer_1_1:
        'KlayMint 是一个提供可访问性的平台，因此任何人都可以通过 NFT 发起新价值和市场，并通过 NFT 轻松参与有趣且有价值的生态系统.\nKlaymint 通过 Klaytn 区块链启动为众多寻求提供专业服务.\nKlayMint 创造了一个从项目启动到交易阶段的轻松有效平台环境.',
    help_cent_question_1_2: '\n2) Klip : ',
    help_cent_answer_1_2:
        '任何想要发行NFT的个人、艺术家等都可以使用KlayMint. (在 Beta 和 Phase 1 阶段，所有 NFT 都可以通过申请表发行 .)连接Kaikas 或 Klip钱包后， 可以享受各种不同的服务，例如：参与铸造，交易服务，NFT 合成, 质押服务（Per Plus) 等.',

    help_cent_question_1_3: '3. 如何开始使用KlayMint?',
    help_cent_answer_1_3: '如要使用KlayMint, 请务必创建Kaikas或Klip钱包.',
    help_cent_answer_1_3_1: 'https://chrome.google.com/webstore/detail/kaikas/jblndlipeogpafnldhgmapagcccfchpi',
    help_cent_answer_1_3_2: '\n2) Klip : ',
    help_cent_answer_1_3_3: 'https://klipwallet.com/',
    help_cent_answer_1_3_4: '\n\n将您的钱包帐户连接到 Klaymint以使用这些服务.',

    help_cent_question_1_4: '4. 如何在KlayMint上售卖NFTs?',
    help_cent_answer_1_4:
        "1) 点击右上角的‘连接’.\n\n2) 选择想连接的钱包(Kaikas或Klip)\n\n3)点击右上角‘我的菜单’裏的‘我的页面’.\n\n4)选择'UNLISTED'里的‘NFT Card SELL'.\n\n5)写下想要卖出的价钱后点击’SELL'",

    help_cent_question_1_5: '5. 如何取消KlayMint上的销售交易？',
    help_cent_answer_1_5:
        "1) 从’我的菜单’里选择‘我的页面’.\n\n2) 点击‘ON SALE'.\n\n3)选择已卖的NFT卡然后点击’SELL CANCEL'.",

    help_cent_question_1_6: '6. 如何在KlayMint上发行我的收藏？',
    help_cent_answer_1_6:
        '任何想通过KlayMint发行NFT的个人/艺术家等可以点击首页底部的上市申请.\n\n 欲知更多详情，可以联系klaytn@klaymint.io.',
    help_cent_answer_1_6_1:
        'https://docs.google.com/forms/d/e/1FAIpQLSckNvWTn_cOFtMpOQfE_BSrM9C1otZCUDIuOww_DEpSsveBsA/viewform',

    help_cent_question_1_7: '7. KlayMint 在发行 NFT 时如何产生收益？',
    help_cent_answer_1_7:
        '除了一定费用外，KlayMint 的所有铸币销售都将属于该项目。 \n\n 1% 的 KlayMint 费用和 0~10%（可配置）的项目费用将通过铸造 NFT 的二级交易支付.',

    help_cent_question_1_8: '8.无法正常使用质押服务？',
    help_cent_answer_1_8: ['按', '这里', '然后发送交易便可以使用质押.'],

    help_cancel_center_check: '无法在“ON SALE”中查看您的 NFT 代币？',
    help_cancel_center: ' - KlayMint 从销售清单中强制取消销售',

    /**
     * 이용 약관,
     */
    term_of_use: '使用条款',
    term_of_use_title: 'KlayMint',

    validate_msg_required: '必须',
    validate_msg_num_char_or_less: '最多可输入（）字',
    validate_msg_more_then_num_char: '最少得输入（）字',

    modal_check_mint_title: '交易请求',
    modal_check_mint_text_hold: '保留',
    modal_check_mint_mint_now: '去铸造',
    modal_check_mint_pending_transactions: '已有交易进行中',
    modal_check_mint_lack_to_klay: 'Klay不足',

    modal_check_text_approved: '承认',
    modal_check_need_data_check_a_approved: '需要确认批准状态.',
    modal_check_sell_item_need_after_approved: '状态批准后才能开始销售',
    modal_check_little_fee: '手续费',

    /**
     * 프로젝트 등록 (collection 리스트 위)
     */
    collections_register_btn_title: '可用KLAYTN 链发行的 NFT进行交易',
    collections_register_btn_subtitle: '点击这里可以注册项目',

    register_kip17_pfp_sections_title: '基于 KIP-17 标准的 PFP-NFT 项目.',
    register_kip17_pfp_sections_description_title: '条件',
    register_kip17_pfp_sections_description1: '1.交易支持的注册人必须是铸造人之一，并且必须输入发行NFT合约.',
    register_kip17_pfp_sections_description2: '2. 注册的合约审核通过后可以在第一时间进行交易。',
    register_kip17_pfp_sections_input_placeholder: '输入合约地址',
    register_kip17_pfp_sections_btn_name: '注册申请',

    suc_cfm_register_question: '确定要注册吗?',

    suc_msg_register_contract_address: '合约注册成功',
    err_msg_invalid_contract_address: '合约地址无效',
    err_msg_duplicate_contract_address: '合约注册失败.',

    /**
     * my page
     */
    suc_msg_sucs_changed_nickname: '昵称已更改',
    err_msg_faild_changed_nickname: '昵称更改失败. 请重新尝试.',
    noti_msg_duplication_nickname: '此昵称已注册',

    /**
     * swap 관련 msg,
     */
    suc_msg_per_swap: 'Swap已完成',
    err_msg_per_swap: 'Swap失败',
    err_msg_kilp_swap_denied: 'Swap服务不适用于Klip用户',
    err_msg_check_per_balance: '请检查所保留的PER数量后并重新输入.',
    err_msg_check_klay_balance: '请检查所保留的KLAY数量后并重新输入.',

    /**
     * MegaPhone에서 출력되는 문자
     */
    mega_msg_reinforce_success: '太棒了！{nickname} 成功地合成<a className="{rateCSS}">{rating}</a>级别了.',

    /**
     * 합성페이지 관련
     */

    enforce: '合成',
    enforce_main_content_title: '请试试将2个NFT合成！',
    enforce_main_content_desc: '以获得更高等级的NFT.',
    enforce_main_content_choice_main_card: '选择一张要进行合成的卡',
    enforce_main_content_choice_sub_card: '选择一张材料卡',
    enforce_main_content_main_card: '合成时需要的卡',
    enforce_main_content_sub_card: '材料卡',
    enforce_main_content_main: '合成目标',
    enforce_main_content_item_amount: '物件数',
    enforce_main_content_sub: '材料',
    enforce_main_content_enforce_percentage: '成功率',
    enforce_main_content_enforce_percentage_m: '合成成功率',
    enforce_main_content_enforce_item_Betting: '物件使用',
    enforce_main_content_enforce_item_Betting_txt: '使用物件 \n以提高合成成功率！',
    enforce_main_content_enforce_item_etc_txt: '点击该项目以取消选择',
    enforce_main_content_enforce_info: '合成情报',
    enforce_main_content_enforce_class_scenario: '情景',
    enforce_main_content_enforce_choice_card: '请选择2张卡',

    enforce_main_content_enforce_percentage_desc: [
        '材料卡只能以与合成卡相同或更低的等级进行.',
        '每个级别的合成概率如下',
    ],
    enforce_main_content_start_enforce_title: '合成已开始！',
    enforce_main_content_start_enforce_title_fail: '合成尝试失败!请稍后再做尝试.',
    enforce_main_content_start_enforce_desc: ['结果可以在仪表板上查看.', '合成完成后, 将在仪表板上收到通知.'],

    enforce_main_content_return: '回到首页',
    enforce_main_content_result_fail_title: '合成失败😓',
    enforce_main_content_result_fail_desc: ['别伤心', '送您一些有助于提高成功率的礼物.'],
    enforce_main_content_result_fail_item_desc: ['💎 获得', '物件！'],
    enforce_main_content_result_suc_title: '✨合成成功 🎉',
    enforce_main_content_result_suc_desc: ['合成卡与材料卡合成成功.', '合成过程中所使用的卡已被销毁.'],

    enforce_perPlus_modal_transaction: '交易申请',
    enforce_perPlus_modal_transaction_confirmation: '若已检查所有内容，是否要继续？',
    enforce_perPlus_modal_kip7_approve_desc: '只在第一次执行一次的批准交易，以允许合约上的代币。',
    enforce_perPlus_modal_kip17_approve_desc: '批准交易仅在合成所需的 KIP17 代币转移授权的第一次执行。',
    enforce_perPlus_modal_kip37_approve_desc:
        '关于KIP37代币转移的授权，仅在首次进行时批准交易，这是提高合成的成功率所必需的.',
    enforce_perPlus_modal_approve_next_desc: '交易成功后才可申请下一笔交易',

    enforce_perPlus_klip_modal_title: '执行交易',
    enforce_perPlus_modal_approve_suc: '进行下一个交易',
    enforce_perPlus_modal_approve_fail: '交易失败',

    enforce_perPlus_modal_expected_result: '预测结果',
    enforce_perPlus_modal_have_per: '保留',

    enforce_inventory_choice_button: '选择',
    enforce_inventory_cancel_button: '取消',
    enforce_inventory_sort_list_1: '低至高等级排序',
    enforce_inventory_sort_list_2: '高至低等级排序',
    enforce_inventory_sort_list: ['低至高等级排序', '高至低等级排序'],
    enforce_inventory_searchBox_placeholder: '请尝试搜索NFT ID',
    enforce_inventory_myItems: '我的物件',

    enforce_dashboard_estimated_time: '约1分30秒',
    enforce_dashboard_estimated_time_desc: '预计所需时间 ',

    enforce_network_delay: 'Klaytn 网络区块同步延迟.\n请稍后再试.',
    enforce_request_fail: '合成请求失败.\n 请稍后再做尝试.',

    enforce_Inventory: '目录',
    enforce_Dashboard: '仪表板',

    mining_Power_grade: '可以查看每个等级的挖采矿力',

    mining_power_th1: '等级',
    mining_power_th2: '采矿力',
    mining_power_th3: '可质押的数量 ',
    mining_power_th4: '日期',
    mining_power_th5: '状态',

    mining_power_th2_m: '采矿力',
    mining_power_th3_m: '수량',

    suc_klip_msg: '交易完毕',
    fail_klip_msg: '交易失败. 请重新尝试.',

    mobile_swap_alert: '此功能不适用于手机用户.',

    enforce_cannot_be_used: '无法使用此卡',
    enforce_expected_mining_power: '预计挖矿量',
    /**
     * 스왑 페이지 관련
     */

    swap: '交换',
    cancel_msg_per_swap: '取消',
    swap_header_h1: '直接交换到您选择的资产',
    swap_header_h3: ['和', '网站提供的功能一致.请参阅指南.'],
    swap_hold: '保留',
    swap_input_placeholder: '请输入数量',
    swap_exchange_rate: '转换率',
    swap_expected_tarnce_value: '预计交易数量',
    swap_fee: '手续费',
    swap_transaction_history: '交易内容',

    /* 소각 페이지 관련 */
    Status: '销毁',
    Status_h1: '显示被销毁的代币状态',
    Status_h3: '请查看PER代币的最新状态.',
    Status_burn_per: '当前已烧毁的PER',
    Status_circulation_per: '当前PER的流通量',

    /* 스테이킹 페이지 관련 */
    Staking: '质押',
    Staking_h1: '质押累计奖励',
    Staking_h3: '存入PerFriends以获得奖励！',
    Staking_Klay_total_quantity: 'KLAY总数量',
    Staking_PER_total_quantity: 'PER总数量',

    imposableStaking: '无法质押的NFTs',
    // Staking_over_count: '스테이킹은 5장까지 할 수 있습니다.',
    Staking_over_count: ['最多能质押', '张卡'],
    // Staking_over_count: ['스테이킹은 ', '장 까지 가능합니다.'],
    Staking_multi_count_over: ['最多可以对 ', '张卡片进行多重抵押/取消抵押.'],

    staking_tx: '质押交易',
    staking_tx_suc: '质押完成',
    staking_tx_fail: '质押未完成.',

    unStaking_tx: '取消质押交易',
    unStaking_tx_suc: '奖励已领取完成.',
    unStaking_tx_fail: '取消质押失败',

    //================== staking common word ================================
    staking_common_staking: '质押',
    staking_common_unstaking: '取消质押',
    staking_common_gohome: '回到质押首页',
    //=======================================================================
    staking_enter_button: '去质押',
    //================== staking Index page =================================
    staking_index_h1: '通过质押获得奖励',

    staking_tooltip_h6_1: '■ 获得奖励',
    staking_tooltip_p_1: '通过质押 NFT，您可以获得正在开采的资产作为补偿.',
    staking_tooltip_h6_2: '■ 质押',
    staking_tooltip_p_2:
        '通过质押持有的 NFT，您可以根据自己的采矿力对存入的资产进行挖矿. 如有多次抵押的情况，采矿力将被综合.',
    staking_tooltip_h6_3: '■ 取消质押',
    staking_tooltip_p_3: '取消已被质押的NFT卡以获得开采的资产.',

    staking_pool_text_klay: '总流动性',

    staking_historybox_amount: '可提款',

    staking_allWithdraw_button: '奖励',
    staking_dostaking_button: '质押',
    staking_dounstaking_button: '取消质押',

    staking_separateItem_miningpower: '采矿力',
    staking_separateItem_shareratio: '持股比例',
    staking_separateItem_cumulative: '累计提款额',

    staking_expected_24h: '预计奖励(24h)',
    staking_plz_login: '请登录.',

    staking_list_carousel: '质押清单',

    //=======================================================================

    //================== staking doStaking page =============================
    staking_scenterContent_selectcard: ['选择想要质押的', 'NFT'],
    staking_confirm_header: ['质押信息 | ', '总', '物件'],
    unStaking_confirm_header: ['取消质押信息 | ', '总', '物件'],
    //=======================================================================

    //================== staking successStaking =============================
    staking_success_h1: '质押完成！',
    staking_success_small: '请在交易记录上查看结果',
    staking_transactionTable_caption: '交易内容',
    staking_transactionTable_thead1: '类别',
    staking_transactionTable_thead2: 'Hash',
    staking_transactionTable_thead3: '状态',
    staking_transactionTable_thead4: 'Date',
    //=======================================================================

    //================== staking successUnStaking ===========================
    staking_unstakingsuccess_cardunstaking: '的取消质押已完毕',
    staking_unstakingsuccess_unstaking: '取消质押已完毕',
    staking_unstakingsuccess_small: '请在交易记录上查看结果',
    //=======================================================================

    /* 기타 사항 */
    LogOut: '登出',
    Exit: '退出',
    NickName: '更换昵称',
    Tooltip_Possible: '现在可以进行NFT合成',
    Tooltip_InPossible: '现在无法进行NFT合成. 请稍后再做尝试.',
    Possible_value: '状态',
    Possible_Instability: '不稳定',
    Possible_Stability: '稳定',
    Possible_Delay: '延迟',
    CheckCard_Modal_close_btn: '关闭',

    /* 更换昵称 모달창 */
    Input_NickName: '昵称',
    Input_Please: '不输入昵称',
    Input_NickName_Edit: '修改',
    Input_Etc_comment: '只能输入2-8韩/英文字, 不能输入符号',

    /**
     * Tutorial
     **/
    tutorial_line: [
        "您好！很高兴见到您. 我是小变色龙 ‘Mel'!准备好和我一起探索PER Plus星球了吗？ " /* 120px */,
        '首先,我们得升级以提升卡牌等级. 升级时需要2张卡（强化与材料卡） ',
        '右侧的物件栏中放置了一张时尚的 PoPo 卡片.\n让我们把这张 PoPo 卡变得更强大更酷吧！ ' /* 120px */,
        '点击一张卡片, 将它注册为强化卡.\n让我们把PoPo卡注册为强化卡吧！ ' /* 120px */,
        "这时尚的PoPo卡等级是‘稀有’！\n'材料卡'必须与'升级卡'的等级相同或低于! ",
        '点击一张卡片, 将它注册为材料卡.\n 用作强化的卡将会消失, 所以请谨慎选择.\n现在, 让我们选择KAON卡作为升级PoPo卡的材料卡吧！ ' /* 120px */,
        '选择完毕. 现在可以进行升级.\n别忘了下次升级的时候需要PER币哦！ ' /* 120px */,
        '这次可以让您免费体验. 😊\n 现在, 点击升级以强化PoPo卡！ ',
        '哇！您成功了！正在升级中.\n让我们给一点时间让时尚达人PoPo换服装. ' /* 120px */,
        '升级结果显示在仪表板上！\n让我们在仪表板里检查升级结果！ ' /* 120px */,
        '成功！原本是稀有的PoPo卡现在已经升级为独特.\n今天真的很开心, 谢谢您关注我. ',
        '好的, 时间到了！\n是时候为即将在 PER PLUS 星球上举行的各种活动做好准备了! ' /* 120px */,
        '您也可以看看Swap和销毁, 请四处看看！\n未来也会有新的活动和功能，请敬请期待! ',
        '如果您加入KlayMint的频道以及PerFriends的Discord,\n我将会给您们带来更多的好消息以及未来会举办的活动. 好期待我们下一次的见面！ ',
    ],
    tutorial_end: '您确定要退出教程吗？',

    tutorial_Tooltop: '您可以再看一遍教程.',

    /**
     * 급작스런 모바일...
     */
    mobile_header_profile_dropdown_nickname_change: '更换昵称',
    /**
     * 네비게이션 메뉴
     * */
    navi_menu_text_arr: ['合成', '交换', '质押', /*'销毁',*/ 'Plus Minting'],

    add_per_token: '登录代币',

    add_per_token_suc: 'PER代币登录成功',
    add_per_token_fail: 'PER代币登录失败。 请稍后再做尝试.',

    swap_require_login: '登录以使用Swap功能',
    not_not_enough_per: 'PER不足',

    /**
     * myPage 전송기능 관련
     */
    sell_button: '售卖',
    transfer_button: '传送',

    err_msg_transfer: '传送失败. \n请重试.',
    suc_msg_transfer: '传送成功',

    placeholder_msg_transfer: '输入收款人的钱包地址',
    err_msg_transfer_invalid_address: '收款人地址无效',
    warning_msg_transfer: '*传送功能在某些项目中起不了作用',
    title_transfer: 'NFT 传送',
    from_transfer: '发件人',
    to_transfer: '收件人',

    /**
     * evt modal text
     */
    evt_modal_session: '今日不再提示',
    evt_modal_close: '关闭',

    /**
     * 플러스 민팅 (auto minting) 페이지
     */
    plusMinting_pubilc_mint: '公开',
    plusMinting_pubilc_mint_price: '售卖价格',
    plusMinting_owner_mint: 'NFT',
    plusMinting_owner_mint_price: '名持有人',
    plusMinting_remaining_numbers: [' 剩余', '个'],
    plusMinting_check_number_of_nft_owned: '请检查拥有的PerFriends NFT 数量',
    plusMinting_modal_enter_quantity: '请输入NFT的数量',
    plusMinting_modal_sale_price: '铸造价',
    plusMinting_modal_header: ['', '请选择用来支付的资产。', '请求交易.'],
};
