import React, { useEffect, useState } from 'react';

import Header from '@/pages/_layouts/components/Header';
import Footer from '@/pages/_layouts/components/Footer';
import RightArea from '@/pages/_layouts/components/RightArea';

import { useSelector } from 'react-redux';
import { RootState } from '@/redux/connectors.redux';
import KlipModal from '@/_components/commons/modals/KlipModal';
import { useLocation } from 'react-router';

const Layout: React.FC<any> = ({ children }): JSX.Element => {
    const location = useLocation();

    const { klipQR } = useSelector((store: RootState) => store.GlobalStatus);
    const [perPlus, setPerPlus] = useState(false);

    useEffect(() => {
        if (location.pathname.toLowerCase().indexOf('/PerPlus'.toLowerCase()) !== -1) setPerPlus(true);
        else if (perPlus) setPerPlus(false);
    }, [children, location]);

    return (
        <>
            <div className="container-fluid min-vh-100 m-0 p-0">
                {!perPlus && <Header />}
                {/* 페이지 해더 */}

                {/* 좌측 메인 메뉴 */}

                {/* 메인 컨텐츠 영역 */}
                {children}

                {klipQR ? <KlipModal klipQR={klipQR} /> : null}

                {/* Fixed Div -- z Index 때문에 콘텐츠보다 아래에 랜더링 */}
            </div>
            {!perPlus && (
                <>
                    <Footer />
                    <RightArea />
                </>
            )}
        </>
    );
};

export default Layout;
