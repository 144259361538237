import { request, getResult } from 'klip-sdk';
import { klipQR, setKlipQR } from '@/redux/reducers/GlobalStatus.reducer';
import { Dispatch } from 'redux';
import { getReceiptByTxHash } from '@/helpers/klaymint.api';

class KlipHelper {
    private device: string;

    constructor() {
        this.device = navigator.userAgent.toLowerCase();
    }

    /**
     * @param input sendTransaction 을 보내기 위한 input Object
     * @param output sendTransaction 을 보낸 후 실행할 functions
     * @param util sendTransaction 을 보내기 위해 필요한 util 함수
     * 1. deepLink 또는 makeQR 실행
     * 2. polling 실행
     */
    public sendTransaction = async (
        prepareSign: klipQR,
        output: { sucCallback(result?): void; failCallback(): void },
        util?: { dispatch?: Dispatch; Lang?: Record<string, any> },
    ) => {
        if (prepareSign.err) return output.failCallback();
        if (prepareSign.request_key) {
            /**
             * tx가 오류없이 전송할 수 있다면 res.request_key 생성,
             * mobile 이라면 deepLink 로 클립 바로가기,
             * pc 라면 QR 생성 (globalState 에 type 및 res 전달 -> _ArticleModal 컴포넌트에서 QR state 가 존재한다면 해당 모달 생성)
             */
            if (this.device.indexOf('android') !== -1 || this.device.indexOf('iphone') !== -1) {
                await this.deepLink(prepareSign, output);
            } else await this.makeQR(prepareSign, output, util);
        }
    };
    /**
     * 모바일 환경에서 로그인을 위해 klip으로 이동하는 deeplink
     * @param prepareSign
     * @param output
     */
    private deepLink = async (prepareSign: klipQR, output: { sucCallback(): void; failCallback(): void }) => {
        try {
            await request(prepareSign.request_key, () => alert('모바일 환경에서 실행해주세요'));
            await this.polling(prepareSign, output);
        } catch (error) {
            console.log(error);
            output.failCallback();
        }
    };

    /**
     * makeQR 메서드는 모달에 QR 생성해야, redux 의 klipQR 값을 바꿔줌으로서
     * ./src/_components/commons/modals/klipModal.tsx
     * 으로 이어져 나머지 polling 과 callback 실행
     * @param prepareSign
     * @param output
     * @param util
     */
    private makeQR = async (
        prepareSign: klipQR,
        output: { sucCallback(result?): void; failCallback(): void },
        util?: { dispatch?: Dispatch; Lang?: Record<string, any> },
    ) => {
        util.dispatch(
            setKlipQR({
                ...prepareSign,
                device: 'pc',
                callback: output,
            }),
        );
    };
    /**
     * deepLink 연결되는 동안 tx가 성공적인 응답을 반환하기 전까지 반복적인 응답결과 조회함수
     * @param prepareSign
     * @param output
     */
    private polling = async (prepareSign: klipQR, output: { sucCallback(result?): void; failCallback(): void }) => {
        const mobilePolling = setInterval(async () => {
            try {
                /**
                 * App2App api 요청에 대한 결과 getResult
                 */
                const data = await getResult(prepareSign.request_key);
                /**
                 * status가 completed이고 signMessage가 err 이거나 result.status가 fail이라면
                 * mobilePolling interval 초기화
                 */
                if (data.status === 'completed') {
                    if (prepareSign.err || data.result.status === 'fail') {
                        console.log(prepareSign.err);
                        clearInterval(mobilePolling);
                        return output.failCallback();
                    }
                    /**
                     * result가 있다면 polling clear
                     */
                    if (data.result) {
                        clearInterval(mobilePolling);
                        /**
                         * sign.type이 "login"이라면 성공콜백
                         * 아니라면 backend req
                         */
                        if (prepareSign.type === 'login') output.sucCallback(data.result);
                        else {
                            const receipt = await getReceiptByTxHash(data.result.tx_hash);

                            const requireData = {
                                transactionHash: data.result.tx_hash,
                                blockNumber: +receipt.data.blockNumber,
                                status: data.result.status === 'success' ? true : false,
                            };

                            output.sucCallback(requireData);
                        }
                    }
                }
                /**
                 * canceled 시 mobilePolling clear
                 * 실패콜백
                 */
                if (data.status === 'canceled') {
                    clearInterval(mobilePolling);
                    output.failCallback();
                }
            } catch (e) {
                output.failCallback();
                console.error(e);
            }
        }, 1000);
    };
}

export default KlipHelper;
