import React, { useState } from 'react';
import css from './_ArticleModal.module.scss';

import { ArticleModalProps } from '@/_components/commons/modals/_ArticleModal.interfaces.declare';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux/connectors.redux';

import iconKaikas from '@/_statics/images/icon_kaikas.svg';

import cx from 'classnames';
import { useLanguages } from '@/hooks/useLanguages.hook';
import KlayMint from '@/helpers/KlayMint';
import { getSalesHistory, postBuyEvent } from '@/helpers/klaymint.api';
import LastPriceHistoryModal from '@/pages/CollectionsDetail/components/Article.DetailModal.LastPriceHistoryModal';
import _SalesHisotryModal from '@/_components/commons/modals/_SalesHisotryModal';
import { capitalize, getBackgroundColor } from '@/helpers/_common';
import { Wizard, StepWizard, remoteWizardOpener } from '@/_components/commons/wizard';
import { numberWithCommas } from '@/helpers/common.helper';
import { numOnlyTwoDecimal } from '@/helpers/validate.helper';
import ShowMoreText from 'react-show-more-text';
import S from '@/_statics/images/PerPlus/s.svg';
import KlayMintUtil from '@/helpers/KlayMint.util';

const _ArticleModal = ({ collection, props, setModal, style, optimisticDelete }: ArticleModalProps): JSX.Element => {
    const dispatch = useDispatch();
    const Lang = useLanguages();

    const wallet = useSelector((store: RootState) => store.Wallet);
    const { list } = useSelector((store: RootState) => store.Collections);
    const { breakpoint } = useSelector((store: RootState) => store.GlobalStatus);
    const mobileSizeEx = ['xs', 'md', 'sm'];

    const klaymint = new KlayMint(collection?.contract_address, collection?.factory_address, list);
    const klaymintUtil = new KlayMintUtil();

    /**
     * sellRequest modal 인 경우 price input state
     **/
    const [price, setPrice] = useState('');

    /**
     * _Article 안에 또 다른 모달을 열어야 하는 경우 state 값들
     **/
    const [childModal, setChildModal] = useState(false);
    const [modalProps, setModalProps] = useState({} as ArticleModalProps);

    /**
     * _Article 안에서 열리는 Wizard tx request modal 중 mintRequest wizard modal 인 경우
     * kaikas notification extension 창 중복 쌓기 금지위한 lock,
     **/
    const [mintLock, setMintLock] = useState(false);

    /**
     * CLASS 에 따라 적용되야할 css return
     **/
    const setGradient = (value) => {
        switch (value) {
            case 'None':
                return css.gradient_nomal;
            case 'Normal':
                return css.gradient_nomal;
            case 'Uncommon':
                return css.gradient_uncommon;
            case 'Rare':
                return css.gradient_rare;
            case 'Unique':
                return css.gradient_unique;
            case 'Legendary':
                return css.gradient_legendary;
            case 'Myth':
                return css.gradient_myth;
            case 'Ancient':
                return css.gradient_ancient;
            default:
                return css.gradient_nomal;
        }
        return css;
    };

    /**
     * _ArticleModal 안에서 열리는 또 다른 modal load
     **/
    const loadArticleModal = async () => {
        const res = await getSalesHistory(collection.brand_name, props.item.data.token_id);
        setModalProps({
            collection,
            props: {
                title: 'Token Sales History',
                jsxContent: <_SalesHisotryModal history={res.data} />,
            },
        });
        setChildModal(true);
    };

    const wizardSubmitHandler = async () => {
        try {
            setMintLock(true);
            switch (props.footerButtons) {
                case 'BUY':
                    await klaymint.buyRequest(
                        {
                            wallet: wallet,
                            tokenId: props.item?.data.token_id,
                            sellPrice: props.item.data.sales_price,
                        },
                        {
                            sucCallback(receipt?) {
                                remoteWizardOpener('trade_chk_wizard');
                                setMintLock(false);
                                setModal(false);
                                postBuyEvent(collection.factory_address);
                                klaymint.updateFactoryList();
                                window.toast('success', Lang.suc_msg_sucs_buy);
                                optimisticDelete(props.item);
                            },
                            failCallback() {
                                remoteWizardOpener('trade_chk_wizard');
                                setMintLock(false);
                                setModal(false);
                                window.toast('error', Lang.err_msg_sucs_buy);
                            },
                            exceptionCallback() {
                                remoteWizardOpener('trade_chk_wizard');
                                setMintLock(false);
                                setModal(false);
                            },
                        },
                        { dispatch: dispatch, Lang: Lang },
                    );
                    break;

                case 'SELL CANCEL':
                    await klaymint.sellCancelRequest(
                        { wallet: wallet, tokenId: props.item?.data.token_id },
                        {
                            sucCallback(receipt?) {
                                try {
                                    remoteWizardOpener('trade_chk_wizard');
                                } catch (e) {
                                    console.log(e);
                                }
                                setMintLock(false);
                                setModal(false);
                                klaymint.updateFactoryList();
                                window.toast('success', Lang.suc_msg_sucs_sellCancel);
                                console.log('345');
                            },
                            failCallback() {
                                console.log('123');
                                remoteWizardOpener('trade_chk_wizard');
                                setMintLock(false);
                                setModal(false);
                                window.toast('error', Lang.err_msg_sucs_sellCancel);
                            },
                            exceptionCallback() {
                                remoteWizardOpener('trade_chk_wizard');
                                setMintLock(false);
                                setModal(false);
                            },
                        },
                        { dispatch: dispatch, Lang: Lang },
                    );
                    break;
                default:
                    break;
            }
        } catch (err) {
            console.log(err);
        }
    };

    const sellButtonOnClickHandler = async () => {
        // null 이면 정상 아래 validate check
        const checkNum = numOnlyTwoDecimal(+price);
        if (checkNum) {
            window?.toast('error', Lang.err_msg_fail_price_not_integers);
            return;
        }
        if (!+price) {
            window?.toast('error', Lang.err_msg_fail_price_empty);
            return;
        }
        if (+price > 99999999.99) {
            window?.toast('error', Lang.err_msg_fail_price_too_big);
            return;
        }
        if (!wallet.isConn) {
            window?.toast('error', Lang.err_msg_fail_connect_wallet);
            return;
        }

        await remoteWizardOpener('trade_chk_stepwizard');
    };

    const approvedWizardHandler = async () => {
        setMintLock(true);
        let isApproved = await klaymintUtil.isApproved(
            wallet.info.address,
            collection.factory_address,
            collection.contract_address,
        );

        if (isApproved) {
            setMintLock(false);
            return true;
        } else {
            try {
                await klaymint.setApprovedAll(
                    { wallet, spender: collection.factory_address },
                    {
                        sucCallback(receipt?) {
                            window.toast('success', Lang.suc_msg_sucs_sell_approved);
                        },
                        failCallback() {
                            window.toast('error', Lang.err_msg_sucs_sell_approved);
                        },
                        exceptionCallback() {},
                    },
                    { dispatch: dispatch, Lang: Lang },
                );
                isApproved = await klaymintUtil.isApproved(
                    wallet.info.address,
                    collection.factory_address,
                    collection.contract_address,
                );
                if (isApproved) {
                    setMintLock(false);
                    return true;
                } else return false;
            } catch (e) {
                window.toast('error', Lang.err_msg_transfer_invalid_address);
                return false;
            }
        }

        setMintLock(false);
        return remoteWizardOpener('trade_chk_stepwizard');
    };

    const stepWizardSubmitHandler = async () => {
        await klaymint.sellRequest(
            {
                wallet: wallet,
                tokenId: props.item.data.token_id,
                sellPrice: +price,
                tokenInfo: props.item.data,
            },
            {
                sucCallback(receipt?) {
                    remoteWizardOpener('trade_chk_stepwizard');
                    setModal(false);
                    klaymint.updateFactoryList();
                    window.toast('success', Lang.suc_msg_sucs_sell);
                    // confirm 창 만들게 되면 push 해서 item 전달
                    // history.push({
                    //     pathname: `/collections/${collection.contract_name}/${collection.brand_name}`,
                    //     state: { item: props.item.data },
                    // });
                },
                failCallback() {
                    remoteWizardOpener('trade_chk_stepwizard');
                    setModal(false);
                    window.toast('error', Lang.err_msg_sucs_sellCancel);
                },
                exceptionCallback() {
                    remoteWizardOpener('trade_chk_stepwizard');
                    setModal(false);
                },
            },
            { dispatch: dispatch, Lang: Lang },
        );
    };

    return (
        <>
            <section className={css.ArticleDetailSection} style={style ? style : undefined}>
                <div className={css.mainSection}>
                    {/* ********************************************************************************
                     *  mainImage 를 card 형식으로 출력하는 tsx **********************************************
                     *********************************************************************************/}
                    {props.mainImage ? (
                        <div className={cx(css.modalCardContainer)}>
                            <div style={{ position: 'relative' }}>
                                {props.mainVideo ? (
                                    <video controls autoPlay muted loop className={css.modalCardVideo}>
                                        <source src={props.mainVideo}></source>
                                    </video>
                                ) : (
                                    <img className={css.modalCardImage} src={props.mainImage} alt="" />
                                )}
                                {props.item?.data.isStaking ? (
                                    <img src={S} alt="S" className={css.StakingcardMark} />
                                ) : null}
                            </div>

                            {props.mainAttrs ? (
                                <div className={css.modalCardAttr}>
                                    <div className={css.right}>
                                        <h2>{props.title}</h2>
                                    </div>
                                    <div className={cx('row', css.attrRow)}>
                                        {props.mainAttrs.map((attr, index) => {
                                            try {
                                                const value = JSON.parse(attr[1]);

                                                return (
                                                    <div className={cx('col-md-6', css.attrCol)} key={index}>
                                                        <span
                                                            className={css.attrKey}
                                                            style={value[1] ? getBackgroundColor(value[1]) : undefined}
                                                        >
                                                            {attr[0]}
                                                        </span>
                                                        <span
                                                            className={css.attrValue}
                                                            style={value[1] ? getBackgroundColor(value[1]) : undefined}
                                                        >
                                                            {attr[0] === 'CLASS' ? value[1] : value[0]}
                                                        </span>
                                                    </div>
                                                );
                                            } catch (e) {
                                                return (
                                                    <div className={cx('col-md-6', css.attrCol)} key={index}>
                                                        <span
                                                            className={css.attrKey}
                                                            style={attr[1] ? getBackgroundColor(attr[1]) : undefined}
                                                        >
                                                            {attr[0]}
                                                        </span>
                                                        <span
                                                            className={css.attrValue}
                                                            style={attr[1] ? getBackgroundColor(attr[1]) : undefined}
                                                        >
                                                            {attr[1]}
                                                        </span>
                                                    </div>
                                                );
                                            }
                                        })}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    ) : null}

                    {/* ********************************************************************************
                     *  mainImage 를 card 형식으로 출력하는 tsx **********************************************
                     *********************************************************************************/}

                    {props.mainDescButtons ||
                    props.mainDesc ||
                    props.mainAttrs ||
                    props.mainAttrDesc ||
                    props.footerInput ||
                    props.footerPriceHistory ? (
                        <div className={css.descContainer}>
                            <div>
                                <div className={css.titleSection}>
                                    {props.title ? (
                                        <div className={css.name}>
                                            <h2>{props.title}</h2>
                                            {/*<button type="button">*/}
                                            {/*    <span>*/}
                                            {/*        <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">*/}
                                            {/*            <path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z"></path>*/}
                                            {/*        </svg>*/}
                                            {/*    </span>*/}
                                            {/*</button>*/}
                                            <button
                                                onClick={() =>
                                                    window.open(
                                                        `https://scope.klaytn.com/nft/${collection.contract_address}/${
                                                            props.title?.split('#')[1]
                                                        }`,
                                                    )
                                                }
                                            >
                                                <span>
                                                    <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                                        <path d="M21 3H3c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H3V5h10v4h8v10z"></path>
                                                    </svg>
                                                </span>
                                            </button>
                                        </div>
                                    ) : null}

                                    {props.titleButtons
                                        ? props.titleButtons.map(
                                              (button: string | JSX.Element | Buffer | any, index) => {
                                                  return (
                                                      <button className={css.shareButton} type="button" key={index}>
                                                          {button}
                                                      </button>
                                                  );
                                              },
                                          )
                                        : null}
                                </div>
                                {props.mainDescButtons ? (
                                    <div className={css.mainTopButtons}>
                                        {props.mainDescButtons.map((button, index) => {
                                            return (
                                                <div key={index} className={css.ownedLink}>
                                                    <button type="button">
                                                        <span>{button}</span>
                                                    </button>
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : null}
                                {props.mainDesc ? (
                                    <ShowMoreText
                                        lines={3}
                                        more="Show more"
                                        less="Show less"
                                        className={css.descText}
                                        expanded={false}
                                        width={800}
                                    >
                                        {props.mainDesc.split('\n').map((item) => {
                                            if (item === '') return <br />;
                                            else {
                                                return (
                                                    <>
                                                        <p>{item}</p>
                                                        <br />
                                                    </>
                                                );
                                            }
                                        })}
                                    </ShowMoreText>
                                ) : null}
                            </div>

                            <div>
                                {props.mainAttrs ? (
                                    <div className={css.modalCardAttr}>
                                        <div className={cx('row', css.attrRow)}>
                                            {props.mainAttrs
                                                .filter((item) => item[0] === 'CLASS')
                                                .map((attr, index) => {
                                                    const setBorderCss = (value) => {
                                                        switch (value) {
                                                            case 'Unique':
                                                                return css.uniqueBorderCss;
                                                            case 'Legendary':
                                                                return css.LegendaryBorderCss;
                                                            case 'Myth':
                                                                return css.MythBorderCss;
                                                            case 'Ancient':
                                                                return css.AncientBorderCss;
                                                            default:
                                                                return undefined;
                                                        }
                                                    };
                                                    try {
                                                        const value = JSON.parse(attr[1]);

                                                        return (
                                                            <div
                                                                className={cx(
                                                                    'col-md-6',
                                                                    css.attrCol,
                                                                    css.attrClassCol,
                                                                    setBorderCss(value[1]),
                                                                )}
                                                                key={index}
                                                            >
                                                                <span className={cx(css.attrKey, css.attrClassKey)}>
                                                                    {attr[0]}
                                                                </span>
                                                                <span className={cx(css.attrValue, css.attrClassValue)}>
                                                                    {value[1].toUpperCase()}
                                                                </span>
                                                            </div>
                                                        );
                                                    } catch (e) {
                                                        return (
                                                            <div
                                                                className={cx(
                                                                    'col-md-6',
                                                                    css.attrCol,
                                                                    css.attrClassCol,
                                                                    setBorderCss(attr[1]),
                                                                )}
                                                                key={index}
                                                            >
                                                                <span className={cx(css.attrKey, css.attrClassKey)}>
                                                                    {attr[0]}
                                                                </span>
                                                                <span className={cx(css.attrValue, css.attrClassValue)}>
                                                                    {attr[1].toUpperCase()}
                                                                </span>
                                                            </div>
                                                        );
                                                    }
                                                })}
                                            {props.mainAttrs.map((attr, index) => {
                                                try {
                                                    const value = JSON.parse(attr[1]);

                                                    if (attr[0] !== 'CLASS') {
                                                        return (
                                                            <div
                                                                className={cx(
                                                                    'col-md-6',
                                                                    css.attrCol,
                                                                    setGradient(value[1]),
                                                                )}
                                                                key={index}
                                                            >
                                                                <span className={css.attrKey}>{attr[0]}</span>
                                                                <span className={css.attrValue}>{value[0]}</span>
                                                            </div>
                                                        );
                                                    }
                                                } catch (e) {
                                                    if (attr[0] !== 'CLASS') {
                                                        return (
                                                            <div
                                                                className={cx(
                                                                    'col-md-6',
                                                                    css.attrCol,
                                                                    setGradient(attr[1]),
                                                                )}
                                                                key={index}
                                                            >
                                                                <span className={css.attrKey}>{attr[0]}</span>
                                                                <span className={css.attrValue}>{attr[1]}</span>
                                                            </div>
                                                        );
                                                    }
                                                }
                                            })}
                                        </div>
                                    </div>
                                ) : null}
                                {props.footerPrice ? (
                                    <div className={css.priceSection}>
                                        <img alt="KLAY" src={iconKaikas} />
                                        <span>{props.footerPrice}</span>
                                        {props.footerPriceHistory ? (
                                            <button
                                                type="button"
                                                className={css.lastPriceHistoryBtn}
                                                onClick={loadArticleModal}
                                            >
                                                <span>
                                                    Last
                                                    <img alt="KLAY" src={iconKaikas} />
                                                    {props.footerPriceHistory}
                                                </span>
                                                <span>
                                                    <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                                        <path d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.28 2.54.72-1.21-3.5-2.08V8H12z"></path>
                                                    </svg>
                                                </span>
                                            </button>
                                        ) : null}
                                    </div>
                                ) : null}
                            </div>
                            {props.mainAttrDesc && mobileSizeEx.indexOf(breakpoint) === -1 ? (
                                <div className={css.attrDesc}>
                                    {Object.keys(props.mainAttrDesc).map((item, index) => {
                                        if (props.mainAttrDesc[item].scs_name !== 'none') {
                                            return (
                                                <div className={css.attrDescContainer} key={index}>
                                                    {capitalize(props.mainAttrDesc[item].scs_name)}
                                                    <div
                                                        className={css.classBox}
                                                        style={{
                                                            backgroundColor: `#${props.mainAttrDesc[item].sc_code0}`,
                                                        }}
                                                    ></div>
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                            ) : null}

                            {props.footerInput ? (
                                <div className={css.inputSection}>
                                    <label>
                                        <span>
                                            <img src={iconKaikas} alt="klay" />
                                            KLAY :{'  '}
                                        </span>
                                        <input
                                            onChange={(e) => setPrice(e.target.value)}
                                            placeholder="Write the desired klay"
                                        />
                                    </label>
                                </div>
                            ) : null}
                        </div>
                    ) : null}

                    {/* modal 에 다른 컨텐츠 없이 props 값으로 직접 jsx 코드를 넘기는 경우 ex) priceHistory 또는 salesHistory */}
                    {props.jsxContent ? props.jsxContent : null}
                </div>

                {props.footerButtons ? (
                    <div className={css.buttonSection}>
                        {props.footerButtons === 'SELL' ? (
                            <button type="button" onClick={sellButtonOnClickHandler}>
                                {props.footerButtons}
                            </button>
                        ) : (
                            <button
                                type="button"
                                onClick={async () => {
                                    // Buy Button ************************************ popo Collection -> detail
                                    // Sell Cancel Button ************************************
                                    remoteWizardOpener('trade_chk_wizard');
                                }}
                            >
                                {props.footerButtons}
                            </button>
                        )}
                    </div>
                ) : null}

                {childModal ? <LastPriceHistoryModal setModal={setChildModal} modalProps={modalProps} /> : null}
            </section>

            {/* ********************************************************************************
             *  TRY BUY, SELL CANCLE, **********************************************
             *********************************************************************************/}
            {props.footerButtons ? (
                <Wizard
                    className="border-0"
                    closeHold={true}
                    id="trade_chk_wizard"
                    title={Lang.modal_check_mint_title}
                    lock={mintLock}
                >
                    <div>
                        <div className="row mx-4 mb-2 text-dark">
                            <hr />
                            <div className="d-flex justify-content-between p-0">
                                <p className="text-dark">{props.footerButtons} Item</p>
                                <span className="text-dark">{props.title}</span>
                            </div>
                            <div className="d-flex justify-content-between mt-2 p-0">
                                <p className="text-dark">Price</p>
                                <span className="text-dark">
                                    {props.footerButtons === 'BUY' && (
                                        <>
                                            <img width={18} src={iconKaikas} />{' '}
                                            <b style={{ color: '#dd3333', fontSize: '1.2em' }}>
                                                {numberWithCommas(Number(props.footerPrice), '')}
                                            </b>
                                        </>
                                    )}
                                    {props.footerButtons === 'SELL' && (
                                        <small className="fw-lighter">{Lang.modal_check_little_fee}</small>
                                    )}
                                    {props.footerButtons === 'SELL CANCEL' && (
                                        <small className="fw-lighter">{Lang.modal_check_little_fee}</small>
                                    )}
                                </span>
                            </div>
                            <div className="d-flex justify-content-between p-0 my-3">
                                <small className="fw-lighter">{Lang.modal_check_mint_text_hold}</small>
                                <small className="fw-lighter">{wallet.info.balance} Klay</small>
                            </div>
                        </div>
                        {/* BUY 버튼 */}
                        <div className="position-bottom bg-dark p-0 text-center">
                            {props.footerButtons === 'BUY' &&
                            // 클레이 부족시
                            Number(wallet.info.balance) - Number(props.footerPrice) <= 0 ? (
                                <button
                                    className="btn m-0 p-3"
                                    style={{ color: 'red' }}
                                    onClick={() => remoteWizardOpener('trade_chk_wizard')}
                                >
                                    <i className="fas fa-info-circle" /> {Lang.modal_check_mint_lack_to_klay}
                                </button>
                            ) : (
                                <button disabled={mintLock} className="btn m-0 p-3" onClick={wizardSubmitHandler}>
                                    {mintLock ? (
                                        <div className="spinner-border text-light" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    ) : (
                                        props.footerButtons
                                    )}
                                </button>
                            )}
                        </div>
                    </div>
                </Wizard>
            ) : null}
            {/* ********************************************************************************
             *  Step Wizard 1.approved  2.SELL *************************************************
             *********************************************************************************/}

            <StepWizard closeHold={true} id="trade_chk_stepwizard" lock={mintLock}>
                {/* Step 1 . Approved (승인 상태 조회) 절차 진행 */}
                <div
                    className="row mx-4 mb-2 text-dark"
                    data-step-title="Request a Approved"
                    data-fn-next={approvedWizardHandler}
                >
                    <hr />
                    <div className="d-flex justify-content-center p-0">
                        <b className="text-dark" style={{ fontSize: '1.2em' }}>
                            {Lang.modal_check_need_data_check_a_approved}
                        </b>
                    </div>

                    <div className="d-flex justify-content-center p-0 my-3">
                        <small className="fw-lighter">{Lang.modal_check_sell_item_need_after_approved}</small>
                    </div>
                </div>

                {/* Step 2 . Request a Transction 절차 진행 */}
                <div
                    className="row mx-4 mb-2 text-dark"
                    data-step-title="Request a Transaction"
                    data-fn-confirm={stepWizardSubmitHandler}
                >
                    <hr />
                    <div className="d-flex justify-content-between p-0">
                        <p className="text-dark">{props.footerButtons ? props.footerButtons : null} Item</p>
                        <span className="text-dark">{props.title}</span>
                    </div>
                    <div className="d-flex justify-content-between mt-2 p-0">
                        <p className="text-dark">Sell Order</p>
                        <span className="text-dark align-items-center">
                            <img width={18} src={iconKaikas} />
                            <b style={{ color: '#33AA33', fontSize: '1.2em' }}>{numberWithCommas(Number(price), '')}</b>
                        </span>
                    </div>
                    <div className="d-flex justify-content-between p-0 my-3">
                        <small className="fw-lighter">{Lang.modal_check_mint_text_hold}</small>
                        <small className="fw-lighter">{wallet.info.balance} Klay</small>
                    </div>
                </div>
            </StepWizard>
        </>
    );
};

export default _ArticleModal;
