import React, { useState, useRef } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLanguages } from '@/hooks/useLanguages.hook';

import { RootState } from '@/redux/connectors.redux';
import { setUser, getUser } from '@/redux/reducers/Wallet.reducer';

import { copyToClipboard, shortWalletAddr } from '@/helpers/common.helper';
import { postCreateUser, postUpdateNickname, postUpdateThumbnail } from '@/helpers/klaymint.api';

import css from './profile1.module.scss';
import iconKlip_W from '@/_statics/images/icon_klip_w.png';
import iconKaikas from '@/_statics/images/icon_kaikas.svg';

import { ModalButton, Modal, remoteModalOpener, remoteCollapseOpener } from '@/_components/commons/modals';
import ImageCropper from '@/_components/commons/imageCropper/imageCropper';

const Profile: React.FC<any> = (props): JSX.Element => {
    const nicknameRef = useRef(null);
    const backBtnRef = useRef(null);
    const { autoconn, mobile } = useSelector((store: RootState) => store.GlobalStatus);
    const wallet = useSelector((store: RootState) => store.Wallet);
    const Pluswallet = useSelector((store: RootState) => store.PerPlus);
    const Lang = useLanguages();
    const dispatch = useDispatch();

    // const [edit, setEdit] = useState(false);
    const [nickname, setNickname] = useState('');

    const { logout } = props;
    /**
     * 지갑 주소 복사하기 핸들러
     */
    const copyToClipboardHandler = (data: string): void => {
        copyToClipboard(data);
        window.toast('info', Lang.inf_msg_copy_to_clipboard);
    };

    // const createProfile = () => {
    //     if (wallet.type === 'none') return;

    //     postCreateUser({
    //         usr_type: wallet.type,
    //         usr_nickname: shortWalletAddr(wallet.info.address),
    //         usr_wallet_address: wallet.info.address,
    //     })
    //         .then((res) => {
    //             console.log(res.data);
    //             if (!res?.data[0]) return;

    //             dispatch(
    //                 getUser({
    //                     usr_wallet_address: wallet.info.address,
    //                 }),
    //             );
    //         })
    //         .catch((err) => {});
    // };

    const changedNicknameHandler = () => {
        const regex = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|]+$/;
        if (!regex.test(nickname) && (nickname.length > 8 || nickname.length <= 0)) {
            window.toast('error', Lang.err_msg_faild_changed_nickname);
            return;
        }

        postUpdateNickname({ usr_type: wallet.type, usr_wallet_address: wallet.info.address, usr_nickname: nickname })
            .then(async (res) => {
                if (res?.data[0] === false) {
                    console.log(res.data[1]);
                    window.toast('notice', Lang.noti_msg_duplication_nickname);
                    return;
                    // await dispatch(getUser({ usr_wallet_address: wallet.info.address }));
                }
                window.toast('success', Lang.suc_msg_sucs_changed_nickname);
                await dispatch(getUser({ usr_wallet_address: wallet.info.address }));
            })
            .catch((err) => {
                window.toast('error', Lang.err_msg_faild_changed_nickname);
            });
        // setEdit(false);
    };

    // const changedThumbnailHandler = (image) => {
    //     console.log('changedThumbnailHandler');
    //     postUpdateThumbnail({})
    //         .then(async (res) => {
    //             console.log(res.data);

    //             window.toast('success', Lang.suc_msg_sucs_changed_nickname);
    //             backBtnRef.current.click();
    //         })
    //         .catch((err) => {
    //             window.toast('error', Lang.err_msg_faild_changed_nickname);
    //         });
    // };

    return (
        <>
            <nav className="navbar m-0 p-0">
                <div className={cx('d-flex flex-row-reverse align-items-center', css.profile1)}>
                    <div className="btn-group btn-group-link" role="group">
                        <button
                            onClick={logout}
                            id="language_flags"
                            type="button"
                            className={cx('btn connectWalletButton text-white')}
                            // data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <b>{Lang.header_logout}</b>
                            {/*<small>*/}
                            {/*    <i className="fas fa-chevron-down mx-2" />*/}
                            {/*</small>*/}
                        </button>
                        <div
                            className="dropdown-menu dropdown-menu-end bg-dark border-white"
                            aria-labelledby="BtnLanguage"
                        >
                            <button type="button" className="dropdown-item text-white">
                                <Link to="myPage">
                                    <b>MyPage</b>
                                </Link>
                            </button>
                            <button
                                type="button"
                                className="dropdown-item text-white"
                                onClick={() => {
                                    remoteModalOpener('myProfile');
                                }}
                            >
                                <b>{Lang.Change_Nickname}</b> {/* <span className="mx-3">정보변경</span> */}
                            </button>

                            <button
                                type="button"
                                className="dropdown-item text-white"
                                onClick={() => {
                                    logout();
                                }}
                            >
                                <b>{Lang.LogOut}</b> {/* <span className="mx-3">Logout</span> */}
                            </button>
                        </div>
                    </div>
                    {/* <img
                            src={''}
                            alt="P"
                            className="img-thumbnail rounded-circle mx-auto d-block"
                            onError={(e) => {
                                e.currentTarget.src =
                                    wallet.type === 'kaikas' ? iconKaikas : wallet.type === 'klip' ? iconKlip_W : logo;
                            }}
                        />
                        {/*<span className="position-absolute start-100 translate-middle badge rounded-pill bg-danger">*/}
                    {/*    99+*/}
                    {/*    <span className="visually-hidden">unread messages</span>*/}
                    {/*</span>*/}

                    <div className="mx-2">
                        <ul className="text-end text-white m-0 p-0">
                            <li>
                                <button
                                    className="btn btn-sm m-0 p-0"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="bottom"
                                    title="Tooltip on bottom"
                                    onClick={() => copyToClipboardHandler(wallet.info.address)}
                                >
                                    <i className="far fa-copy" /> <span>{shortWalletAddr(wallet.info.address)} </span>
                                </button>
                            </li>
                            <li>
                                <small>
                                    <img src={iconKaikas} width="20px" height="20px" /> { wallet.type === 'kaikas' ? wallet.info.balance : wallet.info.balance.toString().slice(0,5)}
                                </small>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            {/* *********************************************************************************************** */}
            {/* ************************************* My Profile ********************************************** */}
            {/* *********************************************************************************************** */}

            <Modal className="text-center" id="myProfile" closeHold={true}>
                <div className={cx('modal-body p-0')}>
                    <div className={cx(css.upper)}>
                        <div className={cx(css.ModifyRow, 'row')}>
                            <div className={cx(css.ModifyNickName, 'col-2')}>닉네임</div>
                            <div className={cx(css.ModifyNickNameBox, 'col-8')}>
                                <input
                                    className={cx(css.NicknameInput, 'form-control form-control-sm')}
                                    type="text"
                                    placeholder="입력하세요"
                                    aria-label=".form-control-sm example"
                                    style={{ color: 'white' }}
                                    onChange={(e) => {
                                        setNickname(e.target.value);
                                    }}
                                />
                                <div className={css.NicknameNotice}>
                                    {' '}
                                    <i className="fas fa-exclamation-triangle"></i>&nbsp;&nbsp;2~8자 한글·영문, 특수기호
                                    입력 불가
                                </div>
                            </div>
                            <div className={cx(css.Btnmodify, 'col-2')}>
                                <button
                                    className={cx(css.BtnCo, 'btn btn-primary')}
                                    type="button"
                                    onClick={() => changedNicknameHandler()}
                                    style={{ color: 'white' }}
                                >
                                    수정
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* {!wallet.user && (
                    <div
                        className={cx(
                            'position-absolute start-0 top-0 d-flex align-items-center justify-content-center',
                            css.overlay,
                        )}
                    >
                        <button
                            type="button"
                            className={'btn-close btn-close-white position-absolute top-0 end-0 m-2'}
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            disabled={wallet.type === 'none'}
                            onClick={createProfile}
                        >
                            프로필 생성
                        </button>
                    </div>
                )} */}
                <button
                    type="button"
                    className={'btn-close btn-close-white position-absolute top-0 end-0 m-2'}
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </Modal>

            {/* *********************************************************************************************** */}
            {/* ************************************* Edit Thumbnail ****************************************** */}
            {/* *********************************************************************************************** */}
            <Modal className="text-center" id="editMyThumb" closeHold={true}>
                <ImageCropper />

                <div className={cx('modal-footer p-0 d-flex align-items-center justify-content-between')}>
                    <button
                        ref={backBtnRef}
                        type="button"
                        className="btn text-dark"
                        data-bs-target="#myProfile"
                        data-bs-toggle="modal"
                        data-bs-dismiss="modal"
                    >
                        Back
                    </button>

                    <button type="button" className="btn text-dark">
                        변경
                    </button>
                </div>
                <button
                    type="button"
                    className={'btn-close position-absolute top-0 end-0 m-2'}
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </Modal>
        </>
    );
};
export default Profile;
