import dotenv from 'dotenv';
dotenv.config();

/**
 * klaymint 관련 ABI (거래 관련)
 */
export const isApprovalForAppABI: any = {
    constant: true,
    inputs: [
        {
            name: 'owner',
            type: 'address',
        },
        {
            name: 'operator',
            type: 'address',
        },
    ],
    name: 'isApprovedForAll',
    outputs: [
        {
            name: '',
            type: 'bool',
        },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
};
export const setApprovalForAllABI: any = {
    name: 'setApprovalForAll',
    type: 'function',
    inputs: [
        {
            type: 'address',
            name: 'to',
        },
        {
            type: 'bool',
            name: 'approved',
        },
    ],
};
export const setApprovoalForKip37ABI: any = {
    constant: false,
    inputs: [
        {
            internalType: 'address',
            name: 'operator',
            type: 'address',
        },
        {
            internalType: 'bool',
            name: 'approved',
            type: 'bool',
        },
    ],
    name: 'setApprovalForAll',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
};

export const multiMint_ABI: any = {
    constant: false,
    inputs: [
        {
            internalType: 'uint256',
            name: '_roundNumber',
            type: 'uint256',
        },
        {
            internalType: 'uint256',
            name: '_mintQ',
            type: 'uint256',
        },
        {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
        },
    ],
    name: 'mintNFT',
    outputs: [],
    payable: true,
    stateMutability: 'payable',
    type: 'function',
};
export const sellNFTABI: any = {
    name: 'sell',
    type: 'function',
    inputs: [
        {
            type: 'uint256',
            name: '_tokenId',
        },
        {
            type: 'uint256',
            name: '_sellPrice',
        },
    ],
};
export const sellCancelNFTABI: any = {
    name: 'sell_cancel',
    type: 'function',
    inputs: [
        {
            type: 'uint256',
            name: '_tokenId',
        },
    ],
};
export const sellingItemList: any = {
    constant: true,
    inputs: [
        {
            name: '_user',
            type: 'address',
        },
    ],
    name: 'user_selling_items',
    outputs: [
        {
            name: '',
            type: 'uint256[]',
        },
        {
            name: '',
            type: 'uint256[]',
        },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
};
export const buyNFTABI: any = {
    name: 'buy',
    type: 'function',
    payable: true,
    inputs: [
        {
            type: 'uint256',
            name: '_tokenId',
        },
    ],
};
export const last_mintNFT_ABI: any = {
    constant: false,
    inputs: [
        {
            name: '_roundNumber',
            type: 'uint256',
        },
        {
            name: '_mintQ',
            type: 'uint256',
        },
    ],
    name: 'mintNFT',
    outputs: [],
    payable: true,
    stateMutability: 'payable',
    type: 'function',
};
export const auto_mint_ABI: any = {
    constant: false,
    inputs: [
        {
            internalType: 'uint256',
            name: '_roundNumber',
            type: 'uint256',
        },
        {
            internalType: 'uint256',
            name: '_mintQ',
            type: 'uint256',
        },
        {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
        },
    ],
    name: 'autoMint',
    outputs: [],
    payable: true,
    stateMutability: 'payable',
    type: 'function',
};
export const safeTransferFromABI: any = {
    constant: false,
    inputs: [
        {
            internalType: 'address',
            name: 'from',
            type: 'address',
        },
        {
            internalType: 'address',
            name: 'to',
            type: 'address',
        },
        {
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256',
        },
    ],
    name: 'safeTransferFrom',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
};

export const factoryABI: any = [
    {
        constant: false,
        inputs: [
            {
                name: '_mintQ',
                type: 'uint256',
            },
        ],
        name: 'setMintQ',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                name: '',
                type: 'uint256',
            },
        ],
        name: 'sellItems',
        outputs: [
            {
                name: 'owner',
                type: 'address',
            },
            {
                name: 'sellPrice',
                type: 'uint256',
            },
            {
                name: 'tokenId',
                type: 'uint256',
            },
            {
                name: 'isSelling',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [],
        name: 'renounceOwner',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                name: 'account',
                type: 'address',
            },
        ],
        name: 'isOwner',
        outputs: [
            {
                name: '',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'maxMintCount',
        outputs: [
            {
                name: '',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                name: '_mintCount',
                type: 'uint256',
            },
        ],
        name: 'setMintCount',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                name: '',
                type: 'address',
            },
        ],
        name: 'sellings',
        outputs: [
            {
                name: 'owner',
                type: 'address',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'minted',
        outputs: [
            {
                name: '',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                name: '_maxMint',
                type: 'uint256',
            },
        ],
        name: 'setMaxMint',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'mintPrice',
        outputs: [
            {
                name: '',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                name: '_address',
                type: 'address',
            },
        ],
        name: 'setNFTProjectAddress',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                name: 'account',
                type: 'address',
            },
        ],
        name: 'addOwner',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [],
        name: 'release',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                name: '_mintQ',
                type: 'uint256',
            },
        ],
        name: 'mintNFT',
        outputs: [],
        payable: true,
        stateMutability: 'payable',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'released',
        outputs: [
            {
                name: '',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'mintCount',
        outputs: [
            {
                name: '',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'ViewState',
        outputs: [
            {
                name: '',
                type: 'uint256',
            },
            {
                name: '',
                type: 'uint256',
            },
            {
                name: '',
                type: 'uint256',
            },
            {
                name: '',
                type: 'uint256',
            },
            {
                name: '',
                type: 'address',
            },
            {
                name: '',
                type: 'address',
            },
            {
                name: '',
                type: 'uint256',
            },
            {
                name: '',
                type: 'uint256',
            },
            {
                name: '',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                name: '_tokenId',
                type: 'uint256',
            },
        ],
        name: 'admin_sell_cancel',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                name: '_klaymint',
                type: 'uint256',
            },
            {
                name: '_project',
                type: 'uint256',
            },
        ],
        name: 'setTradeFee',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                name: '_tokenId',
                type: 'uint256',
            },
        ],
        name: 'sell_cancel',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                name: '_user',
                type: 'address',
            },
        ],
        name: 'user_selling_items',
        outputs: [
            {
                name: '',
                type: 'uint256[]',
            },
            {
                name: '',
                type: 'uint256[]',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                name: '_address',
                type: 'address',
            },
        ],
        name: 'set_deposit_wallet',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                name: '_to',
                type: 'address',
            },
            {
                name: '_tokenId',
                type: 'uint256',
            },
            {
                name: '_uri',
                type: 'string',
            },
        ],
        name: 'mint',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                name: '_tokenId',
                type: 'uint256',
            },
            {
                name: '_sellPrice',
                type: 'uint256',
            },
        ],
        name: 'sell',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                name: '_tokenId',
                type: 'uint256',
            },
        ],
        name: 'buy',
        outputs: [],
        payable: true,
        stateMutability: 'payable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                name: '_time',
                type: 'uint256',
            },
        ],
        name: 'setMintTimeStamp',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                name: '_price',
                type: 'uint256',
            },
        ],
        name: 'setMintPrice',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                name: '_tokenId',
                type: 'uint256',
            },
        ],
        name: 'token_trade_history',
        outputs: [
            {
                name: '',
                type: 'uint256[]',
            },
            {
                name: '',
                type: 'address[]',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [],
        name: 'mint_release',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                name: '_address',
                type: 'address',
            },
        ],
        name: 'setKlayMintAddress',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                name: '_owner',
                type: 'address',
            },
            {
                indexed: true,
                name: '_tokenId',
                type: 'uint256',
            },
        ],
        name: 'mintEvent',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                name: '_owner',
                type: 'address',
            },
            {
                indexed: true,
                name: '_tokenId',
                type: 'uint256',
            },
            {
                indexed: true,
                name: '_sellPrice',
                type: 'uint256',
            },
        ],
        name: 'sellEvent',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                name: '_seller',
                type: 'address',
            },
            {
                indexed: true,
                name: '_buyer',
                type: 'address',
            },
            {
                indexed: true,
                name: 'tokenId',
                type: 'uint256',
            },
            {
                indexed: false,
                name: '_sellPrice',
                type: 'uint256',
            },
        ],
        name: 'buyEvent',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                name: 'owner',
                type: 'address',
            },
            {
                indexed: true,
                name: 'tokenId',
                type: 'uint256',
            },
        ],
        name: 'sellCancelEvent',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                name: 'account',
                type: 'address',
            },
        ],
        name: 'OwnerAdded',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                name: 'account',
                type: 'address',
            },
        ],
        name: 'OwnerRemoved',
        type: 'event',
    },
];

/**
 * per 관련 ABI
 */
export const perTokenAbi: any = [
    {
        constant: true,
        inputs: [],
        name: 'name',
        outputs: [
            {
                name: '',
                type: 'string',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                name: '_spender',
                type: 'address',
            },
            {
                name: '_value',
                type: 'uint256',
            },
        ],
        name: 'approve',
        outputs: [
            {
                name: 'success',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'totalSupply',
        outputs: [
            {
                name: '',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                name: '_from',
                type: 'address',
            },
            {
                name: '_to',
                type: 'address',
            },
            {
                name: '_value',
                type: 'uint256',
            },
        ],
        name: 'transferFrom',
        outputs: [
            {
                name: 'success',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'decimals',
        outputs: [
            {
                name: '',
                type: 'uint8',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                name: '_value',
                type: 'uint256',
            },
        ],
        name: 'burn',
        outputs: [
            {
                name: 'success',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                name: '',
                type: 'address',
            },
        ],
        name: 'balanceOf',
        outputs: [
            {
                name: '',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                name: '_from',
                type: 'address',
            },
            {
                name: '_value',
                type: 'uint256',
            },
        ],
        name: 'burnFrom',
        outputs: [
            {
                name: 'success',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [],
        name: 'release',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'owner',
        outputs: [
            {
                name: '',
                type: 'address',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'symbol',
        outputs: [
            {
                name: '',
                type: 'string',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'released',
        outputs: [
            {
                name: '',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                name: '_to',
                type: 'address',
            },
            {
                name: '_value',
                type: 'uint256',
            },
        ],
        name: 'transfer',
        outputs: [
            {
                name: 'success',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                name: '',
                type: 'address',
            },
        ],
        name: 'frozenAccount',
        outputs: [
            {
                name: '',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                name: '_spender',
                type: 'address',
            },
            {
                name: '_value',
                type: 'uint256',
            },
            {
                name: '_extraData',
                type: 'bytes',
            },
        ],
        name: 'approveAndCall',
        outputs: [
            {
                name: 'success',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                name: '',
                type: 'address',
            },
            {
                name: '',
                type: 'address',
            },
        ],
        name: 'allowance',
        outputs: [
            {
                name: '',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                name: 'target',
                type: 'address',
            },
            {
                name: 'freeze',
                type: 'bool',
            },
        ],
        name: 'freezeAccount',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                name: 'newOwner',
                type: 'address',
            },
        ],
        name: 'transferOwnership',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                name: 'initialSupply',
                type: 'uint256',
            },
            {
                name: 'tokenName',
                type: 'string',
            },
            {
                name: 'tokenSymbol',
                type: 'string',
            },
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'constructor',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                name: 'target',
                type: 'address',
            },
            {
                indexed: false,
                name: 'frozen',
                type: 'bool',
            },
        ],
        name: 'FrozenFunds',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                name: 'from',
                type: 'address',
            },
            {
                indexed: true,
                name: 'to',
                type: 'address',
            },
            {
                indexed: false,
                name: 'value',
                type: 'uint256',
            },
        ],
        name: 'Transfer',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                name: '_owner',
                type: 'address',
            },
            {
                indexed: true,
                name: '_spender',
                type: 'address',
            },
            {
                indexed: false,
                name: '_value',
                type: 'uint256',
            },
        ],
        name: 'Approval',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                name: 'from',
                type: 'address',
            },
            {
                indexed: false,
                name: 'value',
                type: 'uint256',
            },
        ],
        name: 'Burn',
        type: 'event',
    },
];

/**
 * PerPlus enforce 관련 ABI
 */
export const enforceABI: any = {
    constant: false,
    inputs: [
        {
            internalType: 'uint256',
            name: '_tokenId1',
            type: 'uint256',
        },
        {
            internalType: 'uint256',
            name: '_tokenId2',
            type: 'uint256',
        },
        {
            internalType: 'uint256',
            name: '_perPay',
            type: 'uint256',
        },
        {
            internalType: 'uint256',
            name: '_tokenId1_classNumber',
            type: 'uint256',
        },
        {
            internalType: 'uint256',
            name: '_tokenId2_classNumber',
            type: 'uint256',
        },
        {
            internalType: 'uint256',
            name: '_itemID',
            type: 'uint256',
        },
        {
            internalType: 'uint256',
            name: '_itemAmount',
            type: 'uint256',
        },
    ],
    name: 'enforce',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
};

export const isPerApprovedABI: any = {
    constant: false,
    inputs: [
        {
            name: '_spender',
            type: 'address',
        },
        {
            name: '_value',
            type: 'uint256',
        },
    ],
    name: 'approve',
    outputs: [
        {
            name: 'success',
            type: 'bool',
        },
    ],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
};

export const enforceContractABI: any = [
    {
        inputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'constructor',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'account',
                type: 'address',
            },
        ],
        name: 'OwnerAdded',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'account',
                type: 'address',
            },
        ],
        name: 'OwnerRemoved',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'uint256',
                name: 'toBurn',
                type: 'uint256',
            },
        ],
        name: 'burn_per',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: '_user',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: '_burnToken',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'ep_idx',
                type: 'uint256',
            },
        ],
        name: 'fail_enforce',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: '_user',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: '_tokenId1',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: '_tokenId2',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'ep_idx',
                type: 'uint256',
            },
        ],
        name: 'return_enforce',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: '_user',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: '_tokenId',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'ep_idx',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'classNumber',
                type: 'uint256',
            },
        ],
        name: 'setMiningPower',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'uint256',
                name: '_perPay',
                type: 'uint256',
            },
        ],
        name: 'set_enforce_price',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: '_user',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: '_tokenId',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'string',
                name: '_uri',
                type: 'string',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'ep_idx',
                type: 'uint256',
            },
        ],
        name: 'suc_enforce',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: '_user',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: '_tokenId1',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: '_tokenId2',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: '_tokenId1_classNumber',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: '_tokenId2_classNumber',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: '_itemID',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: '_itemAmount',
                type: 'uint256',
            },
        ],
        name: 'try_enforce',
        type: 'event',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'address',
                name: '_newOwner',
                type: 'address',
            },
        ],
        name: 'DB_transferOwnership',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'address',
                name: '_to',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: '_tokenId',
                type: 'uint256',
            },
        ],
        name: 'NFT_exit',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'uint256',
                name: '_power',
                type: 'uint256',
            },
        ],
        name: 'addClassPower',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'address',
                name: 'account',
                type: 'address',
            },
        ],
        name: 'addOwner',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        name: 'amountOfBurnPower',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'address',
                name: '_user',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: '_tokenId',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: '_itemID',
                type: 'uint256',
            },
        ],
        name: 'confirm',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'uint256',
                name: '_tokenId1',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: '_tokenId2',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: '_perPay',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: '_tokenId1_classNumber',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: '_tokenId2_classNumber',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: '_itemID',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: '_itemAmount',
                type: 'uint256',
            },
        ],
        name: 'enforce',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'enforcePrice',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'address',
                name: '_user',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: '_tokenId',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: '_burnTokenId',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'ep_idx',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: '_classNumber',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: '_itemID',
                type: 'uint256',
            },
        ],
        name: 'enforce_fail',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'address',
                name: '_user',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: '_tokenId1',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: '_tokenId2',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'ep_idx',
                type: 'uint256',
            },
        ],
        name: 'enforce_return',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'address',
                name: '_user',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: '_tokenId',
                type: 'uint256',
            },
            {
                internalType: 'string',
                name: '_uri',
                type: 'string',
            },
            {
                internalType: 'uint256',
                name: '_burnToken1',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: '_burnToken2',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'ep_idx',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: '_classNumber',
                type: 'uint256',
            },
        ],
        name: 'enforce_suc',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'address',
                name: 'account',
                type: 'address',
            },
        ],
        name: 'isOwner',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'klaymint_fee_percent',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        name: 'miningPower',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
            {
                internalType: 'uint256[]',
                name: '',
                type: 'uint256[]',
            },
            {
                internalType: 'uint256[]',
                name: '',
                type: 'uint256[]',
            },
            {
                internalType: 'bytes',
                name: '',
                type: 'bytes',
            },
        ],
        name: 'onKIP37BatchReceived',
        outputs: [
            {
                internalType: 'bytes4',
                name: '',
                type: 'bytes4',
            },
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
            {
                internalType: 'bytes',
                name: '',
                type: 'bytes',
            },
        ],
        name: 'onKIP37Received',
        outputs: [
            {
                internalType: 'bytes4',
                name: '',
                type: 'bytes4',
            },
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'perBurnPercent',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'pool_transfer_percent',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'project_fee_percent',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'recently_burnCount',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'recently_marketBalance',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [],
        name: 'release',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'released',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [],
        name: 'renounceOwner',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'uint256',
                name: '_classNumber',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: '_amount',
                type: 'uint256',
            },
        ],
        name: 'setAmountOfBurnPower',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [],
        name: 'setApprovedForStaker',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'uint256',
                name: '_price',
                type: 'uint256',
            },
        ],
        name: 'setEnforcePrice',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'uint256',
                name: '_percent',
                type: 'uint256',
            },
        ],
        name: 'setFeeKlayMint_Percent',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'uint256',
                name: '_percent',
                type: 'uint256',
            },
        ],
        name: 'setFeeProejct_Percent',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'address',
                name: '_itemOwnAddress',
                type: 'address',
            },
        ],
        name: 'setItemOwnAddress',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'address',
                name: '_klaymintWallet',
                type: 'address',
            },
        ],
        name: 'setKlaymintWallet',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'uint256',
                name: '_percent',
                type: 'uint256',
            },
        ],
        name: 'setPool_Percent',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'address',
                name: '_projectWallet',
                type: 'address',
            },
        ],
        name: 'setProjectWallet',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'uint256',
                name: '_recently_burnCount',
                type: 'uint256',
            },
        ],
        name: 'setRecently_burnCount',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'uint256',
                name: '_recently_marketBalance',
                type: 'uint256',
            },
        ],
        name: 'setRecently_marketBalance',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'uint256',
                name: '_percent',
                type: 'uint256',
            },
        ],
        name: 'setperBurnPercent',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'bytes4',
                name: 'interfaceId',
                type: 'bytes4',
            },
        ],
        name: 'supportsInterface',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'address',
                name: '_to',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: '_balance',
                type: 'uint256',
            },
        ],
        name: 'token_exit',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
];

export const dbContractABI: any = [
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'previousOwner',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'newOwner',
                type: 'address',
            },
        ],
        name: 'OwnershipTransferred',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'account',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'power',
                type: 'uint256',
            },
        ],
        name: 'setPowerEvent',
        type: 'event',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_contract_address',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: '_tokenId',
                type: 'uint256',
            },
        ],
        name: 'getPower',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'initialize',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'owner',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'renounceOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_contract_address',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: '_tokenId',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: '_power',
                type: 'uint256',
            },
        ],
        name: 'setPower',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'newOwner',
                type: 'address',
            },
        ],
        name: 'transferOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
];

/**
 * PerPlus nft staking 관련 ABI
 */
// export const nftDepositABI  :any= {
//     inputs: [
//         {
//             internalType: 'uint256',
//             name: '_tokenId',
//             type: 'uint256',
//         },
//     ],
//     name: 'deposit',
//     outputs: [],
//     stateMutability: 'nonpayable',
//     type: 'function',
// };
export const nftDepositABI: any = {
    inputs: [
        {
            internalType: 'uint256[]',
            name: 'ids',
            type: 'uint256[]',
        },
    ],
    name: 'deposits',
    outputs: [
        {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
        },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
};

export const nftWithdrawABI: any = {
    inputs: [
        {
            internalType: 'uint256[]',
            name: 'ids',
            type: 'uint256[]',
        },
    ],
    name: 'withdraws',
    outputs: [
        {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
        },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
};

// export const nftWithdrawABI  :any= {
//     inputs: [
//         {
//             internalType: 'uint256',
//             name: '_tokenId',
//             type: 'uint256',
//         }
//     ],
//     name: 'withdraw',
//     outputs: [],
//     stateMutability: 'nonpayable',
//     type: 'function',
// };

export const allWithdraw: any = {
    inputs: [],
    name: 'withdraw',
    outputs: [
        {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
        },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
};

export const harvest: any = {
    inputs: [],
    name: 'harvest',
    outputs: [
        {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
        },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
};

export const nftStakerContractABI: any = [
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'user',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'address',
                name: '_contractAddress',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'uint256',
                name: '_tokenId',
                type: 'uint256',
            },
        ],
        name: 'Deposit',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'user',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'address',
                name: '_contractAddress',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'uint256',
                name: '_tokenId',
                type: 'uint256',
            },
        ],
        name: 'EmergencyWithdraw',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'user',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'address',
                name: '_contractAddress',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: '_amount',
                type: 'uint256',
            },
        ],
        name: 'Harvest',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: 'operator',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'address',
                name: 'from',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'bytes',
                name: 'data',
                type: 'bytes',
            },
        ],
        name: 'NFTReceived',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'previousOwner',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'newOwner',
                type: 'address',
            },
        ],
        name: 'OwnershipTransferred',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: 'account',
                type: 'address',
            },
        ],
        name: 'Paused',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: 'account',
                type: 'address',
            },
        ],
        name: 'Unpaused',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'user',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'address',
                name: '_contractAddress',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'uint256',
                name: '_tokenId',
                type: 'uint256',
            },
        ],
        name: 'Withdraw',
        type: 'event',
    },
    {
        inputs: [],
        name: 'DAY_TO_SEC',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'MAX_STAKING_LIMIT',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'REWARD_DECIMAL',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'REWARD_ERC20_SUPPLY',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'REWARD_KLAY_SUPPLY',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'REWARD_PRECISION',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_amount',
                type: 'uint256',
            },
        ],
        name: 'addDistributionAmount',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'addKlayDistributionAmount',
        outputs: [],
        stateMutability: 'payable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'currentTotalWeight',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'dailyRewardUpdateBlock',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'dbContract',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_tokenId',
                type: 'uint256',
            },
        ],
        name: 'deposit',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint256[]',
                name: 'ids',
                type: 'uint256[]',
            },
        ],
        name: 'deposits',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_user',
                type: 'address',
            },
            {
                internalType: 'uint256[]',
                name: 'ids',
                type: 'uint256[]',
            },
        ],
        name: 'emergencyWithdraw',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_user',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: '_block',
                type: 'uint256',
            },
            {
                internalType: 'uint256[]',
                name: 'ids',
                type: 'uint256[]',
            },
        ],
        name: 'emergencyWithdrawNotUpdate',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_user',
                type: 'address',
            },
        ],
        name: 'getPageLoadData',
        outputs: [
            {
                internalType: 'uint256[10]',
                name: '',
                type: 'uint256[10]',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'getPageLoadData',
        outputs: [
            {
                internalType: 'uint256[10]',
                name: '',
                type: 'uint256[10]',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'harvest',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'account',
                type: 'address',
            },
        ],
        name: 'has',
        outputs: [
            {
                internalType: 'uint256[]',
                name: '',
                type: 'uint256[]',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_erc20Amount',
                type: 'uint256',
            },
        ],
        name: 'initDistributionAmount',
        outputs: [],
        stateMutability: 'payable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_dbContract',
                type: 'address',
            },
            {
                internalType: 'address',
                name: '_nftContract',
                type: 'address',
            },
            {
                internalType: 'address',
                name: '_rewardTokenContract',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: '_startBlock',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: '_rewardKlayPercentage',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: '_rewardERC20Percentage',
                type: 'uint256',
            },
        ],
        name: 'initialize',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'initialized',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'lastRewardBlock',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'nftContract',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'operator',
                type: 'address',
            },
            {
                internalType: 'address',
                name: 'from',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
            },
            {
                internalType: 'bytes',
                name: 'data',
                type: 'bytes',
            },
        ],
        name: 'onERC721Received',
        outputs: [
            {
                internalType: 'bytes4',
                name: '',
                type: 'bytes4',
            },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'owner',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'pause',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'paused',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_user',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: '_withdrawBlock',
                type: 'uint256',
            },
        ],
        name: 'pendingReward',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'renounceOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'rewardERC20',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'rewardERC20Percentage',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'rewardKlay',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'rewardKlayPercentage',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'rewardTokenContract',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'contract IERC20Upgradeable',
                name: 'erc20Token',
                type: 'address',
            },
            {
                internalType: 'contract IERC721',
                name: 'nftToken',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: 'erc20Amount',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'klayAmount',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
            },
        ],
        name: 'seize',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: 'limit',
                type: 'uint256',
            },
        ],
        name: 'setMaxStakingLimit',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_rewardKlayPercentage',
                type: 'uint256',
            },
        ],
        name: 'setRewardKlayPercentage',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_rewardERC20Percentage',
                type: 'uint256',
            },
        ],
        name: 'setRewardPercentage',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'newOwner',
                type: 'address',
            },
        ],
        name: 'transferOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'unpause',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'update',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_contractAddress',
                type: 'address',
            },
        ],
        name: 'updateDbContract',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_contractAddress',
                type: 'address',
            },
        ],
        name: 'updateNftContract',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_contractAddress',
                type: 'address',
            },
        ],
        name: 'updateRewardTokenContract',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'account',
                type: 'address',
            },
        ],
        name: 'weightOf',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_tokenId',
                type: 'uint256',
            },
            {
                internalType: 'address',
                name: '_user',
                type: 'address',
            },
        ],
        name: 'withdraw',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_tokenId',
                type: 'uint256',
            },
        ],
        name: 'withdraw',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_tokenId',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: '_block',
                type: 'uint256',
            },
            {
                internalType: 'address',
                name: '_user',
                type: 'address',
            },
        ],
        name: 'withdrawNotUpdate',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint256[]',
                name: 'ids',
                type: 'uint256[]',
            },
        ],
        name: 'withdraws',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
    },
];

export const ILPcontractABI: any = [
    {
        inputs: [
            {
                internalType: 'address',
                name: 'token',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
            },
        ],
        name: 'estimatePos',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
];

export const IKlaySwapABI: any = [
    {
        inputs: [
            {
                internalType: 'address',
                name: '_spender',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: '_value',
                type: 'uint256',
            },
        ],
        name: 'approve',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool',
            },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'tokenA',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: 'amountA',
                type: 'uint256',
            },
            {
                internalType: 'address',
                name: 'tokenB',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: 'amountB',
                type: 'uint256',
            },
            {
                internalType: 'address[]',
                name: 'path',
                type: 'address[]',
            },
        ],
        name: 'exchangeKctPos',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'token',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
            },
            {
                internalType: 'address[]',
                name: 'path',
                type: 'address[]',
            },
        ],
        name: 'exchangeKlayPos',
        outputs: [],
        stateMutability: 'payable',
        type: 'function',
    },
];
