import React, { useEffect } from 'react';

import TermOfUse_KO from '@/_statics/languages/ko_KR_termOfService';
import TermOfUse_EN from '@/_statics/languages/en_US_termOfService';
import TermOfUse_CHT from '@/_statics/languages/cn_CHT_termOfService';
import TermOfUse_CHS from '@/_statics/languages/cn_CHS_termOfService';

import { useSelector } from 'react-redux';
import { RootState } from '@/redux/connectors.redux';

const LangTermOfUse: React.FC<any> = (): JSX.Element => {
    const { language } = useSelector((store: RootState) => store.GlobalStatus);

    const obj = {
        'en-US': <TermOfUse_EN />,
        'ko-KR': <TermOfUse_KO />,
        'zh-CHT': <TermOfUse_CHT />,
        'zh-CHS': <TermOfUse_CHS />,
    };
    return <>{obj[language] ?? obj[navigator.language]}</>;
};

export default LangTermOfUse;
