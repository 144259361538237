import KlayMint, { KlayMintParamsInput, KlayMintParamsOutput, KlayMintParamsUtil } from '@/helpers/KlayMint';
import Caver from 'caver-js';
import { getStakeAvailable, setPromiseAll } from '@/helpers/_common';
import axios from 'axios';
import {
    enforceABI,
    perTokenAbi,
    auto_mint_ABI,
    nftDepositABI,
    nftWithdrawABI,
    allWithdraw,
    harvest,
    ILPcontractABI,
    IKlaySwapABI,
    multiMint_ABI,
} from '@/includes/abi';
import { prepare } from 'klip-sdk';
import { getKlayFromAddress } from '@/helpers/klaymint.api';
import {
    envEnNode,
    klayAddress,
    klaySwapFactory,
    myBAppName,
    perKlayLP,
    qNumber,
    stakerAddress,
} from '@/includes/envVariables';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/connectors.redux';

// Swap const's data init
/**
 * perPlusParamsInput 은 klayMintParamsInput을 상속받는다.
 */
export interface perPlusParamsInput extends KlayMintParamsInput {
    value?: string;
    perPay?: string;

    mainTokenId?: number;
    subTokenId?: number;
    mainTokenClassNumber?: number;
    subTokenClassNumber?: number;

    abiParams?: string | number[];
    abi?: string;
    expect?: number;
    type?: string;
    stakingInfo?: any;
}

/**
 * perPlus는 klaymint를 상속받는다.
 */
export default class PerPlus extends KlayMint {
    private readonly contractList: any[];
    private readonly perPLusNftContractAddress: string[];

    readonly enforceContractAddress: string;
    private readonly autoMintFactory: string;

    // 첫번째 인자로 enforceContractAddress , 두번째 인자는 KlayMint 인스턴스를 상속받아 사용하기 위한 option
    constructor(
        perPlus: { enforceContractAddress?: string; contractList: any[]; autoMintFactory?: string },
        klaymint?: { contractAddress?: string; factoryAddress?: string; list?: any[] },
    ) {
        super(klaymint?.contractAddress, klaymint?.factoryAddress, klaymint?.list);

        this.contractList = perPlus.contractList;
        this.perPLusNftContractAddress = perPlus.contractList.map((item) => item.ctl_contract_address);

        this.enforceContractAddress = perPlus.enforceContractAddress;
        this.autoMintFactory = perPlus.autoMintFactory;
    }

    /**
     * klay=<>per로 Swap을 위한 request
     * klay => per ( klayToPer )
     * per => klay ( perToKlay )
     * klip일 경우 / kaikas일 경우 다른 헬퍼 tracsaction 사용
     * @param input
     * @param output
     * @param util
     */
    public perSwapRequest = async (
        input: perPlusParamsInput,
        output: KlayMintParamsOutput,
        util: KlayMintParamsUtil,
    ) => {
        const res = await this.walletStatusCheck(input.wallet, output.exceptionCallback, util.Lang);
        if (!res) return;
        /*******************************************************************************************************************************************************/
        if (input.wallet.type === 'kaikas') {
            const { caver } = window;
            const SWAPTOKENCONTRACT = new caver.klay.Contract(IKlaySwapABI, klaySwapFactory);
            if (input.type === 'klayToPer') {
                const klayValue = await this.toPebFromKlay(input.value);
                // 일단 됨 klay -> per
                try {
                    const swap = await SWAPTOKENCONTRACT.methods
                        /**
                         * per contract address를 dev에선 dan2로 할 수 없기에 하드코딩
                         */
                        .exchangeKlayPos('0x7eee60a000986e9efe7f5c90340738558c24317b', klayValue, [])
                        .send({ from: input.wallet.info.address, value: klayValue, gas: 3000000 });
                    output.sucCallback(swap);
                } catch (error) {
                    output.failCallback();
                }
                return;
            } else if (input.type === 'perToKlay') {
                const klayValue = await this.toPebFromKlay(input.expect);
                const perValue = await this.toPebFromKlay(input.value);

                try {
                    const swap = await SWAPTOKENCONTRACT.methods
                        .exchangeKctPos(
                            '0x7eee60a000986e9efe7f5c90340738558c24317b',
                            perValue,
                            klayAddress,
                            klayValue,
                            [],
                        )
                        .send({ from: input.wallet.info.address, value: '0', gas: 3000000 });
                    output.sucCallback(swap);
                } catch (error) {
                    output.failCallback();
                }
                return;
            }
        } else if (input.wallet.type === 'klip') {
            console.log('클립');
            if (input.type === 'klayToPer') {
                console.log('klip ==================> klaytoper');
                const klayValue = await this.toPebFromKlay(input.value);
                const prepareSign = await prepare.executeContract({
                    bappName: myBAppName,
                    from: input.wallet.info.address,
                    to: klaySwapFactory,
                    value: klayValue,
                    abi: JSON.stringify(IKlaySwapABI[2]),
                    params: JSON.stringify(['0x7eee60a000986e9efe7f5c90340738558c24317b', klayValue, []]),
                    successLink: '#',
                    failLink: '#',
                });
                prepareSign.type = 'swap';
                console.log(prepareSign);

                await this.klipHelper.sendTransaction(prepareSign, output, util);

                return;
            } else if (input.type === 'perToKlay') {
                console.log('klip ==================> pertoklay');
                //클레이
                const klayValue = await this.toPebFromKlay(input.expect);
                //퍼
                const perValue = await this.toPebFromKlay(input.value);
                console.log('klayValue :: ', klayValue);
                console.log('perValue :: ', perValue);

                const prepareSign = await prepare.executeContract({
                    bappName: myBAppName,
                    from: input.wallet.info.address,
                    to: klaySwapFactory,
                    value: '0',
                    abi: JSON.stringify(IKlaySwapABI[1]),
                    params: JSON.stringify([
                        '0x7eee60a000986e9efe7f5c90340738558c24317b',
                        perValue,
                        klayAddress,
                        klayValue,
                        [],
                    ]),
                    successLink: '#',
                    failLink: '#',
                });

                prepareSign.type = 'swap';
                await this.klipHelper.sendTransaction(prepareSign, output, util);
                console.log('input');
                console.log(prepareSign);
                return;
            }
        }
    };
    /**
     *  합성 요청을 수행하는 func
     *  1step : 지갑의 상태조회
     *  2step : klip일 경우 = klip.helper에 있는 transaction을 이용하여 prepareSign 전송
     *          kaikas 경우 = kaikas.helper에 있는 transaction을 이용하여 전송
     * @param input
     * @param output
     * @param util
     */
    public enforceRequest = async (
        input: perPlusParamsInput,
        output: KlayMintParamsOutput,
        util: KlayMintParamsUtil,
    ) => {
        const res = await this.walletStatusCheck(input.wallet, output.exceptionCallback, util.Lang);
        if (!res) return;

        if (input.wallet.type === 'klip') {
            const prepareSign = await prepare.executeContract({
                bappName: myBAppName,
                from: input.wallet.info.address,
                to: this.enforceContractAddress,
                value: '0',
                abi: JSON.stringify(enforceABI),
                params: JSON.stringify(input.abiParams),
                successLink: '#',
                failLink: '#',
            });
            prepareSign.type = 'enforce';

            await this.klipHelper.sendTransaction(prepareSign, output, util);
        }

        if (input.wallet.type === 'kaikas') {
            console.log(input.abiParams);
            const abi = this.caver.klay.abi.encodeFunctionCall(enforceABI, input.abiParams);

            await this.kaikasHelper.sendTransaction(
                { toAddress: this.enforceContractAddress, fromAddress: input.wallet.info.address, abi },
                output,
            );
        }
    };
    /**
     * enforce 후 카드를 열때 백엔드 api요청
     * @param data
     */
    public openCard = async (data) => {
        return await axios.post(`${window.envBackHost}/contracts/confirm`, data);
    };
    /**
     * inventory에서 nft를 불러오기위한 func
     * getMyTokens 로 wallet Address가 constract address가 가지고있는 토큰을 불러온다.
     *
     * @param wallet
     * @param totalClasssSortNumber
     */
    public perGetToken = async (wallet, totalClasssSortNumber) => {
        /**
         * list 가 10개 이상이 되거나 , cursor 가 존재하는 경우 핸들링도 추가되어야함
         */
        const res = await this.caverExtKas.getMyTokens(wallet.info.address, this.perPLusNftContractAddress);
        const enforceTokens = [];

        const caver = new Caver(window.klaytn ? window.klaytn : window.envEnNode);

        await setPromiseAll(res.items, async (item) => {
            const { tokenId } = item.extras;
            const kip17Instance = caver.kct.kip17.create(item.contractAddress);
            const tokenURI = await kip17Instance.tokenURI(tokenId);
            const uri = this.replaceIPFSGateway(tokenURI);

            try {
                const response = await axios.get(uri);

                response.data.tokenId = Number.parseInt(item.extras.tokenId, 16);
                response.data.contractAddress = item.contractAddress;
                const result = await getStakeAvailable(item.contractAddress, response.data.tokenId);
                response.data.isStaking = result === '0' ? false : true;

                try {
                    const className = JSON.parse(
                        response.data.attributes[response.data.attributes.length - 1].value,
                    )[1];
                    response.data.className = className;
                    response.data.sortNumber = totalClasssSortNumber[className.toLowerCase()];
                    response.data.updatedAt = item.updatedAt;
                } catch (e) {
                    const className = response.data.attributes[response.data.attributes.length - 1].value;
                    response.data.className = className;
                    response.data.sortNumber = totalClasssSortNumber[className.toLowerCase()];
                    response.data.updatedAt = item.updatedAt;
                }

                enforceTokens.push(response.data);
            } catch (e) {
                console.log(e);
            }
        });
        return enforceTokens.sort((a, b) => a.sortNumber - b.sortNumber);
    };
    /**
     * 플러스 오토 민팅을 위한 func
     * 1step : 지갑 상태조회
     * 2step : peb의 타입이 string을 제외하곤 예외처리
     * 3step : klip일 경우 klip.helper
     *         kaikas일 경우 kaikas.helper
     * @param input
     * @param output
     * @param util
     */
    public autoMintRequest = async (
        input: KlayMintParamsInput,
        output: KlayMintParamsOutput,
        util: KlayMintParamsUtil,
    ) => {
        console.log('perplus input', input);
        const res = await this.walletStatusCheck(input.wallet, output.exceptionCallback, util.Lang);
        if (!res) return;

        const peb = await this.toPebFromKlay(+input.mtl_price * input.amount);
        if (typeof peb !== 'string') return this.endFunction(output.exceptionCallback, util.Lang.err_msg_sucs_mint);

        if (input.wallet.type === 'klip') {
            const prepareSign = await prepare.executeContract({
                bappName: myBAppName,
                from: input.wallet.info.address,
                to: this.autoMintFactory,
                value: peb,
                abi: JSON.stringify(auto_mint_ABI),
                params: JSON.stringify([input.mtl_idx, +qNumber, +input.amount]),
                successLink: '#',
                failLink: '#',
            });
            prepareSign.type = 'mint';

            await this.klipHelper.sendTransaction(prepareSign, output, util);
        } else if (input.wallet.type === 'kaikas') {
            const abi = this.caver.klay.abi.encodeFunctionCall(auto_mint_ABI, [input.mtl_idx, +qNumber, +input.amount]);
            await this.kaikasHelper.sendTransaction(
                { toAddress: this.autoMintFactory, fromAddress: input.wallet.info.address, abi, value: peb },
                output,
            );
        }
    };
    /**
     * staking pool 을 위한 func
     * @param input
     * @param output
     * @param util
     */
    public nftStakingRequest = async (
        input: perPlusParamsInput,
        output: KlayMintParamsOutput,
        util: KlayMintParamsUtil,
    ) => {
        const res = await this.walletStatusCheck(input.wallet, output.exceptionCallback, util.Lang);
        if (!res) return;

        const tokenData = [];
        input.stakingInfo.forEach((element) => {
            tokenData.push(element.tokenId);
        });

        if (input.wallet.type === 'klip') {
            const prepareSign = await prepare.executeContract({
                bappName: myBAppName,
                from: input.wallet.info.address,
                to: stakerAddress,
                value: '0',
                abi: JSON.stringify(nftDepositABI),
                params: JSON.stringify([tokenData]),
                successLink: '#',
                failLink: '#',
            });
            prepareSign.type = 'staking';

            await this.klipHelper.sendTransaction(prepareSign, output, util);
        } else if (input.wallet.type === 'kaikas') {
            const abi = this.caver.klay.abi.encodeFunctionCall(nftDepositABI, [tokenData]);

            await this.kaikasHelper.sendTransaction(
                { toAddress: stakerAddress, fromAddress: input.wallet.info.address, abi },
                output,
            );
        }
    };
    /**
     * 언스테이킹을 위한 func
     * @param input
     * @param output
     * @param util
     */
    public nftUnStakingRequest = async (
        input: perPlusParamsInput,
        output: KlayMintParamsOutput,
        util: KlayMintParamsUtil,
    ) => {
        const res = await this.walletStatusCheck(input.wallet, output.exceptionCallback, util.Lang);
        if (!res) return;

        const tokenData = [];
        input.stakingInfo.forEach((element) => {
            tokenData.push(element.tokenId);
        });

        if (input.wallet.type === 'klip') {
            const prepareSign = await prepare.executeContract({
                bappName: myBAppName,
                from: input.wallet.info.address,
                to: stakerAddress,
                value: '0',
                abi: JSON.stringify(nftWithdrawABI),
                params: JSON.stringify([tokenData]),
                successLink: '#',
                failLink: '#',
            });
            prepareSign.type = 'unStaking';

            await this.klipHelper.sendTransaction(prepareSign, output, util);
        } else if (input.wallet.type === 'kaikas') {
            const abi = this.caver.klay.abi.encodeFunctionCall(nftWithdrawABI, [tokenData]);

            await this.kaikasHelper.sendTransaction(
                { toAddress: stakerAddress, fromAddress: input.wallet.info.address, abi },
                output,
            );
        }
    };
    /**
     * 스테이킹 pool에 있는 모든 nft를 언스테이킹 하기위한 func
     * @param input
     * @param output
     * @param util
     */
    public nftAllUnStakingRequest = async (
        input: perPlusParamsInput,
        output: KlayMintParamsOutput,
        util: KlayMintParamsUtil,
    ) => {
        const res = await this.walletStatusCheck(input.wallet, output.exceptionCallback, util.Lang);
        if (!res) return;

        if (input.wallet.type === 'klip') {
            const prepareSign = await prepare.executeContract({
                bappName: myBAppName,
                from: input.wallet.info.address,
                to: stakerAddress,
                value: '0',
                abi: JSON.stringify(harvest),
                params: '[]',
                successLink: '#',
                failLink: '#',
            });
            prepareSign.type = 'unStaking';

            await this.klipHelper.sendTransaction(prepareSign, output, util);
        } else if (input.wallet.type === 'kaikas') {
            const abi = this.caver.klay.abi.encodeFunctionCall(harvest, []);
            await this.kaikasHelper.sendTransaction(
                { toAddress: stakerAddress, fromAddress: input.wallet.info.address, abi },
                output,
            );
        }
    };
}
